<template>
  <section class="w-100">
    <div
      :id="`condominium-${index}`"
      v-b-toggle="`collapse-condominium-${index}`"
      class="col pt-3 mb-0">
      <div class="row">
        <div class="col d-flex">
          <h5 class="cursor-pointer hover width-only-content">
            <span v-if="condominium.owner.contactIds.length === 0">
              n/a
            </span>
            <span v-else>
              {{ getTenantsName }}
            </span>
            <span>
              :
              <template v-if="condominium.startingAt && condominium.startingAt !== -1 && condominium.startingAt !== '-1'">
                {{ condominium.startingAt | objectToDate }}
              </template>
              <template v-else>
                n/a
              </template>
              -
              <template v-if="condominium.endingAt && condominium.endingAt !== -1 && condominium.endingAt !== '-1'">
                {{ condominium.endingAt | objectToDate }}
              </template>
              <template v-else>
                n/a
              </template>
            </span>
          </h5>
          <a
            tabindex="0"
            class="lh-1 link-activity "
            @click="openActivities()"
            @click.middle="openActivitiesMiddle()">
            <img
              height="15px"
              class="ml-3 mb-1"
              :src="`/img/ticket/new-window.svg`"
              alt="type">
            {{ $t('message.ticketOverview.activities.title') }}
          </a>
        </div>
        <div
          v-if="isAdminModule || isAccountingModule"
          class="col mr-5">
          <coozzy-dropdown
            size="sm"
            class="w-20 sort float-right"
            :text="$t('message.generic.actions')">
            <coozzy-dropdown-item
              v-if="condominium.id && isTicketingEnabled && (isEditor || isTicketEditor)"
              @click="createTicket();$event.stopPropagation()">
              {{ $t('message.ticketOverview.createTicket') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="!isOwnerModule && !isAssetModule"
              @click="navigateToCreateOrder()">
              {{ $t('message.onBoarding.devices.createOrder') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="!condominium.activated"
              @click="removeClicked();$event.stopPropagation()">
              {{ $t('message.onBoarding.buildings.objects.ownerAgreement.delete') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="!condominium.activated"
              @click="activateCondominiumClicked();$event.stopPropagation()">
              {{ $t('message.onBoarding.buildings.objects.ownerAgreement.activateModal.title') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="condominium.owner.contactIds.length > 0"
              @click="addActivityClicked(); $event.stopPropagation()">
              {{ $t('message.contact.activity.create') }}
            </coozzy-dropdown-item>
            <add-document-modal
              :suffix="'condominium-doc' + index"
              :owner-id="condominium.ownerId"
              @document-created="documentCreatedAction" />
            <coozzy-dropdown-item
              @click="addDocumentClicked('condominium-doc' + index);$event.stopPropagation()">
              {{ $t('message.manageDocuments.addDocument') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              @click="$bvModal.show(`create-contract-confirmationModal-${index}`); $event.stopPropagation()">
              {{ $t('message.addTenancy.createDocument') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="condominium.owner.contactIds.length > 0"
              @click="addReminderClicked();$event.stopPropagation()">
              {{ $t('message.onBoarding.buildings.objects.addReminder') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="condominium.owner.contactIds.length > 0 && tenantContacts.filter(tenant => tenant.email !== '' && tenant.email !== null).length > 0 "
              @click="openSendMailModal();$event.stopPropagation()">
              {{ $t('message.generic.writeEmail') }}
            </coozzy-dropdown-item>
          </coozzy-dropdown>
        </div>
      </div>
    </div>
    <b-collapse
      :id="`collapse-condominium-${index}`"
      ref="collapse"
      :visible="expandByDefault || openedCollapses.includes(`collapse-condominium-${index}`)"
      class="col">
      <div class="row">
        <div class="col">
          <h6
            v-b-toggle="`collapse-condominium-${index}-tenant`"
            class="font-weight-bold mt-3"
            :class="[readOnly ? 'none': 'cursor-pointer', 'width-only-content hover']">
            {{ $t('message.onBoarding.buildings.objects.ownerAgreement.condominiumOwner') }}
          </h6>
        </div>
      </div>
      <b-collapse
        :id="`collapse-condominium-${index}-tenant`"
        :visible="true">
        <div
          class="row py-02rem">
          <div class="col-12">
            <coozzy-form-checkbox
              :id="`${condominium.id}-ownerIsDebtor`"
              :key="`${condominium.id}-ownerIsDebtor`"
              v-model="ownerIsDebtor"
              :initial="ownerIsDebtor"
              name="ownerIsDebtor"
              :disabled="!canBeEdited || readOnly || (!condominium.internalId && activated && !isAccountingModule && !isAdminModule && !isOwnerModule)"
              class="mt-2">
              {{ $t('message.onBoarding.settlementObject.ownerIsDebtor') }}
            </coozzy-form-checkbox>
          </div>
        </div>
        <div
          :ref="`objcondominiumrow-${condominium.id}`"
          class="row objcondominiumrow py-01rem">
          <div
            v-if="readOnly || $parent.$route.name === 'AdminAddTenancyView' || $parent.$route.name === 'MarketingAddTenancyView' || $parent.$route.name === 'AdminActivateTenancy' || $parent.$route.name === 'AssetActivateTenancy' || $parent.$route.name === 'MarketingActivateTenancy'"
            class="col-sm-12 col-md-3">
            <label>
              {{ $t('message.onBoarding.buildings.objects.tenancies.debtor') }}
            </label>
            <div v-if="billingContactOption">
              <div v-if="getContact(billingContactOption.objectID || billingContactOption.id)">
                <a
                  role="button"
                  class="cursor-pointer"
                  tabindex="0"
                  @click="redirectToDetailPage(billingContactOption.objectID || billingContactOption.id)"
                  @click.middle="redirectToDetailPageMiddleClick(billingContactOption.objectID || billingContactOption.id)">
                  {{ getContact(billingContactOption.objectID || billingContactOption.id).name }}
                </a>
                <template v-if="getContact(billingContactOption.objectID || billingContactOption.id)">
                  <br>
                  <address-text
                    :show-short-country="true"
                    :address="getContact(billingContactOption.objectID || billingContactOption.id).address" />
                </template>
              </div>
            </div>
            <span
              v-else
              class="d-block">-</span>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <!-- <coozzy-contact-multiselect
              id="billingContactId"
              v-model="billingContactOption"
              :options="availableTenantsWithAddress"
              :multiple="false"
              :close-on-select="true"
              :check-valide="$v.billingContactOption.$dirty && $v.billingContactOption.$error ? 'is-invalid' : ''"
              :state="$v.billingContactOption.$dirty && $v.billingContactOption.$error ? false : null"
              :disabled="!condominium.internalId && activated && !isAccountingModule"
              label="label"
              track-by="value">
              {{ $t('message.onBoarding.buildings.objects.tenancies.debtor') + (readOnly ? '' : '*') }}
            </coozzy-contact-multiselect> -->
            <label>{{ $t('message.onBoarding.buildings.objects.tenancies.debtor') + (readOnly ? '' : '*') }}</label>
            <ais-instant-search
              v-if="searchClientInternal"
              :search-client="searchClientInternal"
              :routing="routing"
              index-name="contact">
              <!-- eslint-disable vue/attribute-hyphenation -->
              <ais-configure
                :hitsPerPage="10"
                :filters="'active:true AND isEmployee:false AND owner.id=' + condominium.ownerId" />
              <!-- eslint-disable vue/attribute-hyphenation -->
              <ais-autocomplete>
                <template slot-scope="{ indices, refine }">
                  <coozzy-search-multiselect
                    v-model="billingContactOption"
                    :options="indices[0].hits.filter(x => x && x.objectID)"
                    :placeholder="''"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :preselect-first="false"
                    :check-valide="$v.billingContactOption.$dirty && $v.billingContactOption.$error ? 'is-invalid' : ''"
                    :state="$v.billingContactOption.$dirty && $v.billingContactOption.$error ? false : null"
                    :disabled="!canBeEdited || (ownerIsDebtor || (!condominium.internalId && activated && !isAccountingModule && !isAdminModule && !isOwnerModule))"
                    :taggable="false"
                    track-by="objectID"
                    label="name"
                    :with-address="true"
                    :with-numeric-id="true"
                    @search-change="see(refine, $event)" />
                </template>
              </ais-autocomplete>
            </ais-instant-search>
          </div>
          <div
            v-if="readOnly || !enableTenantSelection"
            class="col-sm-12 col-md-3">
            <label for="tenants">
              {{ $t('message.onBoarding.buildings.objects.ownerAgreement.condominiumOwner') }}
            </label>
            <div v-if="condominium.owner.contactIds.length > 0">
              <template
                v-for="(contactId, contactIndex) in condominium.owner.contactIds">
                <div
                  v-if="getContact(contactId)"
                  :key="contactId + contactIndex">
                  <a
                    role="button"
                    class="cursor-pointer"
                    tabindex="0"
                    @click="redirectToDetailPage(contactId)"
                    @click.middle="redirectToDetailPageMiddleClick(contactId)">
                    {{ getContact(contactId).name }}
                  </a>
                  <template v-if="getContact(contactId)">
                    <br>
                    <address-text
                      :show-short-country="true"
                      :hide-if-empty="true"
                      :address="getContact(contactId).address" />
                  </template>
                </div>
              </template>
            </div>
            <span
              v-else
              class="d-block">-</span>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <!-- <coozzy-contact-multiselect
              id="tenants"
              v-model="tenants"
              :options="availableTenantsWithAddress"
              :clear-on-select="true"
              :check-valide="$v.tenants.$dirty && (hasNoAddress.length > 0 || $v.tenants.$error) ? 'is-invalid' : ''"
              :disabled="!condominium.internalId && activated && !isAccountingModule"
              label="label"
              track-by="label">
              {{ $t('message.onBoarding.buildings.objects.ownerAgreement.condominiumOwner') + (readOnly ? '' : '*') }}
            </coozzy-contact-multiselect> -->
            <label>{{ $t('message.onBoarding.buildings.objects.ownerAgreement.condominiumOwner') + (readOnly ? '' : '*') }}</label>
            <ais-instant-search
              v-if="searchClientInternal"
              :search-client="searchClientInternal"
              :routing="routing"
              index-name="contact">
              <!-- eslint-disable vue/attribute-hyphenation -->
              <ais-configure
                :hitsPerPage="10"
                :filters="'active:true AND isEmployee:false AND owner.id=' + condominium.ownerId" />
              <!-- eslint-disable vue/attribute-hyphenation -->
              <ais-autocomplete>
                <template slot-scope="{ indices, refine }">
                  <coozzy-search-multiselect
                    v-model="tenants"
                    :options="editObject(indices[0].hits)"
                    :placeholder="''"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :preselect-first="false"
                    :check-valide="$v.tenants.$dirty && (hasNoAddress.length > 0 || $v.tenants.$error) ? 'is-invalid' : ''"
                    :disabled="!canBeEdited || ownerIsDebtor || (!condominium.internalId && activated && !isAccountingModule && !isAdminModule && !isOwnerModule)"
                    :taggable="false"
                    track-by="id"
                    label="name"
                    :with-address="true"
                    :with-numeric-id="true"
                    @search-change="see(refine, $event)" />
                </template>
              </ais-autocomplete>
            </ais-instant-search>
          </div>
          <div
            class="col-sm-12 col-md-3">
            <label>{{ $t('message.onBoarding.buildings.objects.ownerAgreement.roommateContact.title') }}
              <coozzy-info-circle-icon
                v-b-tooltip.hover="{customClass: 'tooltipRoommate', html: true}"
                :title="$t('message.onBoarding.buildings.objects.ownerAgreement.roommateContact.tooltip')" /></label>
            <template v-if="!readOnly">
              <ais-instant-search
                v-if="searchClientInternal"
                :search-client="searchClientInternal"
                :routing="routing"
                index-name="contact">
                <!-- eslint-disable vue/attribute-hyphenation -->
                <ais-configure
                  :hitsPerPage="10"
                  :filters="'active:true AND isEmployee:false AND owner.id=' + condominium.ownerId" />
                <!-- eslint-disable vue/attribute-hyphenation -->
                <ais-autocomplete>
                  <template slot-scope="{ indices, refine }">
                    <coozzy-search-multiselect
                      v-model="roommateContactIds"
                      :options="editObject(indices[0].hits.filter(x => x && x.objectID))"
                      :placeholder="''"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :preselect-first="false"
                      :taggable="false"
                      track-by="id"
                      label="name"
                      :with-address="true"
                      :with-numeric-id="true"
                      @search-change="see(refine, $event)" />
                  </template>
                </ais-autocomplete>
              </ais-instant-search>
            </template>
            <template v-else>
              <div v-if="condominium.owner.roommateContactIds.length > 0">
                <template
                  v-for="(contactId, contactIndex) in condominium.owner.roommateContactIds">
                  <div
                    v-if="getContact(contactId)"
                    :key="contactId + contactIndex">
                    <a
                      role="button"
                      class="cursor-pointer"
                      tabindex="0"
                      @click="redirectToDetailPage(contactId)"
                      @click.middle="redirectToDetailPageMiddleClick(contactId)">
                      {{ getContact(contactId).name }}
                    </a>
                    <template v-if="getContact(contactId)">
                      <br>
                      <address-text
                        :show-short-country="true"
                        :hide-if-empty="true"
                        :address="getContact(contactId).address" />
                    </template>
                  </div>
                </template>
              </div>
              <span
                v-else
                class="d-block">-</span>
            </template>
          </div>
          <div
            v-if="readOnly || $parent.$route.name === 'AdminAddTenancyView' || $parent.$route.name === 'MarketingAddTenancyView' || $parent.$route.name === 'AdminActivateTenancy' || $parent.$route.name === 'AssetActivateTenancy' || $parent.$route.name === 'MarketingActivateTenancy'"
            class="col-sm-12 col-md-3">
            <label>
              {{ $t('message.onBoarding.buildings.objects.tenancies.correspondenceAddress') }}
            </label>
            <div v-if="correspondenceContactOption">
              <div v-if="getContact(getIdCorrespondenceContact(correspondenceContactOption))">
                <a
                  role="button"
                  class="cursor-pointer"
                  tabindex="0"
                  @click="redirectToDetailPage(getIdCorrespondenceContact(correspondenceContactOption))"
                  @click.middle="redirectToDetailPageMiddleClick(getIdCorrespondenceContact(correspondenceContactOption))">
                  {{ getContact(getIdCorrespondenceContact(correspondenceContactOption)).name }}
                </a>
                <template v-if="getContact(getIdCorrespondenceContact(correspondenceContactOption))">
                  <br>
                  <address-text
                    :show-short-country="true"
                    :address="getContact(getIdCorrespondenceContact(correspondenceContactOption)).address" />
                </template>
              </div>
            </div>
            <span
              v-else
              class="d-block">-</span>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <!-- <coozzy-contact-multiselect
              id="copyOfContactId"
              v-model="correspondenceContactOption"
              :options="availableTenantsWithAddress"
              :multiple="false"
              :close-on-select="true"
              :disabled="!condominium.internalId && activated && !isAccountingModule"
              label="label"
              track-by="label">
              {{ $t('message.onBoarding.buildings.objects.tenancies.correspondenceAddress') }}
            </coozzy-contact-multiselect> -->
            <label>{{ $t('message.onBoarding.buildings.objects.tenancies.correspondenceAddress') }}</label>
            <ais-instant-search
              v-if="searchClientInternal"
              :search-client="searchClientInternal"
              :routing="routing"
              index-name="contact">
              <!-- eslint-disable vue/attribute-hyphenation -->
              <ais-configure
                :hitsPerPage="10"
                :filters="'active:true AND isEmployee:false AND owner.id=' + condominium.ownerId" />
              <!-- eslint-disable vue/attribute-hyphenation -->
              <ais-autocomplete>
                <template slot-scope="{ indices, refine }">
                  <coozzy-search-multiselect
                    v-model="correspondenceContactOption"
                    :options="indices[0].hits.filter(x => x && x.objectID)"
                    :placeholder="''"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :preselect-first="false"
                    :taggable="false"
                    track-by="objectID"
                    label="name"
                    :with-address="true"
                    :with-numeric-id="true"
                    @search-change="see(refine, $event)" />
                </template>
              </ais-autocomplete>
            </ais-instant-search>
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="typeOfUse">
              {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.title') }} {{ readOnly ? '' : '*' }}
            </label>
            <coozzy-form-select
              v-if="!readOnly"
              id="typeOfUse"
              v-model="typeOfUse"
              :disabled="!canBeEdited"
              :state="$v.typeOfUse.$dirty && $v.typeOfUse.$error ? false : null">
              <option value="">
                -
              </option>
              <option
                v-for="(item, i) in typeOfUseData"
                :key="i"
                :value="item">
                {{ $t(`message.onBoarding.buildings.objects.tenancies.typeOfUse.${item}`) }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="d-block">
              <span v-if="typeOfUse === 'TYPE_OF_USE_VACATION'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_VACATION') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_COMMERCIAL'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_COMMERCIAL') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_PARKING'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_PARKING') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_PARKING_EXTERNAL'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_PARKING_EXTERNAL') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_AGRICULTURAL'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_AGRICULTURAL') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_CONSTRUCTION'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_CONSTRUCTION') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_GASTRONOMY'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_GASTRONOMY') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_INDUSTRIAL'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_INDUSTRIAL') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_INVESTMENT'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_INVESTMENT') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_STORAGE'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_STORAGE') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_RESIDENTIAL'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_RESIDENTIAL') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_SECONDARY_ROOM'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_SECONDARY_ROOM') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_OFFICE'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_OFFICE') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_STORE'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_STORE') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_ADVERTISING_AREA'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_ADVERTISING_AREA') }}
              </span>
              <span v-else> - </span>
            </span>
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="rentType">
              {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.title') }} {{ readOnly ? '' : '*' }}
            </label>
            <coozzy-form-select
              v-if="!readOnly"
              id="rentType"
              v-model="rentType"
              :disabled="!canBeEdited"
              :state="$v.rentType.$dirty && $v.rentType.$error ? false : null">
              <option value="RENT_TYPE_CONDOMINIUM_OWNERSHIP">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_CONDOMINIUM_OWNERSHIP') }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="d-block">
              {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_CONDOMINIUM_OWNERSHIP') }}
            </span>
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="dates.startingAt"
              :is-read-only="readOnly"
              :filter="'formatDate'"
              :disabled="!canBeEdited || (!condominium.internalId && activated && (!isAccountingModule && !isAdminModule && !isOwnerModule))"
              type="date"
              :state="$v.startingAt.$dirty && $v.startingAt.$error ? false : null"
              :name="$t('message.onBoarding.buildings.objects.ownerAgreement.ownershipStart') + (readOnly ? '' : '*')"
              @blur="dateToObject()" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="dates.endingAt"
              type="date"
              :state="$v.endingAt.$dirty && $v.endingAt.$error ? false : null"
              :is-read-only="readOnly"
              :filter="'formatDate'"
              :disabled="isInactive"
              :name="$t('message.onBoarding.buildings.objects.ownerAgreement.ownershipEnd')"
              @blur="dateToObject()" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="contractNumber"
              :disabled="true"
              :is-read-only="readOnly"
              :name="$t('message.onBoarding.buildings.objects.tenancies.contractNumber')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="condominiumStatus">
              {{ $t('message.onBoarding.buildings.objects.ownerAgreement.ownershipStatus.title') + (readOnly ? '' : '*') }}
              <coozzy-info-circle-icon
                v-b-tooltip.hover.html="$t('message.onBoarding.buildings.objects.ownerAgreement.ownershipStatus.tooltip')" />
            </label>
            <coozzy-form-select
              v-if="!readOnly"
              id="condominiumStatus"
              v-model="condominiumStatus"
              :disabled="!canBeEdited"
              :state="$v.condominiumStatus.$dirty && $v.condominiumStatus.$error ? false : null">
              <option
                v-if="!hideInprogress"
                :value="null">
                -
              </option>
              <option
                v-if="!hideInprogress"
                value="inProgress">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tenancyStatus.inProgress') }}
              </option>
              <option
                value="active">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tenancyStatus.active') }}
              </option>
              <option
                :disabled="!endingDatebeforeNow"
                value="activeClosed">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tenancyStatus.activeClosed') }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="d-block">
              <span v-if="condominiumStatus === null">
                -
              </span>
              <span v-else-if="condominiumStatus === 'inProgress'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tenancyStatus.inProgress') }}
              </span>
              <span v-else-if="condominiumStatus === 'active'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tenancyStatus.active') }}
              </span>
              <span v-else-if="condominiumStatus === 'activeClosed'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tenancyStatus.activeClosed') }}
              </span>
              <span v-else> - </span>
            </span>
          </div>
          <div
            v-if="objectCategory === 'PARKING_SPACE'"
            class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="licensePlate"
              :is-read-only="readOnly"
              :name="$t('message.onBoarding.buildings.objects.tenancies.licensePlate')" />
          </div>
          <div
            v-if="objectCategory === 'APARTMENT' || objectCategory === 'HOUSE'"
            class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="numberOfPersons"
              type="number"
              min="0"
              :is-read-only="readOnly"
              :is-interger="true"
              :state="$v.numberOfPersons.$dirty && $v.numberOfPersons.$error ? false : null"
              :name="$t('message.onBoarding.buildings.objects.tenancies.numberOfPersons')" />
          </div>
          <div
            v-if="objectCategory === 'COMMERCIAL'"
            class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="intendedUse"
              :is-read-only="readOnly"
              :name="$t('message.onBoarding.buildings.objects.tenancies.intendedUse')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="contractAmendments"
              :is-read-only="readOnly"
              :placeholder="$t('message.onBoarding.buildings.objects.tenancies.contractAmendmentsPlaceholder')"
              :name="$t('message.onBoarding.buildings.objects.tenancies.contractAmendments')" />
          </div>
          <div
            v-if="(property && property.opted === true) || (['TYPE_OF_USE_PARKING_EXTERNAL', 'TYPE_OF_USE_SECONDARY_ROOM', 'TYPE_OF_USE_STORAGE', 'TYPE_OF_USE_ADVERTISING_AREA', 'TYPE_OF_USE_RADIO_ANTENNA'].includes(typeOfUse))"
            class="col-sm-12 col-md-3">
            <label for="tax">
              {{ $t('message.onBoarding.buildings.objects.tenancies.tax.title') }}
            </label>
            <coozzy-form-select
              v-if="!readOnly"
              id="tax"
              v-model="taxable"
              :disabled="(object.debitUntil && new Date($options.filters.objectToDateInput(object.debitUntil)) > new Date($options.filters.objectToDateInput(condominium.startingAt)))">
              <option
                :value="null">
                -
              </option>
              <option
                :disabled="(property && property.opted === false)"
                :value="false">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tax.opted') }}
              </option>
              <option
                v-if="(property && property.opted === false) && (['TYPE_OF_USE_PARKING_EXTERNAL', 'TYPE_OF_USE_SECONDARY_ROOM', 'TYPE_OF_USE_STORAGE', 'TYPE_OF_USE_ADVERTISING_AREA', 'TYPE_OF_USE_RADIO_ANTENNA'].includes(typeOfUse))"
                :value="true">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tax.taxable') }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="d-block">
              <span v-if="taxable === null">
                -
              </span>
              <span v-else-if="taxable === false">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tax.opted') }}
              </span>
              <span v-else-if="taxable === true">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tax.taxable') }}
              </span>
              <span v-else> - </span>
            </span>
          </div>
          <template v-if="objectCategory === 'HOUSE' || objectCategory === 'APARTMENT'">
            <div
              v-if="!readOnly && (mode === 'adding' || mode === 'activate')"
              class="col-sm-12 col-md-3">
              <coozzy-form-checkbox
                v-model="secondaryResidence"
                :initial="secondaryResidence"
                name="secondaryResidence"
                class="mt-4">
                {{ $t('message.onBoarding.buildings.objects.tenancies.secondaryResidence') }}
              </coozzy-form-checkbox>
            </div>
            <div
              v-else-if="readOnly && (mode === 'adding' || mode === 'activate')"
              class="col-sm-12 col-md-3">
              <label>
                {{ $t('message.onBoarding.buildings.objects.tenancies.secondaryResidence') }}
              </label>
              <span class="d-block">
                {{ secondaryResidence ? $t('message.generic.selectField.yes') : $t('message.generic.selectField.no') }}
              </span>
            </div>
            <div
              v-if="!readOnly && mode !== 'adding' && mode !== 'activate'"
              class="col-sm-12 col-md-3">
              <coozzy-form-checkbox
                v-model="secondaryResidence"
                :initial="secondaryResidence"
                name="secondaryResidence"
                class="mt-4">
                {{ $t('message.onBoarding.buildings.objects.tenancies.secondaryResidence') }}
              </coozzy-form-checkbox>
            </div>
            <div
              v-else-if="readOnly && mode !== 'adding' && mode !== 'activate'"
              class="col-sm-12 col-md-3">
              <label>
                {{ $t('message.onBoarding.buildings.objects.tenancies.secondaryResidence') }}
              </label>
              <span class="d-block">
                {{ secondaryResidence ? $t('message.generic.selectField.yes') : $t('message.generic.selectField.no') }}
              </span>
            </div>
          </template>
          <!-- <div
            v-if="!readOnly && mode !== 'activate'"
            class="col-sm-12 col-md-3">
            <coozzy-form-checkbox
              v-if="mode !== 'adding'"
              v-model="activated"
              :initial="activated"
              :disabled="condominium.activatedOnLoad"
              name="activated"
              class="mt-4">
              {{ $t('message.onBoarding.buildings.objects.tenancies.activated') }}
            </coozzy-form-checkbox>
          </div>
          <div
            v-else-if="readOnly && mode !== 'activate'"
            class="col-sm-12 col-md-3">
            <label>
              {{ $t('message.onBoarding.buildings.objects.tenancies.activated') }}
            </label>
            <span class="show-data">
              {{ activated ? $t('message.generic.selectField.yes') : $t('message.generic.selectField.no') }}
            </span>
          </div> -->
          <template v-if="mode !== 'adding' && mode !== 'activate'">
            <div
              v-if="!readOnly"
              class="col-sm-12 col-md-3">
              <coozzy-form-checkbox
                v-model="finalInvoiceCreated"
                :initial="finalInvoiceCreated"
                name="finalInvoiceCreated"
                class="mt-4">
                {{ $t('message.onBoarding.buildings.objects.tenancies.finalInvoiceCreated') }}
              </coozzy-form-checkbox>
            </div>
            <div
              v-else
              class="col-sm-12 col-md-3">
              <label>
                {{ $t('message.onBoarding.buildings.objects.tenancies.finalInvoiceCreated') }}
              </label>
              <span class="show-data">
                {{ finalInvoiceCreated ? $t('message.generic.selectField.yes') : $t('message.generic.selectField.no') }}
              </span>
            </div>
          </template>
          <!-- <template
            v-if="mode !== 'adding' && mode !== 'activate'">
            <div
              v-if="!readOnly"
              class="col-sm-12 col-md-3">
              <coozzy-form-checkbox
                v-model="closed"
                :initial="closed"
                name="closed"
                class="mt-4">
                {{ $t('message.onBoarding.buildings.objects.tenancies.closed') }}
              </coozzy-form-checkbox>
            </div>
            <div
              v-else
              class="col-sm-12 col-md-3">
              <label>
                {{ $t('message.onBoarding.buildings.objects.tenancies.closed') }}
              </label>
              <span class="show-data">
                {{ closed ? $t('message.generic.selectField.yes') : $t('message.generic.selectField.no') }}
              </span>
            </div>
          </template> -->
        </div>
      </b-collapse>
      <div
        v-if="!readOnly || adjustments.length > 0"
        class="row">
        <div class="col-12">
          <hr class="mt-0">
        </div>
        <div class="col-12">
          <h6
            v-b-toggle="`collapse-tenancy-${index}-adjustedcosts`"
            class="cursor-pointer hover font-weight-bold mt-3 width-only-content">
            {{ $t('message.onBoarding.buildings.objects.ownerAgreement.budgetDemands') }}
          </h6>
        </div>
      </div>
      <b-collapse
        :id="`collapse-tenancy-${index}-adjustedcosts`"
        :visible="expandAllChild || openedCollapses.includes(`collapse-tenancy-${index}-adjustedcosts`)">
        <template v-for="(adjustment, adjIndex) in adjustments">
          <condominium-adjustment
            ref="condominium-adjustment"
            :key="adjustment.internalId"
            :index="adjIndex"
            :adjustment="adjustment"
            :condominium="condominium"
            :read-only="readOnly"
            :mode="mode"
            :object="object"
            :is-most-recent="adjustments[0] === adjustment"
            :until-date="convertTimestampToISODate(adjustments[adjIndex - 1] ? getPreviousDayOfTimestamp(adjustments[adjIndex - 1].byDate) : -1)"
            @delete-adjustment="deleteAdjustmentClicked"
            @change-adjustment="changeAdjustment" />
        </template>
        <div class="col-2 my-2 pr-2 pl-0 ml-0">
          <coozzy-button
            v-if="!readOnly"
            class="w-100"
            design="prop-green"
            @click="addAdjustmentClicked">
            {{ $t('message.onBoarding.buildings.objects.tenancies.addNewRent') }}
          </coozzy-button>
        </div>
      </b-collapse>
      <div class="row">
        <div
          v-if="(condominium.tenants.length > 0 || !readOnly)"
          class="col-12">
          <hr class="mt-0">
        </div>
        <div
          v-if="(condominium.tenants.length > 0 || !readOnly)"
          class="col-12 test">
          <h6
            v-b-toggle="`collapse-condominium-${index}-tenants`"
            class="cursor-pointer hover font-weight-bold mt-3 width-only-content">
            {{ $t('message.onBoarding.buildings.objects.tenancies.tenants.title') }}
          </h6>
        </div>
        <div class="col-12">
          <b-collapse
            :id="`collapse-condominium-${index}-tenants`"
            :visible="expandAllChild || openedCollapses.includes(`collapse-condominium-${index}-tenants`)">
            <template v-for="(tenant, x) in condominium.tenants">
              <condominium-tenant
                :ref="`condominium-tenants`"
                :key="x"
                :index="x"
                :tenant="tenant"
                :condominium="condominium"
                :read-only="readOnly"
                @delete-tenant="deleteTenantClicked(x)"
                @change-tenant="changeTenant" />
            </template>
            <div class="col-2 mt-1 pr-0 pl-0 ml-0">
              <coozzy-button
                v-if="!readOnly"
                class="w-100 mt-2 mb-2"
                design="prop-green"
                @click="addTenantClicked">
                {{ $t('message.onBoarding.buildings.objects.ownerAgreement.addTenant') }}
              </coozzy-button>
            </div>
          </b-collapse>
        </div>
        <div
          v-if="mode !== 'adding'"
          class="col-12">
          <hr class="mt-0">
        </div>
        <div
          v-if="(documents.length > 0 || !readOnly) && mode !== 'adding'"
          class="col-12">
          <h6
            v-b-toggle="`collapse-condominium-${index}-document`"
            class="cursor-pointer hover font-weight-bold mt-3 width-only-content">
            {{ $t('message.generic.documents') }}
          </h6>
        </div>
        <b-collapse
          v-if="mode !== 'adding'"
          :id="`collapse-condominium-${index}-document`"
          class="w-100 position-relative"
          :visible="expandAllChild || mode === 'activate' || openedCollapses.includes(`collapse-condominium-${index}-document`)">
          <span
            v-if="documents.length > 0"
            class="link downloadAllDocuments"
            @click="downloadAllDocuments($event)">{{ $t('message.marketingMessages.mail.downloadAll') }} <coozzy-file-download-icon /></span>
          <div
            v-if="!readOnly"
            class="col-2 mt-1">
            <add-document-modal
              :suffix="'condominium' + index"
              :owner-id="condominium.ownerId"
              @document-created="documentCreated" />
            <coozzy-button
              class="w-100"
              design="prop-green"
              @click="addDocumentClicked('condominium' + index)">
              {{ $t('message.manageDocuments.addDocument') }}
            </coozzy-button>
          </div>
          <div
            v-if="documents.length > 0"
            class="col-12 mt-2">
            <documents-list
              :documents-list="documents"
              :is-read-only="readOnly"
              @version-added="versionAdded"
              @document-deleted="deleteDocument" />
          </div>
        </b-collapse>
      </div>
      <div
        v-if="!condominium.activated"
        class="row">
        <div class="col-12 mt-3">
          <coozzy-button
            v-if="!readOnly && mode !== 'adding' && mode !== 'activate'"
            size="small"
            design="red"
            class="float-right"
            @click="removeClicked">
            {{ $t('message.onBoarding.buildings.objects.ownerAgreement.delete') }}
          </coozzy-button>
        </div>
      </div>
    </b-collapse>
    <b-modal
      :id="'delete-condominium-modal'"
      :ref="`delete-condominium-modal`"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.deleteModals.confirmation')">
      <div class="col">
        <p>{{ $t('message.onBoarding.deleteModals.bodyCondominiumOwner') }}</p>
      </div>
      <div class="col">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('delete-condominium-modal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="removeConfirmed">
          {{ $t('message.generic.delete') }}
        </coozzy-button>
      </div>
    </b-modal>
    <b-modal
      :id="`create-contract-confirmationModal-${index}`"
      :ref="`create-contract-confirmationModal-${index}`"
      :size="'xl'"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.addTenancy.createDocument')"
      @close="hideRenderContractModal">
      <template>
        <div class="col pl-0 mb-1">
          <label>{{ $t('message.settings.template.textTemplate') }}</label>
          <coozzy-form-select
            v-model="selectedTemplate"
            name="numberEntries">
            <option
              value="">
              -
            </option>
            <option
              v-for="template in sortedTemplate"
              :key="template.id"
              :value="template.id">
              {{ template.name }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="col pl-0 mb-1">
          <label>{{ $t('message.serialLetterProcess.subject') }}</label>
          <coozzy-form-textarea
            id="title"
            v-model="title"
            :initial="title"
            :value-text-area="title"
            :text="title"
            :show-label="false"
            :rows="3" />
        </div>
        <div class="col pl-0 mb-2">
          <label>{{ $t('message.serialLetterProcess.letterText') }}</label>
          <coozzy-form-textarea
            id="description"
            v-model="description"
            :initial="description"
            :value-text-area="description"
            :text="description"
            :show-label="false"
            :rows="3" />
        </div>
        <div class="col pl-0">
          <template-table
            :source="'objectTenancy'"
            :by-owners-ids="templateOwnersIds"
            @contracts-loaded="contractsLoaded"
            @selected-contract="itemSelected" />
        </div>
        <div class="col pl-0">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="hideRenderContractModal()">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            :disabled="renderContractLoading || contractTemplateSelected.length === 0"
            @click="renderContract">
            {{ $t('message.addTenancy.createDocument') }}
          </coozzy-button>
          <coozzy-form-checkbox
            v-model="addDocumentToContact"
            class="float-right pr-3 mt-1 mr-0 display-none-xs font-weight-lighter">
            {{ $t('message.generic.addDocumentToOwnerAgreement') }}
          </coozzy-form-checkbox>
          <coozzy-form-checkbox
            v-model="includeRoommate"
            class="float-right pr-3 mt-1 mr-0 display-none-xs font-weight-lighter">
            {{ $t('message.generic.includeRoommate') }}
          </coozzy-form-checkbox>
        </div>
      </template>
    </b-modal>
    <create-activity-modal
      :ref="`create-activity${condominium.id}`"
      :condominium="condominium"
      :references-ids="[condominium.id]"
      :ids="condominium.id ? [condominium.id] : []" />
    <reminder-creation-modal
      :ref="`reminder-creation-modal${condominium.id}`"
      :source="'tenancies'"
      :references-ids="condominium.owner.contactIds"
      :available-contacts="tenantContacts" />
    <activate-condominium-modal
      ref="activateCondominiumModal"
      :owner-id="condominium.ownerId"
      :selected-entries-ids="[condominium.id]"
      :objects="[object]"
      :condominiums="[condominium]"
      @condo-activated="condominiumActivated" />
  </section>
</template>

<script>

import CoozzyFormInput from '../../../framework/components/form/input/CoozzyFormInput'
import CoozzySearchMultiselect from '@/framework/components/multiselect/CoozzySearchMultiselect'
import CoozzyFormSelect from '../../../framework/components/form/select/CoozzyFormSelect'
import CoozzyButton from '../../../framework/components/button/CoozzyButton'
import AddDocumentModal from '@/properties/components/AddDocumentModal'
import { onboarding } from '@/mixins/onboarding'
import { validation } from '@/mixins/validation'
import { numeric, required } from 'vuelidate/lib/validators'
import ActivateCondominiumModal from '@/addTenancy/ActivateCondominiumModal'
import Vue from 'vue'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import ObjectApi from '@/misc/apis/ObjectApi'
import { mapGetters, mapMutations, mapState } from 'vuex'
import CreateActivityModal from '@/contact/components/CreateActivityModal'
import ReminderCreationModal from '@/calendar/components/ReminderCreationModal'
import { routeChecks } from '@/mixins/routeChecks'
import { tables } from '@/mixins/tables'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem'
import { algolia } from '@/mixins/algolia'
import { user } from '@/mixins/user'
import AddressText from '@/framework/components/misc/AddressText'
import TemplateTable from '@/settings/components/TemplateTable'
import { toastError } from '@/mixins/toastError'
import CondominiumTenant from './CondominiumTenant.vue'
import CondominiumAdjustment from './CondominiumAdjustment.vue'
import ContactApi from '@/misc/apis/ContactApi'
import { saveAs } from 'file-saver'
import MediaApi from '@/misc/apis/MediaApi'
import DocumentsList from '@/properties/components/DocumentsList.vue'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea.vue'
import CoozzyFileDownloadIcon from '@/framework/components/icons/CoozzyFileDownloadIcon.vue'
import { media } from '@/mixins/media'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon.vue'

function lessThanEnding(value, vm) {
  if (!vm.endingAt) {
    return true
  }
  return new Date(this.$options.filters.objectToDateInput(value)) <= new Date(this.$options.filters.objectToDateInput(vm.endingAt))
}

function moreThanStarting(value, vm) {
  if (!value || new Date(this.$options.filters.objectToDateInput(value)).getTime() / 1000 < 0) {
    return true
  }
  if (!vm.startingAt) {
    return true
  }
  return new Date(this.$options.filters.objectToDateInput(value)) >= new Date(this.$options.filters.objectToDateInput(vm.startingAt))
}

function isTenantsEmpty(value, vm) {
  return (value.length > 0)
}

function isTenantsNull(value, vm) {
  if (value) {
    return true
  }
  return false
}

export default {
  name: 'ObjectCondominium',
  components: {
    CoozzyInfoCircleIcon,
    CoozzyFileDownloadIcon,
    CoozzyFormTextarea,
    DocumentsList,
    CoozzySearchMultiselect,
    TemplateTable,
    ActivateCondominiumModal,
    CoozzyDropdownItem,
    CoozzyDropdown,
    CoozzyFormCheckbox,
    CoozzyButton,
    CoozzyFormSelect,
    CoozzyFormInput,
    AddDocumentModal,
    CondominiumTenant,
    CreateActivityModal,
    AddressText,
    ReminderCreationModal,
    CondominiumAdjustment
  },
  mixins: [routeChecks, onboarding, validation, tables, algolia, user, toastError, media],
  props: {
    expandByDefault: {
      type: Boolean,
      default: true
    },
    expandAllChild: {
      type: Boolean,
      default: false
    },
    index: {
      type: [String, Number],
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    enableTenantSelection: {
      type: Boolean,
      default: true
    },
    isFirst: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'full',
      validator: function (value) {
        return ['full', 'adding', 'activate'].indexOf(value) !== -1
      }
    },
    condominium: {
      type: Object,
      default: null
    },
    target: {
      type: String,
      default: 'condominium'
    },
    billingContact: {
      type: Object,
      default: null
    },
    correspondenceContact: {
      type: Object,
      default: null
    },
    objectCategory: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      addDocumentToContact: false,
      includeRoommate: false,
      uniqueId: null,
      condominiumStatus: null,
      hideInprogress: false,
      dates: {
        startingAt: null,
        endingAt: null
      },
      selectedTenant: [],
      tenantContacts: [],
      contractTemplate: [],
      contractTemplateSelected: [],
      isBusy: false,
      renderContractLoading: false,
      mandatoryTerminationFields: false,
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'title',
          label: 'Title',
          thClass: 'align-middle d-sm-table-cell',
          tdClass: 'align-middle d-sm-table-cell'
        }
      ],
      title: '',
      description: '',
      usedContacts: [],
      ownerIsDebtor: false,
      currentUser: null,
      startingDate: this.condominium?.startingAt,
      selectedTemplate: ''
    }
  },
  computed: {
    ...mapGetters('message', ['getSentMails']),
    ...mapState('message', ['templates']),
    property() {
      return this.properties[0] ? this.properties[0] : null
    },
    sortedTemplate() {
      return this.templates.filter(x => x.type === 'TEMPLATE_TYPE_LETTER').slice().sort((a, b) => {
        if (a.name > b.name) {
          return 1
        }
        if (b.name > a.name) {
          return -1
        }
        return 0
      })
    },
    canBeEdited() {
      return this.object.debitUntil && this.startingDate ? new Date(this.$options.filters.objectToDateInput(this.startingDate)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(this.object.debitUntil)).getTime() / 1000 : true
    },
    isInactive() {
      return this.object && this.object?.inactiveBy && new Date(this.$options.filters.objectToDateInput(this.object?.inactiveBy)) <= new Date()
    },
    adjustments() {
      return this.condominium.rent.adjustments.slice().sort((a, b) => {
        const aDate = new Date(this.$options.filters.objectToDateInput(a.byDate)).getTime()
        const bDate = new Date(this.$options.filters.objectToDateInput(b.byDate)).getTime()
        if (bDate === 0 && b.newItem) {
          return 1
        }
        if (bDate === 0) {
          return 1
        }
        if (aDate === bDate) {
          return 0
        }
        if (aDate > bDate) {
          return -1
        }
        if (aDate < bDate) {
          return 1
        }
        return 0
      })
    },
    object() {
      return this.objects.find(x => x.id === this.condominium.objectId)
    },
    endingDatebeforeNow() {
      return this.endingAt && this.endingAt.year !== -1 && new Date(this.$options.filters.objectToDateInput(this.endingAt)).getTime() / 1000 < new Date().getTime() / 1000
    },
    contractNumber() {
      return this.condominium.numericId ? this.condominium.numericId : this.$t('message.generic.generatedAfterSave')
    },
    typeOfUseData() {
      if (this.objectCategory === 'APARTMENT' || this.objectCategory === 'HOUSE') {
        return ['TYPE_OF_USE_STORAGE', 'TYPE_OF_USE_VACATION', 'TYPE_OF_USE_GASTRONOMY', 'TYPE_OF_USE_INDUSTRIAL', 'TYPE_OF_USE_INVESTMENT', 'TYPE_OF_USE_CONSTRUCTION', 'TYPE_OF_USE_RESIDENTIAL', 'TYPE_OF_USE_COMMERCIAL']
      } else if (this.objectCategory === 'COMMERCIAL' || this.objectCategory === 'GASTRONOMY') {
        return ['TYPE_OF_USE_CONSTRUCTION', 'TYPE_OF_USE_GASTRONOMY', 'TYPE_OF_USE_INDUSTRIAL', 'TYPE_OF_USE_INVESTMENT', 'TYPE_OF_USE_STORAGE', 'TYPE_OF_USE_RESIDENTIAL', 'TYPE_OF_USE_COMMERCIAL', 'TYPE_OF_USE_OFFICE', 'TYPE_OF_USE_STORE', 'TYPE_OF_USE_ADVERTISING_AREA']
      } else if (this.objectCategory === 'GARDEN' || this.objectCategory === 'PLOT' || this.objectCategory === 'AGRICULTURE') {
        return ['TYPE_OF_USE_COMMERCIAL', 'TYPE_OF_USE_GASTRONOMY', 'TYPE_OF_USE_INDUSTRIAL', 'TYPE_OF_USE_INVESTMENT', 'TYPE_OF_USE_CONSTRUCTION']
      } else if (this.objectCategory === 'SECONDARY_ROOM') {
        return ['TYPE_OF_USE_STORAGE', 'TYPE_OF_USE_RESIDENTIAL', 'TYPE_OF_USE_SECONDARY_ROOM']
      } else if (this.objectCategory === 'PARKING_SPACE') {
        return ['TYPE_OF_USE_PARKING', 'TYPE_OF_USE_PARKING_EXTERNAL']
      } else if (this.objectCategory === 'UNDEFINED_CATEGORY') {
        return ['TYPE_OF_USE_VACATION', 'TYPE_OF_USE_COMMERCIAL', 'TYPE_OF_USE_PARKING', 'TYPE_OF_USE_PARKING_EXTERNAL', 'TYPE_OF_USE_AGRICULTURAL', 'TYPE_OF_USE_CONSTRUCTION', 'TYPE_OF_USE_GASTRONOMY', 'TYPE_OF_USE_INDUSTRIAL', 'TYPE_OF_USE_INVESTMENT', 'TYPE_OF_USE_STORAGE', 'TYPE_OF_USE_RESIDENTIAL', 'TYPE_OF_USE_OFFICE', 'TYPE_OF_USE_STORE', 'TYPE_OF_USE_ADVERTISING_AREA']
      } else {
        return []
      }
    },
    finalInvoiceCreated: {
      get() {
        return this.condominium.finalInvoiceCreated
      },
      set(value) {
        const newCondominium = this.condominium
        newCondominium.finalInvoiceCreated = value
        this.dispatchCondominiumUpdate(newCondominium)
        this.$emit('condominium-updated', newCondominium)
      }
    },
    activated: {
      get() {
        return this.condominium.activated
      },
      set(value) {
        const newCondominium = this.condominium
        newCondominium.activated = value
        this.dispatchCondominiumUpdate(newCondominium)
        this.$emit('condominium-updated', newCondominium)
      }
    },
    secondaryResidence: {
      get() {
        return this.condominium.secondaryResidence
      },
      set(value) {
        const newCondominium = this.condominium
        newCondominium.secondaryResidence = value
        this.dispatchCondominiumUpdate(newCondominium)
        this.$emit('condominium-updated', newCondominium)
      }
    },
    activatedOnLoad: {
      get() {
        if (this.condominium.activatedOnLoad !== true && this.condominium.activatedOnLoad !== false) {
          return null
        } else {
          return this.condominium.activatedOnLoad
        }
      },
      set(value) {
        const newCondominium = this.condominium
        newCondominium.activatedOnLoad = value
        this.dispatchCondominiumUpdate(newCondominium)
        this.$emit('condominium-updated', newCondominium)
      }
    },
    closed: {
      get() {
        return this.condominium.closed
      },
      set(value) {
        const newCondominium = this.condominium
        newCondominium.closed = value
        this.dispatchCondominiumUpdate(newCondominium)
        this.$emit('condominium-updated', newCondominium)
      }
    },
    billingContactOption: {
      get() {
        return this.usedContacts.find(t => {
          return t.objectID === this.condominium.owner.billingContactId || t.id === this.condominium.owner.billingContactId
        })
      },
      set(value) {
        const newCondominium = this.condominium
        if (value.length > 0) {
          newCondominium.owner.billingContactId = value[value.length - 1].objectID
          this.usedContacts.push(value[value.length - 1])
          this.usedContacts = [...this.removeDuplicatesContact(this.usedContacts)]
        } else {
          newCondominium.owner.billingContactId = null
        }
        this.dispatchCondominiumUpdate(newCondominium)
        this.$emit('condominium-updated', newCondominium)
      }
    },
    correspondenceContactOption: {
      get() {
        return this.usedContacts.find(t => {
          return t.objectID === this.condominium.owner.correspondenceContactId || t.id === this.condominium.owner.correspondenceContactId
        })
      },
      set(value) {
        const newCondominium = this.condominium
        if (value.length > 0) {
          newCondominium.owner.correspondenceContactId = value[value.length - 1].objectID
          this.usedContacts.push(value[value.length - 1])
          this.usedContacts = [...this.removeDuplicatesContact(this.usedContacts)]
        } else {
          newCondominium.owner.correspondenceContactId = null
        }
        this.dispatchCondominiumUpdate(newCondominium)
        this.$emit('condominium-updated', newCondominium)
      }
    },
    hasNoAddress() {
      return this.tenants.filter(x => x && !(x.address.zip && x.address.city && x.address.country))
    },
    documents() {
      return this.getFilteredCondominiumDocuments(this.condominium.documentIds).sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    endingAt: {
      get() {
        return this.condominium.endingAt !== '-1' ? this.condominium.endingAt : null
      },
      set(value) {
        const newCondominium = this.condominium
        newCondominium.endingAt = value
        this.dispatchCondominiumUpdate(newCondominium)
        this.$emit('condominium-updated', newCondominium)
      }
    },
    licensePlate: {
      get() {
        return this.condominium.contract.licensePlate
      },
      set(value) {
        const newCondominium = this.condominium
        newCondominium.contract.licensePlate = value
        this.dispatchCondominiumUpdate(newCondominium)
        this.$emit('condominium-updated', newCondominium)
      }
    },
    intendedUse: {
      get() {
        return this.condominium.contract.intendedUse
      },
      set(value) {
        const newCondominium = this.condominium
        newCondominium.contract.intendedUse = value
        this.dispatchCondominiumUpdate(newCondominium)
        this.$emit('condominium-updated', newCondominium)
      }
    },
    contractAmendments: {
      get() {
        return this.condominium.contract.amendments
      },
      set(value) {
        const newCondominium = this.condominium
        newCondominium.contract.amendments = value
        this.dispatchCondominiumUpdate(newCondominium)
        this.$emit('condominium-updated', newCondominium)
      }
    },
    getLandlordPossibleMonths() {
      const array = []
      for (const month of this.condominium.terminationLandlord.possibleMonths) {
        array.push(this.$t('message.generic.months.' + month))
      }
      return array.join(', ')
    },
    getTenantPossibleMonths() {
      const array = []
      for (const month of this.condominium.terminationTenant.possibleMonths) {
        array.push(this.$t('message.generic.months.' + month))
      }
      return array.join(', ')
    },
    numberOfPersons: {
      get() {
        return this.condominium.owner.numberOfPersons !== '-1' && this.condominium.owner.numberOfPersons !== -1 ? this.condominium.owner.numberOfPersons : null
      },
      set(value) {
        const newCondominium = this.condominium
        newCondominium.owner.numberOfPersons = value
        this.dispatchCondominiumUpdate(newCondominium)
        this.$emit('condominium-updated', newCondominium)
      }
    },
    startingAt: {
      get() {
        return this.condominium.startingAt !== '-1' ? this.condominium.startingAt : null
      },
      set(value) {
        const newCondominium = this.condominium
        newCondominium.startingAt = value
        this.dispatchCondominiumUpdate(newCondominium)
        this.$emit('condominium-updated', newCondominium)
      }
    },
    typeOfUse: {
      get() {
        return this.condominium.contract.typeOfUse
      },
      set(value) {
        if (value === 'TYPE_OF_USE_PARKING_EXTERNAL') {
          this.taxable = !this.properties[0].opted
        }
        const newCondominium = this.condominium
        newCondominium.contract.typeOfUse = value
        this.dispatchCondominiumUpdate(newCondominium)
        this.$emit('condominium-updated', newCondominium)
      }
    },
    taxable: {
      get() {
        return this.condominium.taxable
      },
      set(value) {
        if (value === null || value) {
          this.opted = false
        } else if (!value && this.property?.opted) {
          this.opted = true
        }
        const newCondominium = this.condominium
        newCondominium.taxable = value
        this.dispatchCondominiumUpdate(newCondominium)
        this.$emit('condominium-updated', newCondominium)
      }
    },
    opted: {
      get() {
        return this.condominium.opted || false
      },
      set(value) {
        const newCondominium = this.condominium
        newCondominium.opted = value
        this.dispatchCondominiumUpdate(newCondominium)
        this.$emit('condominium-updated', newCondominium)
      }
    },
    tenants: {
      get() {
        return this.condominium.owner.contactIds.map(tenantId => {
          return this.usedContacts.find(t => {
            return t.objectID === tenantId || t.id === tenantId
          })
        }).filter(t => {
          return t
        })
      },
      set(value) {
        const newCondominium = this.condominium
        newCondominium.owner.contactIds = value.map(v => v.objectID || v.id)
        this.usedContacts = this.usedContacts.concat(value.filter(x => x !== undefined))
        this.usedContacts = [...this.removeDuplicatesContact(this.usedContacts)]
        this.dispatchCondominiumUpdate(newCondominium)
        this.$emit('condominium-updated', newCondominium)
      }
    },
    roommateContactIds: {
      get() {
        return this.condominium.owner.roommateContactIds.map(contactId => {
          return this.usedContacts.find(t => {
            return t.objectID === contactId || t.id === contactId
          })
        }).filter(t => {
          return t
        })
      },
      set(value) {
        const newCondominium = this.condominium
        newCondominium.owner.roommateContactIds = value.map(v => v.objectID || v.id)
        this.usedContacts = this.usedContacts.concat(value.filter(x => x !== undefined))
        this.usedContacts = [...this.removeDuplicatesContact(this.usedContacts)]
        this.dispatchCondominiumUpdate(newCondominium)
        this.$emit('condominium-updated', newCondominium)
      }
    },
    rentType: {
      get() {
        return 'RENT_TYPE_CONDOMINIUM_OWNERSHIP'
      }
    },
    getTenantsName() {
      let names = ''
      const array = this.condominium.owner.contactIds
      for (let index = 0; index < array.length; index++) {
        names = names + (index === 0 ? this.getContactName(array[index]).substr(2) : this.getContactName(array[index]))
      }
      return names
    },
    translatedMonths() {
      const array = []
      for (const month of this.availableTerminationMonths) {
        array.push({
          label: this.$t('message.generic.months.' + month),
          value: month
        })
      }
      return array
    },
    getLang() {
      return this.$store.getters['internationalization/getCurrentLanguage']
    },
    templateOwnersIds() {
      const ownersIds = [].concat(parseInt(this.condominium.ownerId))
      return ownersIds
    }
  },
  watch: {
    selectedTemplate: function (newVal) {
      if (newVal !== '') {
        const template = this.sortedTemplate.find(x => x.id === newVal)
        this.title = template.subject
        this.description = template.message
      } else {
        this.title = ''
        this.description = ''
      }
    },
    billingContact: function (newVal) {
      if (newVal) {
        Vue.set(this.condominium.tenant, 'billingContactId', newVal.id)
      } else {
        Vue.set(this.condominium.tenant, 'billingContactId', null)
      }
    },
    ownerIsDebtor: function (value) {
      if (value === true) {
        this.billingContactOption = [this.currentUser]
        this.tenants = [this.currentUser]
      }
    },
    condominiumStatus: function (newVal) {
      if (newVal === 'activeClosed') {
        this.activated = true
        this.closed = true
      } else if (newVal === 'active') {
        this.activated = true
        this.closed = false
      } else if (newVal === 'inProgress') {
        this.activated = false
        this.closed = false
      }
    },
    correspondenceContact: function (newVal) {
      if (newVal) {
        Vue.set(this.condominium.tenant, 'correspondenceContactId', newVal.id)
      } else {
        Vue.set(this.condominium.tenant, 'correspondenceContactId', null)
      }
    },
    'condominium.endingAt': function (newVal, oldVal) {
      if (oldVal !== newVal) {
        if (this.condominium.contract.type === 'LIMITED' && this.condominium.endingAt !== -1) {
          this.mandatoryTerminationFields = true
        } else {
          this.mandatoryTerminationFields = false
        }
      }
    },
    billingContactOption: function (newVal, oldVal) {
      if ((!this.tenants || this.tenants.length === 0) && JSON.stringify(oldVal) !== JSON.stringify(newVal) && newVal !== undefined) {
        this.tenants = [newVal]
      }
    },
    'condominium.contract.type': function (newVal, oldVal) {
      if (oldVal !== newVal) {
        if (this.condominium.contract.type === 'LIMITED' && this.condominium.endingAt !== -1) {
          this.mandatoryTerminationFields = true
        } else {
          this.mandatoryTerminationFields = false
        }
      }
    }
  },
  async mounted() {
    document.body.onmousedown = function(e) {
      if (e.button === 1) {
        e.preventDefault()
        return false
      }
    }
    this.dates.startingAt = this.$options.filters.objectToDateInput(this.startingAt)
    this.dates.endingAt = this.$options.filters.objectToDateInput(this.endingAt)
    if (this.condominium) {
      if (this.activatedOnLoad === null) {
        this.activatedOnLoad = this.activated
      }
      if (this.objectCategory === 'PARKING_SPACE') {
        if (this.typeOfUse === 'TYPE_OF_USE_UNDEFINED' || this.typeOfUse === '' || this.typeOfUse === null || this.typeOfUse === undefined) {
          this.typeOfUse = 'TYPE_OF_USE_PARKING'
        }
      } else if (this.objectCategory === 'HOUSE' || this.objectCategory === 'APARTMENT') {
        if (this.typeOfUse === 'TYPE_OF_USE_UNDEFINED' || this.typeOfUse === '' || this.typeOfUse === null || this.typeOfUse === undefined) {
          this.typeOfUse = 'TYPE_OF_USE_RESIDENTIAL'
        }
        if (this.rentType === 'RENT_TYPE_UNDEFINED' || this.rentType === '' || this.rentType === null || this.rentType === undefined) {
          this.rentType = 'RENT_TYPE_VMWG'
        }
      }

      if (!this.usedContacts || this.usedContacts.length === 0) {
        const contactsToDownload = this.condominium.owner.contactIds.concat(this.condominium.owner.correspondenceContactId)
        this.availableTenants([...new Set(contactsToDownload.filter(x => x !== null && x !== ''))])
      } else {
        this.tenantContacts = this.usedContacts.filter(contact => this.condominium.owner.contactIds.includes(contact.id))
      }
      this.loadInstantSearch(true)
      this.loadInstantSearchInternal()
      if (this.condominium && this.condominium.owner) {
        const contactsToLoadAlgolia = [this.condominium.owner.correspondenceContactId]
          .concat([this.condominium.owner.billingContactId])
          .concat(this.condominium.owner.contactIds)
          .concat(this.condominium.owner.roommateContactIds)
        this.availableTenants([...new Set(contactsToLoadAlgolia.filter(x => x !== null && x !== ''))])
      }
      let i = 0
      this.condominium.rent.adjustments.forEach(x => {
        x.internalId = Date.now() + i
        i = i + 1
      })
    }
    if (this.condominium.activated === true && this.condominium.closed === true) {
      this.condominiumStatus = 'activeClosed'
    } else if (this.condominium.activated === true && this.condominium.closed === false) {
      this.condominiumStatus = 'active'
      this.hideInprogress = true
    } else if (this.condominium.activated === false && this.condominium.closed === false) {
      this.condominiumStatus = 'inProgress'
    }
    if (this.condominium.opted === false && this.condominium.taxable === false) {
      this.taxable = null
    }
    if (this.readOnly) {
      this.fillWholesInGreyLines()
    }
    if (!this.readOnly) {
      this.loadCurrentOwner()
    }
    await this.$nextTick(function () {
      this.condominium.modified = false
      this.resetChanged()
    })
    if (this.condominium.contract.type === 'LIMITED' && this.condominium.endingAt) {
      this.mandatoryTerminationFields = true
    }
  },
  beforeDestroy() {
    this.$root.$off('bv::collapse::state')
  },
  methods: {
    ...mapMutations('message', [
      'setSendMailContacts',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply',
      'setSubject',
      'setAdditionalText',
      'setUseBcc',
      'setBccReferenceIds'
    ]),
    downloadAllDocuments(e) {
      e.preventDefault()
      const idsAndNames = this.documents.map(x => ({ id: x.versions[x.versions.length - 1].mediaId, mediumName: x.name }))
      const contacts = [...new Set(this.objectOwnerContacts.map(x => x.id))].map(id => { return this.objectOwnerContacts.find(a => a.id === id) })
      const condoOwnersName = contacts.filter(x => this.condominium.owner.contactIds.includes(x.id)).map(y => y.name).join(', ')
      const nameOfMergedFile = this.$tc('message.generic.documents') + ' - ' + condoOwnersName
      this.mergeDownloadAllDocuments(idsAndNames, nameOfMergedFile)
    },
    openActivities() {
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerActivitiesTicketView', params: { id: this.condominium.id }, query: { view: 'tickets' } }, () => {})
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminActivitiesTicketView', params: { id: this.condominium.id }, query: { view: 'tickets' } }, () => {})
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetActivitiesTicketView', params: { id: this.condominium.id }, query: { view: 'tickets' } }, () => {})
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingActivitiesTicketView', params: { id: this.condominium.id }, query: { view: 'tickets' } }, () => {})
      }
    },
    openActivitiesMiddle() {
      let routeData = ''
      if (this.isOwnerModule) {
        routeData = this.$router.resolve({ name: 'OwnerActivitiesTicketView', params: { id: this.condominium?.id }, query: { view: 'tickets' } }, () => { })
      } else if (this.isAdminModule) {
        routeData = this.$router.resolve({ name: 'AdminActivitiesTicketView', params: { id: this.condominium?.id }, query: { view: 'tickets' } }, () => { })
      } else if (this.isAssetModule) {
        routeData = this.$router.resolve({ name: 'AssetActivitiesTicketView', params: { id: this.condominium?.id }, query: { view: 'tickets' } }, () => { })
      } else if (this.isAccountingModule) {
        routeData = this.$router.resolve({ name: 'AccountingActivitiesTicketView', params: { id: this.condominium?.id }, query: { view: 'tickets' } }, () => { })
      } else {
        routeData = this.$router.resolve({ name: 'MarketingActivitiesTicketView', params: { id: this.condominium.id }, query: { view: 'tickets' } }, () => { })
      }
      window.open(routeData.href, '_blank')
    },
    createTicket() {
      const defaultAssignee = this.property?.ownerId
      const list = [this.condominium?.id]
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerTicketCreation', query: { fromBulk: true, ids: list, defaultAssignee: defaultAssignee } })
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminTicketCreation', query: { fromBulk: true, ids: list, defaultAssignee: defaultAssignee } })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetTicketCreation', query: { fromBulk: true, ids: list, defaultAssignee: defaultAssignee } })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingTicketCreation', query: { fromBulk: true, ids: list, defaultAssignee: defaultAssignee } })
      }
    },
    navigateToCreateOrder() {
      const currentRoute = { name: this.$router.currentRoute.name, params: this.$router.currentRoute.params, query: this.$router.currentRoute.query }
      localStorage.setItem('LS_ROUTE_KEY', JSON.stringify(currentRoute))
      const condominiumIds = this.condominium.id
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerCreateOrderView', query: { condoOwnerIds: condominiumIds, source: 'condominium' } }, () => {})
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminCreateOrderView', query: { condoOwnerIds: condominiumIds, source: 'condominium' } }, () => {})
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetCreateOrderView', query: { condoOwnerIds: condominiumIds, source: 'condominium' } }, () => {})
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingCreateOrderView', query: { condoOwnerIds: condominiumIds, source: 'condominium' } }, () => {})
      }
    },
    editObject(data) {
      for (const item of data) {
        if (item.objectID) {
          item.id = item.objectID
        }
      }
      return data
    },
    hideRenderContractModal() {
      this.$bvModal.hide(`create-contract-confirmationModal-${this.index}`)
      this.contractTemplateSelected = []
    },
    loadCurrentOwner() {
      ContactApi.getOwnerContact(this.condominium.ownerId)
        .then(response => {
          if (response.person) {
            this.currentUser = response.person
            this.currentUser.objectID = this.currentUser.id
          } else if (response.company) {
            this.currentUser = response.company
            this.currentUser.objectID = this.currentUser.id
          }
          if (this.condominium.owner.contactIds.length === 1 && this.condominium.owner.contactIds.find(x => x === this.currentUser.id) && this.condominium.owner.contactIds[0] === this.condominium.owner.billingContactId) {
            this.ownerIsDebtor = true
            this.$nextTick(function () {
              this.condominium.modified = false
              this.resetChanged()
            })
          }
        })
    },
    addAdjustmentClicked() {
      this.condominium.rent.adjustments.forEach(adj => {
        if (adj.visibleCollaps) {
          adj.visibleCollaps = false
        }
      })
      const adjustmentFlatRate = []
      const adjustmentAdditionalRent = []
      const baseAdjustment = (this.adjustments || []).reduce((p, c) => new Date(this.$options.filters.objectToDateInput(p.byDate)) > new Date(this.$options.filters.objectToDateInput(c.byDate)) ? p : c)
      const property = this.properties[0]
      property.flatRateRentComponents.forEach(element => {
        if (element.inactive === false) {
          const item = baseAdjustment.flatRateValues.find(e => e.type === element.rentComponent)
          adjustmentFlatRate.push({
            type: element.rentComponent,
            value: item ? item.value : null
          })
        }
      })
      property.additionalRentComponents.forEach(element => {
        if (element.inactive === false) {
          const item = baseAdjustment.additionalValues.find(e => e.type === element.rentComponent)
          adjustmentAdditionalRent.push({
            type: element.rentComponent,
            value: item ? item.value : null
          })
        }
      })
      this.condominium.rent.adjustments.unshift({
        internalId: Date.now(),
        byDate: null,
        newItem: true,
        dueDates: [1, 4, 7, 10],
        flatRateValues: adjustmentFlatRate,
        additionalValues: adjustmentAdditionalRent,
        finalized: false,
        visibleCollaps: true
      })
      this.dispatchCondominiumUpdate(this.condominium)
      this.$emit('condominium-updated', this.condominium)
      this.$nextTick(() => {
        if (this.$el) {
          const domRect = this.$el.querySelector('#collapse-condominium-adjustedcosts-index-0')
          if (domRect) {
            domRect.scrollIntoView(true)
            window.scrollBy(0, -50)
            this.$el.querySelector('#collapse-condominium-adjustedcosts-index-0 input[type="date"]')?.focus()
          }
        }
      })
    },
    changeAdjustment(newAdjustment) {
      const index = this.condominium.rent.adjustments.findIndex(adj => adj.internalId === newAdjustment.internalId)
      const newCondominium = this.condominium
      newCondominium.rent.adjustments[index] = newAdjustment
      this.dispatchCondominiumUpdate(newCondominium)
      this.$emit('condominium-updated', newCondominium)
    },
    deleteAdjustmentClicked(index) {
      this.$delete(this.condominium.rent.adjustments, this.condominium.rent.adjustments.findIndex(adj => adj.internalId === index))
      this.$emit('condominium-updated', this.condominium)
      this.dispatchCondominiumUpdate(this.condominium)
    },
    fillWholesInGreyLines() {
      if (this.$refs['objcondominiumrow-' + this.condominium.id]?.children?.length % 4 !== 0) {
        const div = document.createElement('div')
        div.classList.add('col-sm-12', 'col-md-3', 'd-sm-none', 'd-md-block')
        this.$refs['objcondominiumrow-' + this.condominium.id].appendChild(div)
        this.fillWholesInGreyLines()
      } else {
        this.paintWhiteAndGreyLines()
      }
    },
    paintWhiteAndGreyLines() {
      if (this.$refs['objcondominiumrow-' + this.condominium.id]) {
        const arrayOfChildren = this.$refs['objcondominiumrow-' + this.condominium.id].children
        for (let index = 0; index < arrayOfChildren.length; index++) {
          if (arrayOfChildren[index]) {
            if ((index === 0) || (parseInt((index / 4)) % 2) === 0) {
              arrayOfChildren[index].classList.add('grey-col')
              arrayOfChildren[index].style.backgroundColor = 'rgba(0, 68, 95, 0.03)'
            } else {
              arrayOfChildren[index].classList.add('white-col')
            }
          }
        }
      }
    },
    getPreviousDayOfTimestamp(timestamp) {
      return timestamp - 86400
    },
    convertTimestampToISODate(timestamp) {
      if (timestamp && timestamp >= 0) {
        const date = new Date(timestamp * 1000)
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
      } else {
        return ''
      }
    },
    changeTenant() {
      const newCondominium = this.condominium
      this.dispatchCondominiumUpdate(newCondominium)
      this.$emit('condominium-updated', newCondominium)
    },
    addActivityClicked() {
      this.$refs['create-activity' + this.condominium.id].show()
    },
    addReminderClicked() {
      this.$refs['reminder-creation-modal' + this.condominium.id].show()
    },
    openSendMailModal() {
      const tenantContactsWithEmail = this.tenantContacts.filter(tenant => tenant.email !== '' && tenant.email !== null)
      this.setSendMailContacts(tenantContactsWithEmail)
      this.setSendMailPreselectedContact(tenantContactsWithEmail)
      this.setSendMailReferenceIds([this.condominium.id])
      this.setSendMailEmailToReply(null)
      this.$nextTick(function () {
        this.callShowSendMailModal()
      })
    },
    condominiumActivated() {
      this.condominium.activated = true
    },
    addTenantClicked() {
      this.condominium.tenants.push({
        contactIds: [],
        correspondenceContactId: '',
        startingAt: null,
        endingAt: null
      })
      this.dispatchCondominiumUpdate(this.condominium)
      this.$emit('condominium-updated', this.condominium)
    },
    addDocumentClicked(suffix = '') {
      this.$bvModal.show('add-document-modal' + suffix)
    },
    renderContract() {
      const promises = []
      const listTemplatePdf = []
      this.renderContractLoading = true
      for (let index = 0; index < this.contractTemplateSelected.length; index++) {
        const signatories = []
        const contract = this.contractTemplate.find(x => x.id === this.contractTemplateSelected[index])
        if (contract && !contract.mediaId.includes('.pdf')) {
          if (contract && contract.firstSignature !== '') {
            signatories.push(contract.firstSignature)
          }
          if (contract && contract.secondSignature) {
            if (contract && contract.firstSignature === '') {
              signatories.push('')
            }
            signatories.push(contract.secondSignature)
          }
          promises.push(ObjectApi.renderContractCondominium(this.contractTemplateSelected[index], this.condominium.id, this.addDocumentToContact, this.title, this.description, this.includeRoommate, this.userId, signatories))
        } else if (contract) {
          listTemplatePdf.push(contract.mediaId)
        }
      }
      if (listTemplatePdf.length > 0) {
        MediaApi.getListMedia(listTemplatePdf).then(response => {
          for (const media of response.media) {
            saveAs(media.url, media.filename)
          }
        })
      }
      Promise.all(promises)
        .then((response) => {
          const renderId = []
          response.forEach((element, index) => {
            const contract = this.contractTemplate.find(x => x.id === this.contractTemplateSelected[index])
            let downloadName = ''
            if (this.tenants.length > 0) {
              downloadName = contract.title + '_' + this.tenants.map(x => x.name).join('_') + '_' + this.condominium.numericId || this.tenants[0].numericId
            } else {
              downloadName = contract.title + '_' + this.condominium.numericId || this.object.numericId
            }
            const item = { renderId: element.renderId + '_' + downloadName + '-/-' + contract.enforcePdf, contract: contract }
            renderId.push(item)
          })
          localStorage.setItem('renderId', JSON.stringify(renderId))
        })
        .catch(e => {
          this.handleErrorOnFinish(e)
        })
        .finally(f => {
          this.hideRenderContractModal()
          this.renderContractLoading = false
          this.contractTemplateSelected = []
        })
    },
    handleErrorOnFinish(e) {
      console.log(e)
      this.loading = false
      if (e.response?.data?.message === 'Lack of data loop') {
        Vue.toasted.show(this.$t('message.savingErrors.lackOfDataLoop'), { type: 'error', duration: 10000 })
      } else {
        Vue.toasted.show(this.$t('message.loadingErrors.condominium'), { type: 'error' })
      }
    },
    getModulo(array) {
      let foo = []
      for (let i = 0; i < array.length / 4; i++) {
        foo.push(i)
      }
      return foo
    },
    dateToObject() {
      if (this.dates.startingAt && this.dates.startingAt !== '') {
        const startingAt = new Date(this.dates.startingAt)
        this.startingAt = startingAt instanceof Date && isFinite(startingAt)
? {
          day: startingAt.getDate(),
          month: startingAt.getMonth() + 1,
          year: startingAt.getFullYear()
        }
: null
      }
      if (this.dates.endingAt && this.dates.endingAt !== '') {
        const endingAt = new Date(this.dates.endingAt)
        this.endingAt = endingAt instanceof Date && isFinite(endingAt)
? {
          day: endingAt.getDate(),
          month: endingAt.getMonth() + 1,
          year: endingAt.getFullYear()
        }
: null
      } else {
        this.endingAt = null
      }
    },
    deleteTenantClicked(index) {
      this.$delete(this.condominium.tenants, index)
      this.dispatchCondominiumUpdate(this.condominium)
      this.$emit('condominium-updated', this.condominium)
    },
    deleteDocument(document) {
      this.condominium.documentIds.forEach((doc, index) => {
        if (doc === document.id) {
          this.condominium.documentIds.splice(index, 1)
        }
      }, this)
      this.dispatchCondominiumUpdate(this.condominium)
      this.$emit('condominium-updated', this.condominium)
      this.activateChanged()
    },
    dispatchCondominiumUpdate(newCondominium) {
      this.$store.dispatch('onboarding/updateCondominiumOwnership', newCondominium)
    },
    documentCreated(document) {
      // Update condominium in vuex store with new documentId
      const newCondominium = this.condominium
      newCondominium.documentIds.push(document.id)
      this.dispatchCondominiumUpdate(newCondominium)
      this.$emit('condominium-updated', newCondominium)

      // Add whole new document to vuex store to display it
      this.condominiumDocuments.push(document)
    },
    versionAdded(updatedDocument) {
      this.condominiumDocuments.forEach((doc, index) => {
        if (doc.id === updatedDocument.id) {
          this.condominiumDocuments.splice(index, 1, updatedDocument)
        }
      }, this)
      this.dispatchCondominiumUpdate(this.condominium)
      this.$emit('condominium-updated', this.condominium)
    },
    async documentCreatedAction(document) {
      // Update condominium in vuex store with new documentId
      const newCondominium = this.condominium
      newCondominium.documentIds.push(document.id)
      this.dispatchCondominiumUpdate(newCondominium)
      this.$emit('condominium-updated', newCondominium)

      // Add whole new document to vuex store to display it
      this.condominiumDocuments.push(document)
    },
    getContact(contactId) {
      const allContacts = this.contacts
        .concat(this.usedContacts)
        .concat(this.tenantContacts)

      if (this.billingContact) {
        allContacts.push(this.billingContact)
      }
      if (this.correspondenceContact) {
        allContacts.push(this.correspondenceContact)
      }
      return allContacts.find(contact => contact.id === contactId || contact.objectID === contactId)
    },
    getContactName(contactId) {
      const contact = this.getContact(contactId)
      return contact ? ', ' + contact.name : '-'
    },
    isInvalid() {
      let valid = true
      const errorMsg = []
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.scrollToError()
        valid = false
      }
      if (this.$v.startingAt.$dirty && this.$v.startingAt.$error && this.startingAt) {
        errorMsg.push(this.$t('message.savingErrors.condominiumStartingAtAfterEndingAt'))
      }
      if (this.readOnly || !this.enableTenantSelection) {
        const tenants = this.condominium.owner.contactIds.map(x => this.getContact(x))
        const hasNoAddress = tenants.filter(x => x && !(x.address.zip && x.address.city && x.address.country))
        if (hasNoAddress.length > 0) {
          errorMsg.push(this.$t('message.savingErrors.tenantHaveAddress'))
        }
      } else {
        if (this.hasNoAddress.length > 0) {
          errorMsg.push(this.$t('message.savingErrors.tenantHaveAddress'))
        }
      }
      if (this.$refs['condominium-tenants']) {
        this.$refs['condominium-tenants'].forEach(element => {
          if (element.isInvalid()) {
            valid = false
            if (!element.isCollapseOpen()) {
              errorMsg.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.buildings.objects.ownerAgreement.title') + this.$t('message.savingErrors.validationError2'))
            }
          }
        })
      }
      let isAdjustmentDateValide = false
      if (this.$refs['condominium-adjustment']) {
        this.$refs['condominium-adjustment'].forEach(element => {
          if (element.isInvalid().$invalid) {
            const v = element.isInvalid()
            if (v.dueDates.$dirty && v.dueDates.$error) {
              errorMsg.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.buildings.objects.ownerAgreement.title'))
            }
            valid = false
          }
          if (this.condominium.startingAt && element.adjustment.byDate && this.condominium.startingAt.month === element.adjustment.byDate.month && this.condominium.startingAt.day === element.adjustment.byDate.day && this.condominium.startingAt.year === element.adjustment.byDate.year) {
            isAdjustmentDateValide = true
          }
        })
        if (!isAdjustmentDateValide) {
          valid = false
          errorMsg.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.buildings.objects.ownerAgreement.title') + this.$t('message.savingErrors.validationError2'))
        }
      }
      if (errorMsg.length > 0) {
        this.showManyToast(errorMsg)
      }

      return !valid
    },
    isCollapseOpen() {
      return this.$refs.collapse.visible
    },
    dateRangeOverlaps(aStart, aEnd, bStart, bEnd) {
      if (aStart <= bStart && bStart <= aEnd) return true // b starts in a
      if (aStart <= bEnd && bEnd <= aEnd) return true // b ends in a
      if (bStart < aStart && aEnd < bEnd) return true // a in b
      return false
    },
    getIdCorrespondenceContact(obj) {
      return obj.objectID ? obj.objectID : obj.id
    },
    redirectToDetailPage(contactId) {
      if (contactId !== null) {
        if (this.isOwnerModule) {
          this.$router.push({
            name: 'OwnerContactDetailsView',
            params: { id: contactId }
          })
        } else if (this.isAssetModule) {
          this.$router.push({
            name: 'AssetContactDetailsView',
            params: { id: contactId }
          })
        } else if (this.isAccountingModule) {
          this.$router.push({
            name: 'AccountingContactDetailsView',
            params: { id: contactId }
          })
        } else {
          this.$router.push({
            name: 'AdminContactDetailsView',
            params: { id: contactId }
          })
        }
      }
    },
    redirectToDetailPageMiddleClick(contactId) {
      if (contactId !== null) {
        let route = ''
        if (this.isOwnerModule) {
          route = this.$router.resolve({
            name: 'OwnerContactDetailsView',
            params: { id: contactId }
          })
        } else if (this.isAssetModule) {
          route = this.$router.resolve({
            name: 'AssetContactDetailsView',
            params: { id: contactId }
          })
        } else if (this.isAccountingModule) {
          route = this.$router.resolve({
            name: 'AccountingContactDetailsView',
            params: { id: contactId }
          })
        } else {
          route = this.$router.resolve({
            name: 'AdminContactDetailsView',
            params: { id: contactId }
          })
        }
        window.open(route.href)
      }
    },
    removeClicked() {
      this.$refs['delete-condominium-modal'].show()
    },
    async removeConfirmed() {
      this.removeCondominiumOwnership(this.condominium)
      await this.saveOnboarding()
      if (this.$refs['delete-condominium-modal']) {
        this.$refs['delete-condominium-modal']?.forEach(element => {
          element?.hide()
        })
      }
      this.resetChanged()
      const onboardingListRoute = ['OwnerOnboardingView', 'OnboardingView', 'AdminOnboardingView', 'AssetOnboardingView', 'AccountingOnboardingView', 'OwnerBuildingDetailsView', 'AdminBuildingDetailsView', 'AssetBuildingDetailsView', 'AccountingBuildingDetailsView']
      if (!onboardingListRoute.includes(this.$route.name)) {
        if (this.isOwnerModule) {
          this.$router.push({
            name: 'OwnerBuildingDetailsView',
            params: { id: this.building.id },
            query: {
              view: this.target,
              elementSelected: this.condominium.objectId
            }
          }, () => {
          })
        } else if (this.isAssetModule) {
          this.$router.push({
            name: 'AssetBuildingDetailsView',
            params: { id: this.building.id },
            query: {
              view: this.target,
              elementSelected: this.condominium.objectId,
              from: ''
            }
          }, () => {
          })
        } else if (this.isAccountingModule) {
          this.$router.push({
            name: 'AccountingBuildingDetailsView',
            params: { id: this.building.id },
            query: {
              view: this.target,
              elementSelected: this.condominium.objectId,
              from: ''
            }
          }, () => {
          })
        } else {
          this.$router.push({
            name: 'AdminBuildingDetailsView',
            params: { id: this.building.id },
            query: {
              view: this.target,
              elementSelected: this.condominium.objectId,
              from: ''
            }
          }, () => {
          })
        }
      }
    },
    itemSelected(ids, contracts) {
      this.contractTemplateSelected = ids
      this.contractTemplate = contracts
    },
    contractsLoaded(contracts) {
      this.contractTemplate = contracts
    },
    indicesToSuggestions(indices) {
      return indices.map(({ hits }) => ({
        data: hits
      }))
    },
    selectHandler(selected) {
      if (selected && selected.item) {
        this.clearingNumber = selected.item.newIid ? selected.item.newIid : selected.item.iid
        this.bic = selected.item.bic ? selected.item.bic : ''
        this.bankName = null
        this.$nextTick(function () {
          this.bankName = selected.item.bankOrInstitutionName
        })
      }
    },
    onChange(refine, bankAccount) {
      refine(bankAccount)
    },
    activateCondominiumClicked() {
      this.$refs.activateCondominiumModal.show()
    },
    see(refine, value) {
      refine(value)
    },
    availableTenants(search) {
      ContactApi.contactResolve(search)
        .then(response => {
          const contacts = response.persons.concat(response.companies)
          this.usedContacts = this.usedContacts.concat(contacts)
        })
        .catch(e => {
          console.log(e)
        })
    }
  },
  validations() {
    const validation = {
      numberOfPersons: {
        numeric
      },
      condominiumStatus: {
        required
      },
      tenants: {
        isTenantsEmpty
      },
      billingContactOption: {
        isTenantsNull
      },
      rentType: {
        isRequired: (value) => !['RENT_TYPE_UNDEFINED', '', undefined, null].includes(value)
      },
      typeOfUse: {
        isRequired: (value) => !['TYPE_OF_USE_UNDEFINED', '', undefined, null].includes(value)
      },
      startingAt: {
        required,
        lessThanEnding
      },
      endingAt: {
        moreThanStarting
      }
    }
    if (this.isFirst === true) {
      validation.endingAt = {
        required
      }
    }
    return validation
  }
}
</script>

<style lang="scss" scoped>
h5 > span {
  font-size: 18px !important;
}

:deep(.mx-datepicker) {
  width: 100%;
}

:deep(.mx-icon-calendar) {
  display: none;
}
.grey-col{
  background:  $color-table-row-2;
}
.white-col{
 background:  $color-table-row-1;
}
:deep(.modal-xl) {
    max-width: 1140px !important;
}

.tooltip-wide :deep(.tooltip-inner) {
  max-width: 800px !important;
}
  .tooltipRoommate :deep(.tooltip-inner) {
    max-width: 250px !important;
    text-align: left !important;
  }
</style>
