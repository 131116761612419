<template>
  <nav class="sidebar-nav">
    <vue-perfect-scrollbar
      class="scroll-area"
      :settings="psSettings"
      @ps-scroll-y="scrollHandle">
      <ul
        v-if="($route.path.startsWith('/owner') > 0 && !hasAccessUserBase) || noAccess"
        class="nav">
        <!-- Dashboard -->
        <coozzy-sidebar-nav-dropdown
          v-if="!noAccess"
          id="menu-item-dashboard"
          :name="$t('message.navigation.dashboard.title')"
          :active="$route.path.startsWith('/owner/dashboard') > 0"
          class="pt-3"
          icon="home"
          route-name="OwnerDashboardOverview" />
        <hr class="sidebar-hr">
        <!-- Reporting -->
        <coozzy-sidebar-nav-dropdown
          v-if="!noAccess"
          id="menu-item-reporting"
          :name="$t('message.navigation.reporting.title')"
          :active="$route.path.startsWith('/owner/reporting') > 0"
          icon="analytics"
          route-name="OwnerReportingOverview">
          <coozzy-sidebar-nav-link
            id="menu-item-reporting-management"
            :child="true"
            :name="$t('message.navigation.reporting.management')"
            route-name="OwnerReportingManagement" />
          <coozzy-sidebar-nav-link
            id="menu-item-reporting-marketing"
            :child="true"
            :name="$t('message.navigation.reporting.marketing')"
            route-name="OwnerReportingMarketing" />
          <coozzy-sidebar-nav-link
            id="menu-item-reporting-accounting"
            :child="true"
            :name="$t('message.navigation.reporting.accounting')"
            route-name="OwnerReportingAccounting" />
        </coozzy-sidebar-nav-dropdown>
        <hr
          v-if="!noAccess"
          class="sidebar-hr">
        <!-- Properties -->
        <coozzy-sidebar-nav-dropdown
          v-if="!noAccess"
          id="menu-item-immo"
          :name="$t('message.navigation.realEstate')"
          :active="['/owner/buildings', '/owner/objects', '/owner/tenancies', '/owner/portfolios', '/owner/properties', '/owner/tenant-list'].findIndex(r => $route.path.startsWith(r)) >= 0"
          icon="map-marker-alt"
          route-name="OwnerBuildingsMapView">
          <coozzy-sidebar-nav-link
            id="menu-item-immo-properties"
            :child="true"
            :name="$t('message.navigation.management.properties')"
            route-name="OwnerPropertyView" />
          <coozzy-sidebar-nav-link
            id="menu-item-immo-buildings"
            :child="true"
            :name="$t('message.navigation.management.buildings')"
            route-name="OwnerBuildingsView" />
          <coozzy-sidebar-nav-link
            v-if="objectTypes && objectTypes.hasRentalObjects"
            id="menu-item-immo-rental-objects"
            :child="true"
            :name="$t('message.navigation.management.rentalObjects')"
            route-name="OwnerRentalObjectOverview" />
          <coozzy-sidebar-nav-link
            v-if="objectTypes && objectTypes.hasCondominiumObjects"
            id="menu-item-immo-condo-objects"
            :child="true"
            :name="$t('message.navigation.management.allCondominiumObjects')"
            route-name="OwnerCondominiumObjectOverview" />
          <coozzy-sidebar-nav-link
            v-if="objectTypes && objectTypes.hasRentalObjects"
            id="menu-item-immo-tenancies"
            :child="true"
            :name="$t('message.navigation.management.tenants')"
            route-name="OwnerTenanciesView" />
          <coozzy-sidebar-nav-link
            v-if="objectTypes && objectTypes.hasCondominiumObjects"
            id="menu-item-immo-condo"
            :child="true"
            :name="$t('message.navigation.management.allCondominiumOwners')"
            route-name="OwnerCondominiumOverview" />
        </coozzy-sidebar-nav-dropdown>
        <hr
          v-if="!noAccess && isTicketEnabled"
          class="sidebar-hr">
        <!-- tickets -->
        <coozzy-sidebar-nav-dropdown
          v-if="!noAccess && isTicketEnabled"
          id="menu-item-tickets"
          :name="$t('message.navigation.tickets')"
          route-name="OwnerTicketOverview"
          :active="$route.path.startsWith('/owner/tickets') > 0"
          icon="ticket"
          :badge="countTickets">
          <coozzy-sidebar-nav-link
            id="menu-item-tickets-draft"
            :child="true"
            :name="$t('message.navigation.draftTickets')"
            route-name="OwnerDraftTicketOverview" />
          <coozzy-sidebar-nav-link
            id="menu-item-tickets-closed"
            :child="true"
            :name="$t('message.navigation.closedTickets')"
            route-name="OwnerClosedTicketOverview" />
        </coozzy-sidebar-nav-dropdown>
        <hr
          v-if="!noAccess"
          class="sidebar-hr">
        <!-- Reminder -->
        <coozzy-sidebar-nav-dropdown
          v-if="!noAccess"
          id="menu-item-reminder"
          :name="$t('message.navigation.reminder')"
          route-name="OwnerRemindersView"
          :active="$route.path.startsWith('/owner/reminders') > 0"
          icon="bell"
          :badge="countReminders" />
        <hr
          v-if="!noAccess"
          class="sidebar-hr">
        <!-- Messages -->
        <coozzy-sidebar-nav-dropdown
          v-if="!noAccess && isEditor"
          id="menu-item-messages"
          :name="$t('message.navigation.messages')"
          route-name="OwnerMessagesView"
          :active="$route.path.startsWith('/owner/messages') > 0"
          icon="envelope"
          :badge="numberMessage">
          <coozzy-sidebar-nav-link
            id="menu-item-draft-message"
            :child="true"
            :name="$t('message.navigation.draftMessages')"
            route-name="OwnerDraftMessagesView"
            :badge="numberDraftMessage" />
        </coozzy-sidebar-nav-dropdown>
        <hr
          v-if="!noAccess && isEditor"
          class="sidebar-hr">
        <!-- Calendar -->
        <coozzy-sidebar-nav-dropdown
          v-if="!noAccess && isEditor"
          id="menu-item-calendar"
          :name="$t('message.navigation.calendar.title')"
          icon="calendar-alt"
          :active="$route.path.startsWith('/owner/calendar') > 0"
          route-name="OwnerCalendarOverview"
          :route-param="{ viewCalendar: lastUsedCalendarView, dayToFocus: null }" />
        <hr
          v-if="!noAccess && isEditor"
          class="sidebar-hr">
        <!-- Contact -->
        <coozzy-sidebar-nav-dropdown
          v-if="!noAccess"
          id="menu-item-contacts"
          :name="$t('message.navigation.directory')"
          :active="($route.path.startsWith('/owner/contacts') > 0 )"
          icon="address-book"
          route-name="OwnerContactOverview" />
        <hr
          v-if="!noAccess"
          class="sidebar-hr">
        <!-- briefButler -->
        <coozzy-sidebar-nav-dropdown
          v-if="!noAccess && isLetterEnabled"
          id="menu-item-briefButler"
          :name="$t('message.navigation.briefButler')"
          :active="($route.path.startsWith('/owner/briefButler') > 0 )"
          icon="briefButler"
          route-name="OwnerBriefButlerOverview"
          :badge="computedNumberBriefButler" />
        <hr
          v-if="!noAccess && isLetterEnabled"
          class="sidebar-hr">
        <!-- NetworkPartner -->
        <coozzy-sidebar-nav-dropdown
          v-if="!noAccess && isEditor"
          id="menu-item-network-partner"
          :name="$t('message.navigation.networkPartner')"
          :active="$route.path.startsWith('/owner/network') > 0"
          icon="users"
          route-name="OwnerNetworkPartnerView" />
        <hr
          v-if="!noAccess && isEditor"
          class="sidebar-hr">
        <!-- deepSIgn -->
        <coozzy-sidebar-nav-dropdown
          v-if="deepSignEnabled && (isCompanyAdmin || hasDigitalSignatureRole)"
          id="menu-item-deepSigns"
          :name="$t('message.navigation.deepSign')"
          :active="$route.path.includes('/deepSign') > 0"
          route-name="OwnerDeepSignOverview"
          icon="deepSign"
          :badge="countOpenSignRequests" />
        <hr
          v-if="deepSignEnabled && (isCompanyAdmin || hasDigitalSignatureRole)"
          class="sidebar-hr">
        <!-- settings -->
        <coozzy-sidebar-nav-dropdown
          id="menu-item-settings"
          :name="$t('message.navigation.settings.title')"
          :active="$route.path.includes('/settings') > 0"
          route-name="OwnerSettingsView"
          icon="cog" />
      </ul>
      <ul
        v-else-if="$route.path.startsWith('/marketing') > 0 && !hasAccessUserBase"
        class="nav">
        <!-- Dashboard -->
        <coozzy-sidebar-nav-dropdown
          id="menu-item-dashboard"
          :name="$t('message.navigation.dashboard.title')"
          :active="$route.path.startsWith('/marketing/dashboard') > 0"
          class="pt-3"
          icon="home"
          route-name="MarketingDashboardView" />
        <hr class="sidebar-hr">
        <!-- Reporting -->
        <coozzy-sidebar-nav-dropdown
          id="menu-item-reporting"
          :name="$t('message.navigation.reporting.title')"
          :active="$route.path.startsWith('/marketing/reporting') > 0"
          icon="analytics"
          route-name="MarketingReportingOverview" />
        <hr class="sidebar-hr">
        <!-- Marketing -->
        <coozzy-sidebar-nav-dropdown
          id="menu-item-reporting-marketing"
          :name="$t('message.navigation.marketing.title')"
          route-name="MarketingObjectsView"
          :active="$route.path.startsWith('/marketing/objects') > 0 || $route.path.startsWith('/marketing/publish-history')"
          icon="bullhorn">
          <coozzy-sidebar-nav-link
            id="menu-item-reporting-publish-history"
            :child="true"
            :name="$t('message.navigation.reporting.publishHistory')"
            route-name="MarketingReportingPublishHistory" />
        </coozzy-sidebar-nav-dropdown>
        <hr
          v-if="!noAccess && isTicketEnabled"
          class="sidebar-hr">
        <!-- tickets -->
        <coozzy-sidebar-nav-dropdown
          v-if="!noAccess && isTicketEnabled"
          id="menu-item-tickets"
          :name="$t('message.navigation.tickets')"
          route-name="MarketingTicketOverview"
          :active="$route.path.startsWith('/marketing/tickets') > 0"
          icon="ticket"
          :badge="countTickets">
          <coozzy-sidebar-nav-link
            id="menu-item-tickets-draft"
            :child="true"
            :name="$t('message.navigation.draftTickets')"
            route-name="MarketingDraftTicketOverview" />
          <coozzy-sidebar-nav-link
            id="menu-item-tickets-closed"
            :child="true"
            :name="$t('message.navigation.closedTickets')"
            route-name="MarketingClosedTicketOverview" />
        </coozzy-sidebar-nav-dropdown>
        <hr class="sidebar-hr">
        <!-- Reminder -->
        <coozzy-sidebar-nav-dropdown
          id="menu-item-reminder"
          :name="$t('message.navigation.reminder')"
          route-name="MarketingRemindersView"
          :active="$route.path.startsWith('/marketing/reminders') > 0"
          icon="bell"
          :badge="countReminders" />
        <hr class="sidebar-hr">
        <!-- Messages -->
        <coozzy-sidebar-nav-dropdown
          v-if="isEditor"
          id="menu-item-messages"
          :name="$t('message.navigation.messages')"
          route-name="MarketingMessagesView"
          :active="$route.path.startsWith('/marketing/messages') > 0"
          icon="envelope"
          :badge="numberMessage">
          <coozzy-sidebar-nav-link
            id="menu-item-draft-message"
            :child="true"
            :name="$t('message.navigation.draftMessages')"
            route-name="MarketingDraftMessagesView"
            :badge="numberDraftMessage" />
        </coozzy-sidebar-nav-dropdown>
        <hr
          v-if="isEditor"
          class="sidebar-hr">
        <!-- Calendar -->
        <coozzy-sidebar-nav-dropdown
          v-if="isEditor"
          id="menu-item-calendar"
          :name="$t('message.navigation.calendar.title')"
          icon="calendar-alt"
          :active="$route.path.startsWith('/marketing/calendar') > 0"
          route-name="MarketingCalendarOverview"
          :route-param="{ viewCalendar: lastUsedCalendarView, dayToFocus: null }" />
        <hr
          v-if="isEditor"
          class="sidebar-hr">
        <!-- directory -->
        <coozzy-sidebar-nav-dropdown
          id="menu-item-contacts"
          :name="$t('message.navigation.directory')"
          :active="($route.path.startsWith('/marketing/contacts') > 0 )"
          icon="address-book"
          route-name="MarketingContactOverview" />
        <hr
          class="sidebar-hr">
        <!-- briefButler -->
        <coozzy-sidebar-nav-dropdown
          v-if="!noAccess && isLetterEnabled"
          id="menu-item-briefButler"
          :name="$t('message.navigation.briefButler')"
          :active="($route.path.startsWith('/marketing/briefButler') > 0 )"
          icon="briefButler"
          route-name="MarketingBriefButlerOverview"
          :badge="computedNumberBriefButler" />
        <hr
          v-if="!noAccess && isLetterEnabled"
          class="sidebar-hr">
        <!-- deepSIgn -->
        <coozzy-sidebar-nav-dropdown
          v-if="deepSignEnabled && (isCompanyAdmin || hasDigitalSignatureRole)"
          id="menu-item-deepSigns"
          :name="$t('message.navigation.deepSign')"
          :active="$route.path.includes('/deepSign') > 0"
          route-name="MarketingDeepSignOverview"
          icon="deepSign"
          :badge="countOpenSignRequests" />
        <hr
          v-if="deepSignEnabled && (isCompanyAdmin || hasDigitalSignatureRole)"
          class="sidebar-hr">
        <!-- Settings -->
        <coozzy-sidebar-nav-dropdown
          id="menu-item-settings"
          :name="$t('message.navigation.settings.title')"
          :active="$route.path.includes('/settings') > 0"
          icon="cog"
          route-name="MarketingSettingsView" />
      </ul>
      <ul
        v-else-if="$route.path.startsWith('/accounting') > 0 && !hasAccessUserBase"
        class="nav">
        <!-- Dashboard -->
        <coozzy-sidebar-nav-dropdown
          id="menu-item-dashboard"
          :name="$t('message.navigation.dashboard.title')"
          :active="$route.path.startsWith('/accounting/dashboard') > 0"
          class="pt-3"
          icon="home"
          route-name="AccountingDashboardView" />
        <hr class="sidebar-hr">
        <!-- Reporting -->
        <coozzy-sidebar-nav-dropdown
          id="menu-item-reporting"
          :name="$t('message.navigation.reporting.title')"
          :active="$route.path.startsWith('/accounting/reporting') > 0"
          icon="analytics"
          route-name="AccountingReportingOverview">
          <coozzy-sidebar-nav-link
            id="menu-item-reporting-management"
            :child="true"
            :name="$t('message.navigation.reporting.management')"
            route-name="AccountingReportingManagement" />
          <coozzy-sidebar-nav-link
            id="menu-item-reporting-accounting"
            :child="true"
            :name="$t('message.navigation.reporting.accounting')"
            route-name="AccountingReportingAccounting" />
        </coozzy-sidebar-nav-dropdown>
        <hr class="sidebar-hr">
        <!-- Properties -->
        <coozzy-sidebar-nav-dropdown
          id="menu-item-immo"
          :name="$t('message.navigation.realEstate')"
          :active="['/accounting/buildings', '/accounting/objects', '/accounting/tenancies', '/accounting/portfolios', '/accounting/properties', '/accounting/tenant-list'].findIndex(r => $route.path.startsWith(r)) >= 0"
          icon="map-marker-alt"
          route-name="AccountingBuildingsMapView">
          <coozzy-sidebar-nav-link
            id="menu-item-immo-properties"
            :child="true"
            :name="$t('message.navigation.management.properties')"
            route-name="AccountingPropertyView" />
          <coozzy-sidebar-nav-link
            id="menu-item-immo-buildings"
            :child="true"
            :name="$t('message.navigation.management.buildings')"
            route-name="AccountingBuildingsView" />
          <coozzy-sidebar-nav-link
            id="menu-item-immo-rental-objects"
            :child="true"
            :name="$t('message.navigation.management.rentalObjects')"
            route-name="AccountingRentalObjectOverview" />
          <coozzy-sidebar-nav-link
            id="menu-item-immo-condo-objects"
            :child="true"
            :name="$t('message.navigation.management.allCondominiumObjects')"
            route-name="AccountingCondominiumObjectOverview" />
          <coozzy-sidebar-nav-link
            id="menu-item-immo-tenancies"
            :child="true"
            :name="$t('message.navigation.management.tenants')"
            route-name="AccountingTenanciesView" />
          <coozzy-sidebar-nav-link
            id="menu-item-immo-condo"
            :child="true"
            :name="$t('message.navigation.management.allCondominiumOwners')"
            route-name="AccountingCondominiumOverview" />
          <coozzy-sidebar-nav-link
            id="menu-item-immo-marketing-list"
            :child="true"
            :name="$t('message.navigation.management.marketingList')"
            route-name="AccountingMarketingObjectsView" />
        </coozzy-sidebar-nav-dropdown>
        <hr
          v-if="!noAccess && isTicketEnabled"
          class="sidebar-hr">
        <!-- tickets -->
        <coozzy-sidebar-nav-dropdown
          v-if="!noAccess && isTicketEnabled"
          id="menu-item-tickets"
          :name="$t('message.navigation.tickets')"
          route-name="AccountingTicketOverview"
          :active="$route.path.startsWith('/accounting/tickets') > 0"
          icon="ticket"
          :badge="countTickets">
          <coozzy-sidebar-nav-link
            id="menu-item-tickets-draft"
            :child="true"
            :name="$t('message.navigation.draftTickets')"
            route-name="AccountingDraftTicketOverview" />
          <coozzy-sidebar-nav-link
            id="menu-item-tickets-closed"
            :child="true"
            :name="$t('message.navigation.closedTickets')"
            route-name="AccountingClosedTicketOverview" />
        </coozzy-sidebar-nav-dropdown>
        <hr class="sidebar-hr">
        <!-- Reminder -->
        <coozzy-sidebar-nav-dropdown
          id="menu-item-reminder"
          :name="$t('message.navigation.reminder')"
          route-name="AccountingRemindersView"
          :active="$route.path.startsWith('/accounting/reminders') > 0"
          icon="bell"
          :badge="countReminders" />
        <hr class="sidebar-hr">
        <!-- Messages -->
        <coozzy-sidebar-nav-dropdown
          v-if="isEditor"
          id="menu-item-messages"
          :name="$t('message.navigation.messages')"
          route-name="AccountingMessagesView"
          :active="$route.path.startsWith('/accounting/messages') > 0"
          icon="envelope"
          :badge="numberMessage">
          <coozzy-sidebar-nav-link
            id="menu-item-draft-message"
            :child="true"
            :name="$t('message.navigation.draftMessages')"
            route-name="AccountingDraftMessagesView"
            :badge="numberDraftMessage" />
        </coozzy-sidebar-nav-dropdown>
        <hr
          v-if="isEditor"
          class="sidebar-hr">
        <!-- Calendar -->
        <coozzy-sidebar-nav-dropdown
          v-if="isEditor"
          id="menu-item-calendar"
          :name="$t('message.navigation.calendar.title')"
          icon="calendar-alt"
          :active="$route.path.startsWith('/accounting/calendar') > 0"
          route-name="AccountingCalendarOverview"
          :route-param="{ viewCalendar: lastUsedCalendarView, dayToFocus: null }" />
        <hr
          v-if="isEditor"
          class="sidebar-hr">
        <!-- directory -->
        <coozzy-sidebar-nav-dropdown
          id="menu-item-contacts"
          :name="$t('message.navigation.directory')"
          :active="($route.path.startsWith('/accounting/contacts') > 0 )"
          icon="address-book"
          route-name="AccountingContactOverview" />
        <hr
          class="sidebar-hr">
        <!-- briefButler -->
        <coozzy-sidebar-nav-dropdown
          v-if="!noAccess && isLetterEnabled"
          id="menu-item-briefButler"
          :name="$t('message.navigation.briefButler')"
          :active="($route.path.startsWith('/accounting/briefButler') > 0 )"
          icon="briefButler"
          route-name="AccountingBriefButlerOverview"
          :badge="computedNumberBriefButler" />
        <hr
          v-if="!noAccess && isLetterEnabled"
          class="sidebar-hr">
        <!-- deepSIgn -->
        <coozzy-sidebar-nav-dropdown
          v-if="deepSignEnabled && (isCompanyAdmin || hasDigitalSignatureRole)"
          id="menu-item-deepSigns"
          :name="$t('message.navigation.deepSign')"
          :active="$route.path.includes('/deepSign') > 0"
          route-name="AccountingDeepSignOverview"
          icon="deepSign"
          :badge="countOpenSignRequests" />
        <hr
          v-if="deepSignEnabled && (isCompanyAdmin || hasDigitalSignatureRole)"
          class="sidebar-hr">
        <!-- Settings -->
        <coozzy-sidebar-nav-dropdown
          id="menu-item-settings"
          :name="$t('message.navigation.settings.title')"
          :active="$route.path.includes('/settings') > 0"
          icon="cog"
          route-name="AccountingSettingsView" />
      </ul>
      <ul
        v-else-if="$route.path.startsWith('/admin') > 0 && !hasAccessUserBase"
        class="nav">
        <!-- Dashboard -->
        <coozzy-sidebar-nav-dropdown
          id="menu-item-dashboard"
          :name="$t('message.navigation.dashboard.title')"
          :active="$route.path.startsWith('/admin/dashboard') > 0"
          class="pt-3"
          icon="home"
          route-name="AdminDashboardView" />
        <hr class="sidebar-hr">
        <!-- Reporting -->
        <coozzy-sidebar-nav-dropdown
          v-if="!isJanitor"
          id="menu-item-reporting"
          :name="$t('message.navigation.reporting.title')"
          :active="$route.path.startsWith('/admin/reporting') > 0"
          icon="analytics"
          route-name="AdminReportingOverview">
          <coozzy-sidebar-nav-link
            id="menu-item-reporting-management"
            :child="true"
            :name="$t('message.navigation.reporting.management')"
            route-name="AdminReportingManagement" />
          <coozzy-sidebar-nav-link
            id="menu-item-reporting-accounting"
            :child="true"
            :name="$t('message.navigation.reporting.accounting')"
            route-name="AdminReportingAccounting" />
        </coozzy-sidebar-nav-dropdown>
        <hr
          v-if="!isPlatformEmployee && !isJanitor"
          class="sidebar-hr">
        <!-- Properties -->
        <coozzy-sidebar-nav-dropdown
          v-if="!isPlatformEmployee"
          id="menu-item-immo"
          :name="$t('message.navigation.realEstate')"
          :active="['/admin/buildings', '/admin/objects', '/admin/tenancies', '/admin/portfolios', '/admin/properties', '/admin/tenant-list'].findIndex(r => $route.path.startsWith(r)) >= 0"
          icon="map-marker-alt"
          route-name="AdminBuildingsMapView">
          <coozzy-sidebar-nav-link
            id="menu-item-immo-properties"
            :child="true"
            :name="$t('message.navigation.management.properties')"
            route-name="AdminPropertyView" />
          <coozzy-sidebar-nav-link
            id="menu-item-immo-buildings"
            :child="true"
            :name="$t('message.navigation.management.buildings')"
            route-name="AdminBuildingsView" />
          <coozzy-sidebar-nav-link
            id="menu-item-immo-rental-objects"
            :child="true"
            :name="$t('message.navigation.management.rentalObjects')"
            route-name="AdminRentalObjectOverview" />
          <coozzy-sidebar-nav-link
            id="menu-item-immo-condo-objects"
            :child="true"
            :name="$t('message.navigation.management.allCondominiumObjects')"
            route-name="AdminCondominiumObjectOverview" />
          <coozzy-sidebar-nav-link
            v-if="!isJanitor"
            id="menu-item-immo-tenancies"
            :child="true"
            :name="$t('message.navigation.management.tenants')"
            route-name="AdminTenanciesView" />
          <coozzy-sidebar-nav-link
            v-if="!isJanitor"
            id="menu-item-immo-condo"
            :child="true"
            :name="$t('message.navigation.management.allCondominiumOwners')"
            route-name="AdminCondominiumOverview" />
          <coozzy-sidebar-nav-link
            v-if="!isJanitor"
            id="menu-item-immo-marketing-list"
            :child="true"
            :name="$t('message.navigation.management.marketingList')"
            route-name="AdminMarketingObjectsView" />
        </coozzy-sidebar-nav-dropdown>
        <hr
          v-if="!noAccess && isTicketEnabled"
          class="sidebar-hr">
        <!-- tickets -->
        <coozzy-sidebar-nav-dropdown
          v-if="!noAccess && isTicketEnabled"
          id="menu-item-tickets"
          :name="$t('message.navigation.tickets')"
          route-name="AdminTicketOverview"
          :active="$route.path.startsWith('/admin/tickets') > 0"
          icon="ticket"
          :badge="countTickets">
          <coozzy-sidebar-nav-link
            id="menu-item-tickets-draft"
            :child="true"
            :name="$t('message.navigation.draftTickets')"
            route-name="AdminDraftTicketOverview" />
          <coozzy-sidebar-nav-link
            id="menu-item-tickets-closed"
            :child="true"
            :name="$t('message.navigation.closedTickets')"
            route-name="AdminClosedTicketOverview" />
        </coozzy-sidebar-nav-dropdown>
        <hr class="sidebar-hr">
        <!-- Reminder -->
        <coozzy-sidebar-nav-dropdown
          id="menu-item-reminder"
          :name="$t('message.navigation.reminder')"
          route-name="AdminRemindersView"
          :active="$route.path.startsWith('/admin/reminders') > 0"
          icon="bell"
          :badge="countReminders" />
        <hr class="sidebar-hr">
        <!-- Messages -->
        <coozzy-sidebar-nav-dropdown
          v-if="isEditor"
          id="menu-item-messages"
          :name="$t('message.navigation.messages')"
          route-name="AdminMessagesView"
          :active="$route.path.startsWith('/admin/messages') > 0"
          icon="envelope"
          :badge="numberMessage">
          <coozzy-sidebar-nav-link
            id="menu-item-draft-message"
            :child="true"
            :name="$t('message.navigation.draftMessages')"
            route-name="AdminDraftMessagesView"
            :badge="numberDraftMessage" />
        </coozzy-sidebar-nav-dropdown>
        <hr
          v-if="isEditor"
          class="sidebar-hr">
        <!-- Calendar -->
        <coozzy-sidebar-nav-dropdown
          v-if="isEditor || isJanitor"
          id="menu-item-calendar"
          :name="$t('message.navigation.calendar.title')"
          icon="calendar-alt"
          :active="$route.path.startsWith('/admin/calendar') > 0"
          route-name="AdminCalendarOverview"
          :route-param="{ viewCalendar: lastUsedCalendarView, dayToFocus: null }" />
        <hr
          v-if="isEditor || isJanitor"
          class="sidebar-hr">
        <!-- directory -->
        <coozzy-sidebar-nav-dropdown
          id="menu-item-contacts"
          :name="$t('message.navigation.directory')"
          :active="($route.path.startsWith('/admin/contacts') > 0 )"
          icon="address-book"
          route-name="AdminContactOverview" />
        <hr
          class="sidebar-hr">
        <!-- briefButler -->
        <coozzy-sidebar-nav-dropdown
          v-if="!noAccess && isLetterEnabled"
          id="menu-item-briefButler"
          :name="$t('message.navigation.briefButler')"
          :active="($route.path.startsWith('/admin/briefButler') > 0 )"
          icon="briefButler"
          route-name="AdminBriefButlerOverview"
          :badge="computedNumberBriefButler" />
        <hr
          v-if="!noAccess && isLetterEnabled"
          class="sidebar-hr">
        <!-- deepSIgn -->
        <coozzy-sidebar-nav-dropdown
          v-if="deepSignEnabled && (isCompanyAdmin || hasDigitalSignatureRole)"
          id="menu-item-deepSigns"
          :name="$t('message.navigation.deepSign')"
          :active="$route.path.includes('/deepSign') > 0"
          route-name="AdminDeepSignOverview"
          icon="deepSign"
          :badge="countOpenSignRequests" />
        <hr
          v-if="deepSignEnabled && (isCompanyAdmin || hasDigitalSignatureRole)"
          class="sidebar-hr">
        <!-- Settings -->
        <coozzy-sidebar-nav-dropdown
          id="menu-item-settings"
          :name="$t('message.navigation.settings.title')"
          :active="$route.path.includes('/settings') > 0"
          icon="cog"
          route-name="AdminSettingsView" />
      </ul>
      <ul
        v-else-if="$route.path.startsWith('/platform') > 0"
        class="nav">
        <!-- Customers -->
        <coozzy-sidebar-nav-dropdown
          name="Kunden"
          :active="$route.path.startsWith('/platform/customers') > 0"
          class="pt-3"
          icon="user-cog"
          route-name="AccountsView" />
        <hr class="sidebar-hr">
        <coozzy-sidebar-nav-dropdown
          name="Daten bearbeiten"
          :active="$route.path.startsWith('/platform/edit-data') > 0"
          icon="database"
          route-name="EditDataView" />
        <hr class="sidebar-hr">
        <coozzy-sidebar-nav-dropdown
          name="Reporting"
          :active="$route.path.startsWith('/platform/reporting') > 0"
          icon="analytics"
          route-name="PlatformReportingView" />
        <hr class="sidebar-hr">
        <coozzy-sidebar-nav-dropdown
          name="Einstellungen"
          :active="$route.path.startsWith('/platform/settings') > 0"
          icon="cog"
          route-name="PlatformSettingsView" />
      </ul>
      <ul
        v-else-if="($route.path.startsWith('/user') > 0 || $route.name === 'ApplicationFormLandingPage') && hasAccessUserBase"
        class="nav">
        <coozzy-sidebar-nav-dropdown
          :name="$t('message.generic.requests')"
          :active="$route.path.startsWith('/user/requests') > 0"
          class="pt-3"
          icon="house-user"
          route-name="UserRequests" />
        <hr class="sidebar-hr">
        <coozzy-sidebar-nav-dropdown
          :name="$t('message.generic.submitApplicationForm')"
          :active="$route.path.startsWith('/user/applicationForm') > 0"
          icon="address-card"
          route-name="ApplicationFormView" />
        <hr class="sidebar-hr">
        <coozzy-sidebar-nav-dropdown
          :name="$t('message.navigation.settings.title')"
          :active="$route.path.startsWith('/user/settings') > 0"
          icon="cog"
          route-name="UserSettings" />
        <hr class="sidebar-hr">
      </ul>
      <ul
        v-else-if="$route.path.startsWith('/asset') > 0 && hasAccessAssetBase"
        class="nav">
        <!-- Dashboard -->
        <coozzy-sidebar-nav-dropdown
          id="menu-item-dashboard"
          :name="$t('message.navigation.dashboard.title')"
          :active="$route.path.startsWith('/asset/dashboard') > 0"
          class="pt-3"
          icon="home"
          route-name="AssetDashboardView" />
        <hr class="sidebar-hr">
        <!-- Reporting -->
        <coozzy-sidebar-nav-dropdown
          id="menu-item-reporting"
          :name="$t('message.navigation.reporting.title')"
          :active="$route.path.startsWith('/asset/reporting') > 0"
          icon="analytics"
          route-name="AssetReportingOverview">
          <coozzy-sidebar-nav-link
            id="menu-item-reporting-management"
            :child="true"
            :name="$t('message.navigation.reporting.management')"
            route-name="AssetReportingManagement" />
          <coozzy-sidebar-nav-link
            id="menu-item-reporting-marketing"
            :child="true"
            :name="$t('message.navigation.reporting.marketing')"
            route-name="AssetReportingMarketing" />
          <coozzy-sidebar-nav-link
            id="menu-item-reporting-accounting"
            :child="true"
            :name="$t('message.navigation.reporting.accounting')"
            route-name="AssetReportingAccounting" />
        </coozzy-sidebar-nav-dropdown>
        <hr class="sidebar-hr">
        <!-- Properties -->
        <coozzy-sidebar-nav-dropdown
          id="menu-item-immo"
          :name="$t('message.navigation.realEstate')"
          :active="['/asset/buildings', '/asset/objects', '/asset/tenancies', '/asset/portfolios', '/asset/properties', '/asset/tenant-list'].findIndex(r => $route.path.startsWith(r)) >= 0"
          icon="map-marker-alt"
          route-name="AssetBuildingsMapView">
          <coozzy-sidebar-nav-link
            id="menu-item-immo-properties"
            :child="true"
            :name="$t('message.navigation.management.properties')"
            route-name="AssetPropertyView" />
          <coozzy-sidebar-nav-link
            id="menu-item-immo-building"
            :child="true"
            :name="$t('message.navigation.management.buildings')"
            route-name="AssetBuildingsView" />
          <coozzy-sidebar-nav-link
            v-if="objectTypes && objectTypes.hasRentalObjects"
            id="menu-item-immo-rental-objects"
            :child="true"
            :name="$t('message.navigation.management.rentalObjects')"
            route-name="AssetRentalObjectOverview" />
          <coozzy-sidebar-nav-link
            v-if="objectTypes && objectTypes.hasCondominiumObjects"
            id="menu-item-immo-condo-objects"
            :child="true"
            :name="$t('message.navigation.management.allCondominiumObjects')"
            route-name="AssetCondominiumObjectOverview" />
          <coozzy-sidebar-nav-link
            v-if="objectTypes && objectTypes.hasRentalObjects"
            id="menu-item-immo-tenancies"
            :child="true"
            :name="$t('message.navigation.management.tenants')"
            route-name="AssetTenanciesView" />
          <coozzy-sidebar-nav-link
            v-if="objectTypes && objectTypes.hasCondominiumObjects"
            id="menu-item-immo-condo"
            :child="true"
            :name="$t('message.navigation.management.allCondominiumOwners')"
            route-name="AssetCondominiumOverview" />
        </coozzy-sidebar-nav-dropdown>
        <hr
          v-if="!noAccess && isTicketEnabled"
          class="sidebar-hr">
        <!-- tickets -->
        <coozzy-sidebar-nav-dropdown
          v-if="!noAccess && isTicketEnabled"
          id="menu-item-tickets"
          :name="$t('message.navigation.tickets')"
          route-name="AssetTicketOverview"
          :active="$route.path.startsWith('/asset/tickets') > 0"
          icon="ticket"
          :badge="countTickets">
          <coozzy-sidebar-nav-link
            id="menu-item-tickets-draft"
            :child="true"
            :name="$t('message.navigation.draftTickets')"
            route-name="AssetDraftTicketOverview" />
          <coozzy-sidebar-nav-link
            id="menu-item-tickets-closed"
            :child="true"
            :name="$t('message.navigation.closedTickets')"
            route-name="AssetClosedTicketOverview" />
        </coozzy-sidebar-nav-dropdown>
        <hr class="sidebar-hr">
        <!-- Reminder -->
        <coozzy-sidebar-nav-dropdown
          id="menu-item-reminder"
          :name="$t('message.navigation.reminder')"
          route-name="AssetRemindersView"
          :active="$route.path.startsWith('/asset/reminders') > 0"
          icon="bell"
          :badge="countReminders" />
        <hr class="sidebar-hr">
        <!-- Messages -->
        <coozzy-sidebar-nav-dropdown
          v-if="isEditor"
          id="menu-item-messages"
          :name="$t('message.navigation.messages')"
          route-name="AssetMessagesView"
          :active="$route.path.startsWith('/asset/messages') > 0"
          icon="envelope"
          :badge="numberMessage">
          <coozzy-sidebar-nav-link
            id="menu-item-draft-message"
            :child="true"
            :name="$t('message.navigation.draftMessages')"
            route-name="AssetDraftMessagesView"
            :badge="numberDraftMessage" />
        </coozzy-sidebar-nav-dropdown>
        <hr
          v-if="isEditor"
          class="sidebar-hr">
        <!-- Calendar -->
        <coozzy-sidebar-nav-dropdown
          v-if="isEditor"
          id="menu-item-calendar"
          :name="$t('message.navigation.calendar.title')"
          icon="calendar-alt"
          :active="$route.path.startsWith('/asset/calendar') > 0"
          route-name="AssetCalendarOverview"
          :route-param="{ viewCalendar: lastUsedCalendarView, dayToFocus: null }" />
        <hr
          v-if="isEditor"
          class="sidebar-hr">
        <!-- Contact -->
        <coozzy-sidebar-nav-dropdown
          id="menu-item-contacts"
          :name="$t('message.navigation.directory')"
          :active="($route.path.startsWith('/asset/contacts') > 0 )"
          icon="address-book"
          route-name="AssetContactOverview" />
        <hr
          class="sidebar-hr">
        <!-- briefButler -->
        <coozzy-sidebar-nav-dropdown
          v-if="!noAccess && isLetterEnabled"
          id="menu-item-briefButler"
          :name="$t('message.navigation.briefButler')"
          :active="($route.path.startsWith('/asset/briefButler') > 0 )"
          icon="briefButler"
          route-name="AssetBriefButlerOverview"
          :badge="computedNumberBriefButler" />
        <hr
          v-if="!noAccess && isLetterEnabled"
          class="sidebar-hr">
        <!-- NetworkPartner -->
        <coozzy-sidebar-nav-dropdown
          id="menu-item-network-partner"
          :name="$t('message.navigation.networkPartner')"
          :active="$route.path.startsWith('/asset/network') > 0"
          icon="users"
          route-name="AssetNetworkPartnerView" />
        <hr class="sidebar-hr">
        <!-- deepSign -->
        <coozzy-sidebar-nav-dropdown
          v-if="deepSignEnabled && (isCompanyAdmin || hasDigitalSignatureRole)"
          id="menu-item-deepSigns"
          :name="$t('message.navigation.deepSign')"
          :active="$route.path.includes('/deepSign') > 0"
          route-name="AssetDeepSignOverview"
          icon="deepSign"
          :badge="countOpenSignRequests" />
        <hr
          v-if="deepSignEnabled && (isCompanyAdmin || hasDigitalSignatureRole)"
          class="sidebar-hr">
        <!-- settings -->
        <coozzy-sidebar-nav-dropdown
          id="menu-item-settings"
          :name="$t('message.navigation.settings.title')"
          :active="$route.path.includes('/settings') > 0"
          route-name="AssetSettingsView"
          icon="cog" />
      </ul>
    </vue-perfect-scrollbar>
  </nav>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import CoozzySidebarNavLink from '../framework/components/navigation/coreui/CoozzySidebarNavLink'
import CoozzySidebarNavDropdown from '../framework/components/navigation/coreui/CoozzySidebarNavDropdown'
import { user } from '@/mixins/user'
import { eventBus, mail } from '@/mixins/mail'
import { contract } from '@/mixins/contract'
import { reminder } from '@/mixins/reminder'
import ObjectApi from '@/misc/apis/ObjectApi'
import TicketApi from '@/misc/apis/TicketApi'
import { mapActions, mapGetters, mapState } from 'vuex'
import DeepSignApi from '@/misc/apis/DeepSignApi'
import { deepSign } from '@/mixins/deepSign'
import { ticket } from '@/mixins/ticket'
import BriefButlerApi from '@/misc/apis/BriefButlerApi'
import InvestPlanApi from '@/misc/apis/InvestPlanApi'

export default {
  name: 'CoozzySidebarNav',
  components: {
    CoozzySidebarNavDropdown,
    CoozzySidebarNavLink,
    VuePerfectScrollbar
  },
  mixins: [user, mail, reminder, deepSign, contract, ticket],
  data() {
    return {
      objectTypes: null,
      isTicketEnabled: false,
      isLetterEnabled: false,
      interval: null,
      lastUsedCalendarView: 'week'
    }
  },
  computed: {
    ...mapState('message', ['mailsLoadedAt', 'mailsDraftLoadedAt', 'readMails']),
    ...mapState('ticket', ['readTickets', 'unreadTickets']),
    ...mapGetters('message', ['getMailboxesForUserId']),
    mailboxesForCurrentUser() {
      return this.getMailboxesForUserId(this.userId)
    },
    noAccess() {
      return (!this.hasAccessMarketBase && !this.hasAccessPropBase && !this.hasAccessAccountingBase && !this.hasAccessAdminBase && !this.hasAccessUserBase && !this.hasAccessAssetBase)
    },
    psSettings: () => {
      // ToDo: find better rtl fix
      return {
        maxScrollbarLength: 200,
        minScrollbarLength: 40,
        suppressScrollX: getComputedStyle(document.querySelector('html')).direction !== 'rtl',
        wheelPropagation: false,
        interceptRailY: styles => ({
          ...styles,
          height: 0
        })
      }
    },
    countTickets() {
      return this.$store.getters['ticket/getCountTickets']
    },
    computedNumberBriefButler() {
      return this.$store.getters['contract/getNumberBriefButler']
    }
  },
  watch: {
    mailboxesForCurrentUser: function () {
      this.countUnreadMails(true)
    },
    mailsLoadedAt: function () {
      this.countUnreadMails()
    },
    mailsDraftLoadedAt: function () {
      this.countDraftMails()
    },
    briefButlerLoadedAt: function () {
      this.countBriefButler()
    },
    readMails: function () {
      this.countUnreadMails(true)
    },
    unreadMails: function () {
      this.countUnreadMails()
    },
    numberMessage: function (val, oldVal) {
      if (val > oldVal) {
        this.$store.dispatch('message/notifyNewEmails')
      }
      this.$nextTick(() => {
        this.$store.commit('message/setNewMessagesArrived', false)
      })
    }
  },
  created () {
    if (localStorage.getItem('lastUsedCalendarView')) {
      this.lastUsedCalendarView = localStorage.getItem('lastUsedCalendarView')
    }
    eventBus.$on('lastUsedCalendarView', (value) => {
        if (value !== '') {
          this.lastUsedCalendarView = value
        }
    })
  },
  mounted: function () {
    this.loadMailboxesForUserId(this.userId)
    this.countDraftMails()
    if (this.$store.getters['contract/getNumberBriefButler'] === undefined || this.$store.getters['contract/getNumberBriefButler'] === '-') {
      this.countBriefButler()
    }
    ObjectApi.getObjectTypes(this.accountId)
      .then(response => {
        this.objectTypes = response
      })
    TicketApi.hasTicketingEnabled(this.accountId)
      .then(response => {
        this.isTicketEnabled = response.enabled
        this.$store.dispatch('user/setIsTicketingEnabled', response.enabled)
      })
    InvestPlanApi.hasInvestPlanEnabled(this.accountId)
      .then(response => {
        this.$store.dispatch('user/setIsInvestPlanEnabled', response.enabled)
      })
    setTimeout(() => {
      if (this.$store.getters['user/getIsBriefButlerEnabled'] === null) {
        BriefButlerApi.loadSettings(this.accountId)
          .then(response => {
            this.isLetterEnabled = response.enabled
            this.$store.dispatch('user/setIsBriefButlerEnabled', response.enabled)
          })
          .catch(e => {
            console.error(e)
          })
      } else {
        this.isLetterEnabled = this.$store.getters['user/getIsBriefButlerEnabled']
      }
    }, this.$route.name.search('BriefButler') > 0 ? 2000 : 0)
    DeepSignApi.isDeepSignEnabled(this.accountId).then((response) => {
      this.$store.dispatch('deepSign/setDeepSignStatus', response.enabled)
    })

    // Calculate the delay until the next minute
    const now = new Date()
    const delay = 60 - now.getSeconds()
    // we make this setTimeout to wait until the next minute to start the interval to be sure that this function + filterMessages in messageView executed in the same time
    setTimeout(() => {
      this.interval = setInterval(() => this.countUnreadMails(), 30000)
    }, delay * 1000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    ...mapActions('message', ['loadMailboxesForUserId']),
    scrollHandle() {
    }
  }
}
</script>

<style lang="scss" scoped>
.scroll-area {
  position: absolute;
  height: 100%;
  margin: auto;
}

.sidebar-hr {
  margin: 4px 0 4px 0;
  border-color: rgba(255, 255, 255, .6);
}

.nav {
  width: 280px !important;
  background-color: rgba(0, 68, 95, 1) !important;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, .2))
}
</style>
