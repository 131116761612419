<template>
  <section>
    <div
      class="row">
      <div
        v-if="!finishLoadingContacts"
        class="col-12 text-center">
        <coozzy-spinner />
      </div>
      <div
        v-else
        :class="email && source === 'email' && !showTickets ? 'col-8' : 'col-12'">
        <form-wizard
          id="wizard"
          ref="wizard"
          :class="showTickets ? 'hideSteps' : ''"
          :hide-buttons="loading"
          color="rgb(0, 68, 95)"
          step-size="xs">
          <span slot="title" />
          <tab-content
            v-if="source === 'email'"
            :title="$tc('message.createOrder.selectObject')">
            <order-objects-tab
              v-if="finishLoadingContacts"
              ref="order-recipient-tab"
              :properties="properties"
              :buildings="buildings"
              :objects="objects"
              :tenancies="tenancies"
              :condo-owners="condoOwners"
              :contacts="combinedContacts"
              @loaded-data-algolia="setLoadedData"
              @object-selected="setObjectSelected" />
          </tab-content>
          <tab-content
            :title="$tc('message.createOrder.selectedDevices')">
            <div
              v-if="loading"
              class="text-center">
              <coozzy-spinner />
            </div>
            <order-device-tab
              v-else
              :buildings="buildings.concat(buildingsAlgolia)"
              :devices="devices"
              :objects="objects.concat(objectsAlgolia)"
              :properties="properties.concat(propertiesAlgolia)"
              :selected-objects="selectedObjects"
              :rooms="rooms"
              :contacts="combinedContacts"
              :tenancies="tenancies"
              :condo-owners="condoOwners"
              :preselected-devices="deviceIds"
              :source="source"
              @device-added="deviceAdded"
              @device-deleted="deviceDeleted"
              @room-added="roomAdded"
              @device-selection-updated="deviceSelectionUpdated"
              @note-changed="noteChanged" />
          </tab-content>
          <tab-content :title="$tc('message.createOrder.selectReceiver')">
            <order-recipient-tab
              v-if="finishLoadingContacts"
              ref="order-recipient-tab"
              :buildings="buildings"
              :craftsman-contacts="craftsmanContacts"
              :janitor-contacts="janitorContacts"
              :objects="objects"
              :service-provider-contacts="serviceProviderContacts"
              :source="source"
              @recipient-changed="recipientChanged"
              @load-new-added-contacts="loadData"
              @contact-updated="contactUpdated" />
          </tab-content>
          <tab-content :title="$tc('message.createOrder.title')">
            <div
              v-if="loading"
              class="text-center">
              <coozzy-spinner />
            </div>
            <order-data-tab
              v-if="!loading && employeeContacts && employeeContacts.length > 0"
              v-show="!showTickets"
              ref="orderDataTab"
              :condo-owners="condoOwners"
              :condo-owners-contact="condoOwnerContacts"
              :craftsman-contacts="craftsmanContacts"
              :employee-contacts="employeeContacts"
              :janitor-contacts="janitorContacts"
              :objects="objects"
              :operator="recipientContact"
              :owners-list="ownersList"
              :references="references"
              :service-provider-contacts="serviceProviderContacts"
              :source="source"
              :tenancies="tenancies"
              :tenant-contacts="tenantContacts"
              :rooms="rooms"
              :ticket-title="ticketTitle"
              :delegate-contacts="delegateContacts"
              @updated="orderDataUpdated"
              @selected-contract="itemSelected"
              @update-attach-auto="updateAttachAuto"
              @update-status-contact="updateStatusContacts"
              @medias-list-updated="mediasListUpdated"
              @contact-updated="contactUpdated" />
            <div
              v-if="!loading"
              v-show="showTickets">
              <template
                v-for="(ticket, index) in listTickets">
                <ticket-details-view
                  :key="'ticket_' + index"
                  :order-ticket="ticket" />
              </template>
            </div>
          </tab-content>
          <template
            #footer="props">
            <template v-if="!loading">
              <div class="wizard-footer-left">
                <wizard-button
                  v-if="props.activeTabIndex === 0"
                  :style="props.fillButtonStyle"
                  @click.native="cancelCreation()">
                  {{ $t('message.generic.cancel') }}
                </wizard-button>
                <wizard-button
                  v-else-if="props.isLastStep && showTickets"
                  :style="props.fillButtonStyle"
                  @click.native="toggleListTickets()">
                  {{ $t('message.generic.form.modify') }}
                </wizard-button>
                <wizard-button
                  v-else
                  :style="props.fillButtonStyle"
                  @click.native="backClicked(props)">
                  {{ $t('message.generic.back') }}
                </wizard-button>
              </div>
              <div class="wizard-footer-right">
                <wizard-button
                  v-if="source === 'email' ? props.activeTabIndex === 3 : props.activeTabIndex === 2"
                  class="mr-2"
                  :style="props.fillButtonStyle"
                  @click.native="saveDraft">
                  {{ $t('message.marketingObjects.editAdvert.saveDraft') }}
                </wizard-button>
                <wizard-button
                  v-if="!props.isLastStep && (source === 'email' ? props.activeTabIndex === 2 : props.activeTabIndex === 1) && !recipientContactId"
                  :style="props.fillButtonStyle"
                  class="wizard-footer-right"
                  @click.native="nextStep(props)">
                  <template>
                    {{ $t('message.createOrder.continueWithoutContact') }}
                  </template>
                </wizard-button>
                <wizard-button
                  v-else-if="!props.isLastStep"
                  :style="props.fillButtonStyle"
                  class="wizard-footer-right"
                  @click.native="nextStep(props)">
                  <template>
                    {{ $t('message.generic.next') }}
                  </template>
                </wizard-button>
                <wizard-button
                  v-else-if="props.isLastStep && !hasTicketingEnabled"
                  :disabled="contractTemplateSelected.length === 0"
                  :style="props.fillButtonStyle"
                  class="wizard-footer-right"
                  @click.native="finishProcess">
                  {{ $t('message.createOrder.title') }}
                </wizard-button>
                <template
                  v-else-if="props.isLastStep && hasTicketingEnabled && !showTickets">
                  <coozzy-form-checkbox
                    v-if="selectedDevices.length > 1"
                    v-model="createSeparateTicket"
                    :initial="createSeparateTicket"
                    class="float-left pr-3 mt-1 mr-0 display-none-xs font-weight-lighter">
                    {{ $t('message.createSeparateTicket') }}
                  </coozzy-form-checkbox>
                  <wizard-button
                    :style="props.fillButtonStyle"
                    @click.native="toggleListTickets">
                    {{ $t('message.ticketPreview') }}
                  </wizard-button>
                </template>
                <wizard-button
                  v-else-if="showTickets"
                  :disabled="disableBtns"
                  :style="props.fillButtonStyle"
                  @click.native="submitTickets(false)">
                  {{ $t('message.generic.form.send') }}
                </wizard-button>
              </div>
            </template>
          </template>
        </form-wizard>
      </div>
      <div
        v-if="finishLoadingContacts && email && source === 'email' && !showTickets"
        class="col-4 mt-5">
        <print-message
          class=" scrollable-print-message"
          :email="email"
          :medias-list="emailMediasList" />
      </div>
    </div>
    <b-modal
      id="confirmationModal"
      ref="confirmationModal"
      size="lg"
      :title="$t('message.onBoardingTabs.confirmModal.confirmationTitle')"
      hide-footer
      no-close-on-backdrop>
      <p>{{ $t('message.onBoardingTabs.confirmModal.confirmationText') }}</p>
      <div class="col">
        <coozzy-button
          class="border mb-0"
          design="transparent"
          size="small"
          @click="$bvModal.hide('confirmationModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          class="float-right mb-0"
          design="green"
          size="small"
          @click="confirmCancellation">
          {{ $t('message.onBoardingTabs.confirmModal.confirmationButton') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import Vue from 'vue'
import ObjectApi from '@/misc/apis/ObjectApi'
import ContactApi from '@/misc/apis/ContactApi'
import { obj } from '@/mixins/object'
import { formatting } from '@/mixins/formatting'
import { tables } from '@/mixins/tables'
import { routeChecks } from '@/mixins/routeChecks'
import OrderRecipientTab from '@/order/components/OrderRecipientTab'
import OrderDeviceTab from '@/order/components/OrderDeviceTab'
import OrderDataTab from '@/order/components/OrderDataTab'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import { mapActions, mapGetters } from 'vuex'
import { user } from '@/mixins/user'
import { media } from '@/mixins/media'
import TicketApi from '@/misc/apis/TicketApi'
import UserApi from '@/misc/apis/UserApi'
import RoomApi from '@/misc/apis/RoomApi'
import TicketDetailsView from '@/management/components/ticket/TicketDetailsView'
import MediaApi from '@/misc/apis/MediaApi'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'
import MessageApi from '@/misc/apis/MessageApi'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import * as Sentry from '@sentry/browser'
import PrintMessage from '@/contact/components/PrintMessage.vue'
import OrderObjectsTab from '@/order/components/OrderObjectsTab.vue'

export default {
  name: 'CreateOrderView',
  components: {
    OrderObjectsTab,
    PrintMessage,
    CoozzyButton,
    CoozzyFormCheckbox,
    TicketDetailsView,
    CoozzySpinner,
    OrderDataTab,
    OrderDeviceTab,
    OrderRecipientTab
  },
  mixins: [user, formatting, obj, routeChecks, tables, media],
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  beforeRouteLeave(to, from, next) {
    this.nextToPath = to
    if (!this.confirmCancellationProcess) {
      this.$refs.confirmationModal.show()
    } else {
      next()
    }
  },
  metaInfo() {
    return {
      title: this.$t('message.onBoarding.devices.createOrder')
    }
  },
  props: {
    buildingIds: {
      type: String,
      default: ''
    },
    deviceIds: {
      type: String,
      default: ''
    },
    propertyIds: {
      type: String,
      default: ''
    },
    objectIds: {
      type: String,
      default: ''
    },
    tenancyIds: {
      type: String,
      default: ''
    },
    condoOwnerIds: {
      type: String,
      default: ''
    },
    roomId: {
      type: String,
      default: ''
    },
    contactIds: {
      type: String,
      default: ''
    },
    mediaIds: {
      type: String,
      default: ''
    },
    source: {
      // values ['property', 'building', 'object', 'condominium', 'device', 'tenancy', 'condoOwner', 'email']
      type: String,
      default: ''
    },
    linkedTicketId: {
      type: String,
      default: ''
    },
    emailsData: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      recipientContactId: '',
      recipientContact: null,
      devices: [],
      objects: [],
      buildings: [],
      properties: [],
      objectsAlgolia: [],
      buildingsAlgolia: [],
      propertiesAlgolia: [],
      condoOwnerContacts: [],
      tenantContacts: [],
      janitorContacts: [],
      craftsmanContacts: [],
      serviceProviderContacts: [],
      loading: false,
      selectedEmployee: '',
      onSiteContactType: '',
      onSiteContactPersonIds: [],
      contact: {
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
      },
      executeFrom: null,
      executeUntil: null,
      orderDescription: '',
      devicesForApi: [],
      attachAutomatically: false,
      contractTemplateSelected: [],
      contractTemplate: [],
      hasTicketingEnabled: false,
      createSeparateTicket: false,
      listTickets: [],
      showTickets: false,
      disableBtns: false,
      ticketKeys: [],
      mediasList: [],
      ownersList: [],
      ownersIds: [],
      tenancies: [],
      condoOwners: [],
      references: [],
      rooms: [],
      finishLoadingContacts: false,
      priority: 'DEFAULT',
      selectedContactsUpdatesStatus: [],
      finishLoadingReferences: false,
      confirmCancellationProcess: false,
      nextToPath: null,
      contacts: [],
      ticketTitle: '',
      delegateContacts: [],
      email: null,
      selectedDevices: [],
      selectedObjects: [],
      emailMediasList: []
    }
  },
  computed: {
    employeeContacts() {
      return (this.$store.getters['employee/getEmployees'] || []).filter(
        e => !e.deleted && !e.blocked
      )
    },
    combinedContacts() {
      return [
        ...this.janitorContacts,
        ...this.craftsmanContacts,
        ...this.serviceProviderContacts,
        ...this.tenantContacts,
        ...this.condoOwnerContacts,
        ...this.delegateContacts
      ]
    },
    prevRoutePath() {
      return this.prevRoute ? this.prevRoute.path : '/'
    },
    prevRouteQuery() {
      return this.prevRoute ? this.prevRoute.query : null
    }
  },
  watch: {
    devices: function (newVal, oldVal) {
      if (newVal.length !== oldVal.length) {
        this.devicesForApi = []
        for (let i = 0; i < this.devices.length; i++) {
          this.devicesForApi.push({
            id: this.devices[i].id,
            note: ''
          })
        }
      }
    },
    finishLoadingReferences: function (newVal) {
      if (newVal === true) {
        this.loadOwners()
        this.loadJanitors()
        this.loadCraftsmans()
        this.loadServiceProviders()
        this.loadDelegates()
        this.$nextTick(() => {
          this.finishLoadingContacts = true
        })
      }
    }
  },
  mounted() {
    this.loadData()
    TicketApi.hasTicketingEnabled(this.accountId)
      .then(response => {
        this.hasTicketingEnabled = response.enabled
        this.getReservedKeys(1)
      })
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    async loadData() {
      try {
        if (this.source === 'device' || (this.source === 'ticket' && this.deviceIds)) {
          await this.loadDevices()
        }
        if (this.source === 'object' || this.source === 'condominium' || (this.source === 'ticket' && this.objectIds)) {
          const objectIds = typeof this.objectIds === 'string' ? this.objectIds.split(',') : this.objectIds
          await this.loadObjects(objectIds)
          await this.getDevicesByRefId(objectIds)
          this.selectedObjects = this.objects.filter(x => objectIds.includes(x.id))
        }
        if (this.source === 'building' || (this.source === 'ticket' && this.buildingIds)) {
          const buildingIds = typeof this.buildingIds === 'string' ? this.buildingIds.split(',') : this.buildingIds
          await this.loadBuildings(buildingIds, [])
          await this.getDevicesByRefId(buildingIds)
          this.selectedObjects = this.buildings.filter(x => buildingIds.includes(x.id))
        }
        if (this.source === 'property' || (this.source === 'ticket' && this.propertyIds)) {
          const propertyIds = typeof this.propertyIds === 'string' ? this.propertyIds.split(',') : this.propertyIds
          await this.loadProperties(propertyIds)
          await this.getDevicesByRefId(propertyIds)
          this.selectedObjects = this.properties.filter(x => propertyIds.includes(x.id))
        }
        if (this.source === 'tenancy' || (this.source === 'ticket' && this.tenancyIds)) {
          const tenancyIds = typeof this.tenancyIds === 'string' ? this.tenancyIds.split(',') : this.tenancyIds
          await this.loadTenancies(tenancyIds)
        }
        if (this.source === 'condoOwner' || (this.source === 'ticket' && this.condoOwnerIds)) {
          const condoOwnerIds = typeof this.condoOwnerIds === 'string' ? this.condoOwnerIds.split(',') : this.condoOwnerIds
          await this.loadCondoOwners(condoOwnerIds)
        }
        if (this.source === 'room' || (this.source === 'ticket' && this.roomId)) {
          await this.loadRooms([this.roomId])
        }
        if (this.source === 'ticket' && this.contactIds) {
          await this.loadContacts()
        }
        if (this.source === 'email' && this.contactIds) {
          await this.loadContactsAssets()
        }
        if (this.source === 'email') {
          const emailId = JSON.parse(this.emailsData)?.id
          await MessageApi.listMailsByIds([emailId]).then(response => {
            if (response.mails?.length > 0) {
              this.email = response.mails[0]
              if (this.email.attachments?.length) {
                for (let index = 0; index < this.email.attachments.length; index++) {
                  MediaApi.getMediaByID(this.email.attachments[index].mediaId)
                    .then(response => {
                      this.emailMediasList.push(response)
                    })
                    .catch(() => {
                      Vue.toasted.show(this.$t('message.loadingErrors.media'), { type: 'error' })
                    })
                }
              } else if (this.email.attachmentMediaIds?.length) {
                MediaApi.getListMedia(this.email.attachmentMediaIds)
                  .then(response => {
                    for (const media of response.media) {
                      this.emailMediasList.push(media)
                    }
                  })
                  .catch(() => {
                    Vue.toasted.show(this.$t('message.loadingErrors.media'), { type: 'error' })
                  })
              } else {
                this.emailMediasList = []
              }
            }
          })
        }
      } finally {
        if (this.source !== 'email') {
          await this.loadReferences()
        }
      }
    },
    async loadContactsAssets() {
      const contactIds = this.contactIds.split(',')
      let promises = []
      contactIds.forEach(contactId => {
        promises.push(ObjectApi.getTenancyListByContactId(contactId, true, true, true, true, true))
        promises.push(ObjectApi.getCondominiumListById(contactId, true, true, true, true, true))
      })
      Promise.all(promises).then(async (response) => {
        response.forEach(element => {
          if (element.condominiums) {
            this.condoOwners = this.condoOwners.concat(element.condominiums)
          } else {
            this.tenancies = this.tenancies.concat(element.tenancies)
          }
        })
      // remove duplicated items
        this.condoOwners = this.getCurrentCondominiums()
        this.tenancies = this.getCurrentTenancies()
        const condoOwnerContactIds = this.condoOwners.flatMap(c => [
          ...(c.owner?.contactIds || []),
          ...(c.owner?.roommateContactIds || []),
          c?.owner.billingContactId,
          c?.owner.correspondenceContactId
        ].filter(Boolean))
        const tenantContactIds = this.tenancies.flatMap(t => [
          ...(t.tenant?.contactIds || []),
          ...(t.tenant?.guarantorContactIds || []),
          t?.tenant.billingContactId,
          t?.tenant.correspondenceContactId
        ].filter(Boolean))
        const contactIds = [...condoOwnerContactIds, ...tenantContactIds]
        await this.loadContacts(contactIds)
        const objectIds = this.tenancies.concat(this.condoOwners).map(x => x.objectId) || []
        await this.loadObjects(objectIds)
        const buildingIds = this.objects.map(x => x.buildingId) || []
        const buildingResponse = await ObjectApi.getBuildingsListByIds(buildingIds)
        this.buildings = buildingResponse.buildings
        const propResponse = await ObjectApi.getPropertiesByBuildingIds(buildingIds)
        this.properties = propResponse.properties
        const roomsResponse = await RoomApi.getRoomsByReferenceIds(objectIds.concat(buildingIds))
        this.rooms = roomsResponse.rooms
        this.finishLoadingReferences = true
      })
    },
    getCurrentTenancies() {
      const startOfDayTimestamp = this.getStartOfDayTimestamp()
      const list = this.filterDuplicateObjectsById(this.tenancies)
      return list.filter(t => t.startingAt && new Date(this.$options.filters.objectToDateInput(t.startingAt)).getTime() / 1000 <= startOfDayTimestamp && (!t.endingAt || (new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 < 0 || new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 >= startOfDayTimestamp)))
    },
    getCurrentCondominiums() {
      const startOfDayTimestamp = this.getStartOfDayTimestamp()
      const list = this.filterDuplicateObjectsById(this.condoOwners)
      return list.filter(t => t.startingAt && new Date(this.$options.filters.objectToDateInput(t.startingAt)).getTime() / 1000 <= startOfDayTimestamp && (!t.endingAt || (new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 < 0 || new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 >= startOfDayTimestamp)))
    },
    getStartOfDayTimestamp() {
      // Because of timezone offset we need to so some trickery here. This only works for timezones with a positive offset
      const now = new Date()
      const localDay = now.getDate()
      now.setUTCHours(0, 0, 0, 0)
      let startOfDayTimestamp
      if (now.getDate() !== localDay) {
        startOfDayTimestamp = Math.trunc((now.getTime() + 86400000) / 1000)
      } else {
        startOfDayTimestamp = Math.trunc(now.getTime() / 1000)
      }

      return startOfDayTimestamp
    },
    setObjectSelected(listObjects) {
      this.selectedObjects = listObjects
      const ids = listObjects.map(x => x.id)
      this.getDevicesByRefId(ids)
      if (listObjects.length > 0) {
        const item = listObjects[0]
        this.loadAdditionalTenants(item)
      }
    },
    loadAdditionalTenants(item) {
      if (item.id.startsWith('prop_')) {
        const buildingIds = item.buildingIds
        let objectIds = []
        buildingIds.forEach(element => {
          const building = this.buildings.find(x => x.id === element)
          if (building) {
            objectIds = objectIds.concat(building.objectIds)
          }
        })
        if (item.type === 'PROPERTY_TYPE_RENTAL') {
          this.loadTenantContacts(objectIds)
        } else {
          this.loadCondominiumContacts(objectIds)
        }
      } else if (item.id.startsWith('building_')) {
        const property = this.properties.find(x => x.buildingIds.includes(item.id))
        const building = this.buildings.find(x => x.id === item.id)
        if (property.type === 'PROPERTY_TYPE_RENTAL') {
          this.loadTenantContacts(building.objectIds)
        } else {
          this.loadCondominiumContacts(building.objectIds)
        }
      }
    },
    async getDevicesByRefId(ids) {
      const deviceResponse = await ObjectApi.getDevicesByReferenceIds(ids)
      this.devices = deviceResponse.devices
      if (this.source !== 'room') {
        let roomIds = this.devices.map(x => x.roomId).filter(Boolean)
        roomIds = [...new Set(roomIds)]
        await this.loadRooms(roomIds)
      }
    },
    deviceAdded(device) {
      const index = this.devices.findIndex(x => x.id === device.id)
      if (index === -1) {
        this.devices.unshift(device)
      } else {
        this.devices.splice(index, 1, device)
      }
    },
    deviceDeleted(device) {
      if (device.internalId) {
        const index = this.devices.findIndex(x => x.internalId === device.internalId)
        if (index !== -1) {
          this.devices.splice(index, 1)
        }
      } else {
        ObjectApi.deleteDevice(device.id).then(() => {
          const index = this.devices.findIndex(x => x.id === device.id)
          if (index !== -1) {
            this.devices.splice(index, 1)
          }
        }).catch(() => {
          Vue.toasted.show(this.$tc('message.deleteErrors.device'), { type: 'error' })
        })
      }
    },
    roomAdded(room) {
      const index = this.rooms.findIndex(x => x.id === room.id)
      if (index === -1) {
        this.rooms.unshift(room)
      } else {
        this.rooms.splice(index, 1, room)
      }
    },
    deviceSelectionUpdated(devices) {
      this.selectedDevices = devices
    },
    setLoadedData(data) {
      if (data.object) {
        this.objectsAlgolia.push(data.object)
      }
      if (data.building) {
        this.buildingsAlgolia.push(data.building)
      }
      if (data.property) {
        this.propertiesAlgolia.push(data.property)
      }
    },
    confirmCancellation() {
      this.confirmCancellationProcess = true
      if (this.nextToPath !== null) {
        this.$router.push({
          name: this.nextToPath.name,
          params: this.nextToPath.params,
          query: this.nextToPath.query
        }, () => {
        })
      } else {
        this.$router.push({
          path: this.prevRoutePath,
          query: this.prevRouteQuery
        }, () => {
        })
      }
    },
    contactUpdated(contact) {
      if (this.craftsmanContacts.findIndex(element => element.id === contact.id) !== -1) {
        this.loadCraftsmans()
      }
      if (this.serviceProviderContacts.findIndex(element => element.id === contact.id) !== -1) {
        this.loadServiceProviders()
      }
      if (this.janitorContacts.findIndex(element => element.id === contact.id) !== -1) {
        this.loadJanitors()
      }
      if (this.tenantContacts.findIndex(element => element.id === contact.id) !== -1) {
        const objectIds = this.objects.filter(x => x.condominiumOwnership === false).map(x => x.id)
        this.loadTenantContacts(objectIds)
      }
      if (this.condoOwnerContacts.findIndex(element => element.id === contact.id) !== -1) {
        const objectIds = this.objects.filter(x => x.condominiumOwnership === true).map(x => x.id)
        this.loadCondominiumContacts(objectIds)
      }
    },
    getReservedKeys(amount) {
      TicketApi.reserveKeys(this.accountId, amount).then(response => {
        this.ticketKeys = this.ticketKeys.concat(response.keys.reverse())
      })
    },
    cancelCreation() {
      if (this.listTickets.length > 0 && this.listTickets[0].listAttachement.length > 0) {
        const ids = this.listTickets[0].listAttachement.map(x => x.renderId)
        ObjectApi.deleteRenderFiles(ids).then(() => {
          this.$router.push({ path: this.prevRoutePath, query: this.prevRouteQuery })
        })
          .catch(exeption => {
            console.error(exeption)
          })
      } else {
        this.$router.push({ path: this.prevRoutePath, query: this.prevRouteQuery })
      }
    },
    toggleListTickets() {
      if (!this.$refs.orderDataTab?.isInvalid()) {
        this.showTickets = !this.showTickets
        if (this.showTickets) {
          this.createTickets()
        }
      }
    },
    filterDuplicateObjectsById(array) {
      return array.filter(function (item, index, inputArray) {
        const current = inputArray[index]
        return inputArray.findIndex(function (i) {
          return i.id === current.id
        }) === index
      })
    },
    mediasListUpdated(list) {
      this.mediasList = list
    },
    saveDraft() {
      if (!this.$refs.orderDataTab?.isInvalid()) {
        this.createTickets(true)
      }
    },
    async createTickets(draft = false) {
      const promises = []
      const employee = this.employeeContacts.find(x => x.id === this.selectedEmployee)
      const user = this.employeeContacts.find(x => x.id === this.userId)
      this.listTickets = []
      let contactPersonOnSite = null
      if (this.onSiteContactPersonIds.length > 0 && this.onSiteContactType === 'VIEWING_APPOINTMENT_CONTACT_TYPE_ASSIGNEE') {
        contactPersonOnSite = this.employeeContacts.find(x => this.onSiteContactPersonIds.includes(x.id))
        promises.push(ContactApi.resolveByMail(this.accountId, [contactPersonOnSite.email]))
      }
      if (employee) {
        promises.push(ContactApi.resolveByMail(employee.accountId, [employee.email]))
      }
      if (user) {
        promises.push(ContactApi.resolveByMail(user.accountId, [user.email]))
      }
      // check if the number of keys reserved equal to nbr of tickets
      if (this.createSeparateTicket && this.devices.length > 1 && this.ticketKeys.length < this.devices.length) {
        this.getReservedKeys(this.devices.length - 1)
      }
      if (this.orderDescription.includes('#anrede#') || this.orderDescription.includes('#eigentuemer#')) {
        this.orderDescription = await this.translatePlaceholders(this.orderDescription)
      }
      // this.devices.forEach(element => {
      //   if (element.referenceId.startsWith('obj')) {
      //     promises.push(ObjectApi.listObjectsByIds([element.referenceId]))
      //   } else if (element.referenceId.startsWith('building')) {
      //     promises.push(ObjectApi.getBuildingsListByIds([element.referenceId]))
      //   } else if (element.referenceId.startsWith('prop')) {
      //     promises.push(ObjectApi.getPropertyListByIds([element.referenceId]))
      //   }
      //   if (element.roomId !== '') {
      //     promises.push(RoomApi.getRoomsByReferenceIds([element.referenceId]))
      //   }
      // })

      promises.push(UserApi.listAccountsByIds([this.accountId]))

      const array = []
      const listRenderIds = []
      let listTemplatePdf = this.mediasList.length > 0 ? this.mediasList.map(x => x.id) : []
      if (this.source === 'email') {
        listTemplatePdf = listTemplatePdf.concat(this.mediaIds.split(','))
      }
      let endProcess = false
      if (this.createSeparateTicket) {
        this.selectedDevices.forEach((device, index) => {
          const promisesTemplates = []
          for (let j = 0; j < this.contractTemplateSelected.length; j++) {
            array.push({
              contract: this.contractTemplateSelected[j]
            })
            const signatories = []
            const contract = this.contractTemplate.find(x => x.id === this.contractTemplateSelected[j])
            if (contract && !contract.mediaId.includes('.pdf')) {
              if (contract && contract.firstSignature !== '') {
                signatories.push(contract.firstSignature)
              }
              if (contract && contract.secondSignature) {
                if (contract && contract.firstSignature === '') {
                  signatories.push('')
                }
                signatories.push(contract.secondSignature)
              }
              const deviceForApi = this.devicesForApi.find(x => x.id === device.id)
              promisesTemplates.push(
                ObjectApi.renderDevice(
                  this.contractTemplateSelected[j],
                  this.selectedEmployee,
                  this.recipientContactId,
                  [deviceForApi],
                  this.executeFrom,
                  this.executeUntil,
                  this.orderDescription,
                  this.onSiteContactPersonIds.length > 0 ? this.onSiteContactPersonIds[0] : this.$refs.orderDataTab.selectedUser,
                  null,
                  this.attachAutomatically,
                  signatories,
                  true,
                  this.ticketKeys[index]
                )
              )
            } else if (contract) {
              listTemplatePdf.push(contract.mediaId)
            }
          }
          Promise.all(promisesTemplates).then(response => {
            response.forEach(element => {
              const obj = {
                renderId: element.renderId,
                deviceId: device.id
              }
              listRenderIds.push(obj)
            })
          }).finally(() => {
            if (index === this.selectedDevices.length - 1) {
              endProcess = true
            }
          })
        })
      } else {
        for (let j = 0; j < this.contractTemplateSelected.length; j++) {
          array.push({
            contract: this.contractTemplateSelected[j]
          })
          const signatories = []
          const contract = this.contractTemplate.find(x => x.id === this.contractTemplateSelected[j])
          if (contract && !contract.mediaId.includes('.pdf')) {
            if (contract && contract.firstSignature !== '') {
              signatories.push(contract.firstSignature)
            }
            if (contract && contract.secondSignature) {
              if (contract && contract.firstSignature === '') {
                signatories.push('')
              }
              signatories.push(contract.secondSignature)
            }
            promises.push(
              ObjectApi.renderDevice(
                this.contractTemplateSelected[j],
                this.selectedEmployee,
                this.recipientContactId,
                this.devicesForApi,
                this.executeFrom,
                this.executeUntil,
                this.orderDescription,
                this.onSiteContactPersonIds.length > 0 ? this.onSiteContactPersonIds[0] : this.$refs.orderDataTab.selectedUser,
                null,
                this.attachAutomatically,
                signatories,
                true,
                this.ticketKeys[0]
              )
            )
          } else if (contract) {
            listTemplatePdf.push(contract.mediaId)
          }
        }
      }
      Promise.all(promises).then(response => {
        let currentContactPersonOnSite = []
        let account = null
        let contacts = []
        const correspondences = []
        const renderIds = []
        let docList = []
        response.forEach(element => {
          if (element.persons?.length > 0 || element.companies?.length > 0) {
            contacts = contacts.concat(element.companies).concat(element.persons)
          } else if (element.accounts && element.accounts.length > 0) {
            account = element.accounts[0]
          } else if (element.renderId) {
            renderIds.push(element.renderId)
          }
        })

        if (this.onSiteContactPersonIds.length > 0 && this.onSiteContactType === 'VIEWING_APPOINTMENT_CONTACT_TYPE_ASSIGNEE') {
          const user = contacts.find(x => x.ownerId === contactPersonOnSite.accountId && x.email === contactPersonOnSite.email) || null
          if (user) {
            currentContactPersonOnSite.push({
              contactId: user.id,
              companyName: '',
              name: user.name || '',
              address: this.convertAddress(user.address) !== '' ? user.address : '',
              email: user.email !== '' ? user.email : '',
              phoneNumbers: user.phoneNumbers.length > 0 ? user.phoneNumbers : '',
              notify: false,
              notifyOnlyStatusChanges: false,
              includeCorrespondenceContact: false
            })
          }
        }

        if (this.onSiteContactPersonIds.length > 0) {
          this.onSiteContactPersonIds.forEach(element => {
            const contact = this.combinedContacts.find(x => x.id === element || x.contactPersons?.some(item => item.id === element))
            if (contact && contact.id === element) {
              currentContactPersonOnSite.push({
                contactId: contact.id,
                companyName: '',
                name: contact.name || '',
                address: this.convertAddress(contact.address) !== '' ? contact.address : '',
                email: contact.email !== '' ? contact.email : '',
                phoneNumbers: contact.phoneNumbers.length > 0 ? contact.phoneNumbers : '',
                notify: false,
                notifyOnlyStatusChanges: false,
                includeCorrespondenceContact: false
              })
            } else if (contact && contact.contactPersons?.some(item => item.id === element)) {
              const contactPersonSiteInfo = contact.contactPersons.find(x => x.id === element)
              const contactPersonSiteCompany = contact
              currentContactPersonOnSite.push({
                contactId: contactPersonSiteInfo.id,
                companyName: contactPersonSiteCompany ? contactPersonSiteCompany.name : '',
                name: contactPersonSiteInfo.name || '',
                address: this.convertAddress(contactPersonSiteInfo.address) !== '' ? contactPersonSiteInfo.address : contactPersonSiteCompany ? contactPersonSiteCompany.address : '',
                email: contactPersonSiteInfo.email !== '' ? contactPersonSiteInfo.email : contactPersonSiteCompany ? contactPersonSiteCompany.email : '',
                phoneNumbers: contactPersonSiteInfo.phoneNumbers.length > 0 ? contactPersonSiteInfo.phoneNumbers : contactPersonSiteCompany && contactPersonSiteCompany.phoneNumbers.length > 0 ? contactPersonSiteCompany.phoneNumbers : '',
                notify: false,
                notifyOnlyStatusChanges: false,
                includeCorrespondenceContact: false
              })
            }
          })
        }
        if (this.recipientContactId !== '') {
          let recipientContact = this.$refs['order-recipient-tab'].recipientContact || this.$refs['order-recipient-tab'].recipientContactfromApi
          let company = null
          let recipientId = 'objectID' in recipientContact ? recipientContact.objectID : recipientContact.id
          if (recipientId !== this.recipientContactId && recipientContact.contactPersons?.length > 0) {
            recipientContact = recipientContact.contactPersons.find(x => x.id === this.recipientContactId)
            company = (this.$refs['order-recipient-tab'].recipientContactfromApi || this.$refs['order-recipient-tab'].recipientContact)
          }
          if (recipientContact) {
            correspondences.push({
              contactId: this.recipientContactId,
              companyName: company ? company.name : '',
              name: recipientContact.name || '',
              address: this.convertAddress(recipientContact.address) !== '' ? recipientContact.address : company ? company.address : '',
              email: recipientContact.email,
              phoneNumbers: recipientContact.phoneNumbers.length > 0 ? recipientContact.phoneNumbers : company && company.phoneNumbers.length > 0 ? company.phoneNumbers : ''
            })
          }
        }
        const assigneContact = contacts.find(x => x.ownerId === employee.accountId && x.email === employee.email)
        const currentAssigne = {
          accountId: this.accountId,
          accountName: account.company ? account.company.name : account.individual ? (account.individual.firstName + ' ' + account.individual.lastName) : '',
          name: employee.profile.firstName + ' ' + employee.profile.lastName,
          profilePictureUrl: employee.profile.pictureUrl,
          userId: employee.id,
          address: assigneContact.address,
          email: employee.email,
          phone: employee.profile.phoneNumber
        }
        const creatorContact = contacts.find(x => x.ownerId === user.accountId && x.email === user.email)
        const currentUser = {
          accountId: this.accountId,
          accountName: account.company ? account.company.name : account.individual ? (account.individual.firstName + ' ' + account.individual.lastName) : '',
          name: user.profile.firstName + ' ' + user.profile.lastName,
          profilePictureUrl: user.profile.pictureUrl,
          userId: user.id,
          address: creatorContact.address,
          email: user.email,
          phone: user.profile.phoneNumber
        }
        const externalTicketWatchers = []
        if (this.$refs?.orderDataTab?.sendStatusUpdates && this.selectedContactsUpdatesStatus.length > 0) {
          this.selectedContactsUpdatesStatus.forEach(contact => {
            if (contact.selectedContactPerson !== null) {
              const contactPerson = contact.contactPersons.find(x => x.id === contact.selectedContactPerson)
              externalTicketWatchers.push({
                name: contactPerson.name || '',
                contactId: contactPerson.id,
                email: contactPerson.email,
                includeCorrespondenceContact: this.$refs?.orderDataTab.includeCorrespondenceContact || false,
                notifyOnlyStatusChanges: true
              })
            } else {
              externalTicketWatchers.push({
                name: contact.name || '',
                contactId: contact.id,
                email: contact.email,
                includeCorrespondenceContact: this.$refs?.orderDataTab.includeCorrespondenceContact || false,
                notifyOnlyStatusChanges: true
              })
            }
          })
        }
        let link = []
        if (this.linkedTicketId !== '') {
          link = [{
            ticketId: this.linkedTicketId,
            type: 'TICKET_LINK_TYPE_RELATES'
          }]
        }
        if (this.createSeparateTicket) {
          const checkEndRenderDevices = setInterval(() => {
            if (endProcess) {
              clearInterval(checkEndRenderDevices)
              this.selectedDevices.forEach((element, index) => {
                docList = []
                const description = this.getTicketDescription(element.id) || ''
                const title = this.getTicketTitle(element.id)
                if (listRenderIds.length > 0) {
                  listRenderIds.forEach((elem, index) => {
                    if (elem.deviceId === element.id) {
                      const template = this.contractTemplate.find(x => x.id === array[index].contract)
                      const device = this.selectedDevices.find(x => x.id === elem.deviceId)
                      const contractName = template.title
                      const downloadName = contractName + (device ? ('_' + this.$t('message.onBoarding.devices.typeValues.' + device.type).toString()) : '')

                      const item = {
                        id: Math.random().toString(36).substr(2, 9),
                        filename: downloadName + '.pdf',
                        mimeType: 'application/pdf',
                        renderId: elem.renderId,
                        url: '',
                        loading: false,
                        blob: null
                      }
                      docList.push(item)
                    }
                  })
                }
                const references = this.selectedDevices.length > 0 ? this.getReferencesDevices() : this.selectedObjects.length > 0 ? this.getReferencesObjects() : this.references
                const ticket = {
                  source: 'UNDEFINED_SOURCE',
                  type: 'TICKET_TYPE_ORDER',
                  status: 'TICKET_STATUS_OPEN',
                  title: title,
                  description: description,
                  mediaIds: listTemplatePdf,
                  listAttachement: docList,
                  ownerId: element.ownerId,
                  priority: this.priority,
                  startDate: this.executeFrom,
                  dueDate: this.executeUntil,
                  id: '',
                  key: this.ticketKeys[index],
                  assignee: currentAssigne,
                  creator: currentUser,
                  onSiteContact: null,
                  onSiteContacts: currentContactPersonOnSite,
                  watchers: [],
                  implementationLead: {},
                  correspondences: correspondences,
                  externalTicketWatchers: externalTicketWatchers,
                  links: link,
                  references: references.filter(x => x.id === element.id || !x.id.startsWith('device')) || [],
                  involvedPersons: []
                }
                this.listTickets.push(ticket)
              })
            }
          }, 100)
        } else {
          let ownerId = this.accountId
          const description = this.getTicketDescription() || ''
          const title = this.$refs.orderDataTab.title || ''
          if (renderIds.length > 0) {
            renderIds.forEach((element, index) => {
              const template = this.contractTemplate.find(x => x.id === array[index].contract)
              const contractName = template.title
              const downloadName = contractName + '_' + this.selectedDevices.map(x => this.$t('message.onBoarding.devices.typeValues.' + x.type)).toString().replaceAll(',', '_')

              const item = {
                id: Math.random().toString(36).substr(2, 9),
                filename: downloadName + '.pdf',
                mimeType: 'application/pdf',
                renderId: element,
                url: '',
                loading: false,
                blob: null
              }
              docList.push(item)
            })
          }
          const references = this.selectedDevices.length > 0 ? this.getReferencesDevices() : this.selectedObjects.length > 0 ? this.getReferencesObjects() : this.references
          const ticket = {
            source: 'UNDEFINED_SOURCE',
            type: 'TICKET_TYPE_ORDER',
            status: 'TICKET_STATUS_OPEN',
            title: title,
            description: description,
            mediaIds: listTemplatePdf,
            listAttachement: docList,
            ownerId: ownerId,
            priority: this.priority,
            startDate: this.executeFrom,
            dueDate: this.executeUntil,
            id: '',
            key: this.ticketKeys[0],
            assignee: currentAssigne,
            creator: currentUser,
            watchers: [],
            implementationLead: {},
            correspondences: correspondences,
            onSiteContact: null,
            onSiteContacts: currentContactPersonOnSite,
            externalTicketWatchers: externalTicketWatchers,
            links: link,
            references: references,
            involvedPersons: []
          }
          this.listTickets.push(ticket)
        }
        if (draft) {
          this.$nextTick(() => {
            this.submitTickets(true)
          })
        }
      })
        .catch(e => {
          Sentry.captureException(e)
          console.error(e)
          Vue.toasted.show('Eigentümer konnten nicht geladen werden', { type: 'error' })
        })
    },
    getReferencesDevices() {
      const references = []
      this.selectedDevices.forEach(device => {
        if (device.referenceId.startsWith('obj')) {
          const relatedIds = [device.referenceId]
          const object = this.objects.find(x => x.id === device.referenceId)
          const building = this.buildings.find(x => x.id === object.buildingId)
          if (building) {
            relatedIds.push(building.id)
            const property = this.properties.find(x => x.buildingIds.includes(building.id))
            if (property) {
              relatedIds.push(property.id)
            }
          }
          references.push({
            id: device.id,
            relatedIds: relatedIds
          })
        } else if (device.referenceId.startsWith('building')) {
          const relatedIds = [device.referenceId]
          const property = this.properties.find(x => x.buildingIds.includes(device.referenceId))
          if (property) {
            relatedIds.push(property.id)
          }
          references.push({
            id: device.id,
            relatedIds: relatedIds
          })
        } else if (device.referenceId.startsWith('prop')) {
          references.push({
            id: device.id,
            relatedIds: [device.referenceId]
          })
        }
      })
      return references
    },
    getReferencesObjects() {
      const references = []
      this.selectedObjects.forEach(obj => {
        if (obj.id.startsWith('obj')) {
          const relatedIds = []
          const object = this.objects.find(x => x.id === obj.id)
          const building = this.buildings.find(x => x.id === object.buildingId)
          if (building) {
            relatedIds.push(building.id)
            const property = this.properties.find(x => x.buildingIds.includes(building.id))
            if (property) {
              relatedIds.push(property.id)
            }
          }
          references.push({
            id: obj.id,
            relatedIds: relatedIds
          })
        } else if (obj.id.startsWith('building')) {
          const relatedIds = []
          const property = this.properties.find(x => x.buildingIds.includes(obj.id))
          if (property) {
            relatedIds.push(property.id)
          }
          references.push({
            id: obj.id,
            relatedIds: relatedIds
          })
        } else if (obj.id.startsWith('prop')) {
          references.push({
            id: obj.id,
            relatedIds: []
          })
        }
      })
      return references
    },
    translatePlaceholders(message = '') {
      return new Promise((resolve) => {
        const list = []
        let description = ''
        if (this.references.length > 0) {
          this.references.forEach(element => {
            if (element.id.startsWith('obj') || element.relatedIds.some(item => item.startsWith('obj'))) {
              let objectId = element.id.startsWith('obj') ? element.id : element.relatedIds.find(item => item.startsWith('obj'))
              list.push({
                contactId: this.recipientContact ? ('id' in this.recipientContact ? this.recipientContact.id : this.recipientContact.objectID) : '',
                objectId: objectId
              })
            }
            if (element.id.startsWith('building')) {
              list.push({
                contactId: this.recipientContact ? ('id' in this.recipientContact ? this.recipientContact.id : this.recipientContact.objectID) : '',
                buildingId: element.id
              })
            }
            if (element.id.startsWith('prop')) {
              list.push({
                contactId: this.recipientContact ? ('id' in this.recipientContact ? this.recipientContact.id : this.recipientContact.objectID) : '',
                propertyId: element.id
              })
            }

            if (element.id.startsWith('room') && this.rooms.length > 0) {
              const room = this.rooms.find(x => x.id === element.id)
              if (room?.referenceId.startsWith('building')) {
                list.push({
                  contactId: this.recipientContact ? ('id' in this.recipientContact ? this.recipientContact.id : this.recipientContact.objectID) : '',
                  buildingId: room.referenceId
                })
              }
              if (room?.referenceId.startsWith('obj')) {
                list.push({
                  contactId: this.recipientContact ? ('id' in this.recipientContact ? this.recipientContact.id : this.recipientContact.objectID) : '',
                  objectId: room.referenceId
                })
              }
            }
          })
        } else if (this.recipientContact) {
          list.push({
            contactId: this.recipientContact ? ('id' in this.recipientContact ? this.recipientContact.id : this.recipientContact.objectID) : ''
          })
        }
        if (list.length > 0) {
          MessageApi.bulkRenderText(list, message, '').then(response => {
            if (response?.entries.length > 0) {
              response?.entries.forEach(element => {
                if (element?.text) {
                  description += element.text + '\n'
                }
              })
            }
            resolve(description)
          }).catch(e => {
            console.log(e)
            resolve(message)
          })
        } else {
          resolve(message)
        }
      })
    },
    getTicketDescription(deviceId = '') {
      let description = ''
      if (this.orderDescription !== '') {
        description += '\n' + this.$t('message.createOrder.orderDetails') + ':' + '\n' + this.orderDescription
      }
      if (this.selectedDevices.length > 0 || this.source === 'device' || (this.source === 'ticket' && this.deviceIds)) {
        description += this.getDeviceDescription(deviceId)
      } else {
        if (this.source === 'property' || (this.source === 'ticket' && this.propertyIds)) {
          description += this.getPropertyDescription()
        }
        if (this.source === 'building' || (this.source === 'ticket' && this.buildingIds)) {
          description += this.getBuildingDescription()
        }
        if (this.source === 'object' || this.source === 'condominium' || this.source === 'tenancy' || this.source === 'condoOwner' || (this.source === 'ticket' && (this.tenancyIds || this.condoOwnerIds || this.objectIds))) {
          description += this.getObjectDescription()
        }
        if (this.source === 'room' || (this.source === 'ticket' && this.roomId)) {
          description += this.getRoomDescription(this.roomId)?.description
        }
      }
      return description
    },
    getTicketTitle(deviceId) {
      let title = ''
      const data = this.properties.concat(this.propertiesAlgolia).concat(this.buildings).concat(this.buildingsAlgolia).concat(this.objects).concat(this.objectsAlgolia)
      if (this.createSeparateTicket) {
        const device = this.selectedDevices.find(x => x.id === deviceId)
        if (device.type !== '' && device.type !== 'DEVICE_TYPE_UNDEFINED') {
          title = (title === '' ? title : title + ' - ') + this.$t('message.onBoarding.devices.typeValues.' + device.type)
        }
        if (device.brand !== '') {
          title = (title !== '' ? title + ', ' : '') + device.brand
        }
        if (title === '') {
          const ref = data.find(x => x.id === device.referenceId)
          let name = ref?.name || ''
          if (ref && name === '') {
            if (ref.id.startsWith('obj')) {
              name = data.find(x => x.id === ref.buildingId)?.name || ''
            }
          }
          title = name
        }
      } else {
        if (this.selectedDevices.length > 1) {
          const entity = this.selectedDevices.filter(x => x.referenceId.startsWith('prop')).length > 0 ? this.selectedDevices.filter(x => x.referenceId.startsWith('prop')) : this.selectedDevices.filter(x => x.referenceId.startsWith('building')).length > 0 ? this.selectedDevices.filter(x => x.referenceId.startsWith('building')) : this.selectedDevices.filter(x => x.referenceId.startsWith('obj'))
          const titleRef = entity.length > 0 ? data.find(x => x.id === entity[0].referenceId)?.name : ''
          title = this.$t('message.ticketTitle', { name: titleRef })
        } else if (this.selectedDevices.length === 1) {
          if (this.selectedDevices[0].type !== '' && this.selectedDevices[0].type !== 'DEVICE_TYPE_UNDEFINED') {
            title = this.$t('message.onBoarding.devices.typeValues.' + this.selectedDevices[0].type)
          }
          if (title === '') {
            const ref = data.find(x => x.id === this.selectedDevices[0].referenceId)
            let name = ref?.name || ''
            if (ref && name === '') {
              if (ref.id.startsWith('obj')) {
                name = data.find(x => x.id === ref.buildingId)?.name || ''
              }
            }
            title = name
          }
        }
      }
      return title
    },
    getDeviceDescription(deviceId = '') {
      let description = ''
      const data = this.properties.concat(this.propertiesAlgolia).concat(this.buildings).concat(this.buildingsAlgolia).concat(this.objects).concat(this.objectsAlgolia)
      const list = deviceId !== '' ? this.selectedDevices.filter(x => x.id === deviceId) : this.selectedDevices
      list.forEach(device => {
        description = description + '\n'
        if (data.length > 0) {
          if (device.referenceId.startsWith('prop')) {
            const property = data.find(x => x.id === device.referenceId) || null
            if (property) {
              data.forEach(item => {
                if (property.id === item.id) {
                  description = description + '\n' + this.$t('message.createOrder.property') + ' ' + property.name
                  description = description + '\n' + this.$t('message.createOrder.city') + ' ' + property.address.zip + ' ' + property.address.city + '\n'
                }
              })
            }
          }
          if (device.referenceId.startsWith('building')) {
            data.forEach(item => {
              if (device.referenceId === item.id) {
                description = description + '\n' + this.$t('message.createOrder.address') + ' ' + this.convertAddress(item.address)
                description = description + '\n' + this.$t('message.createOrder.location') + ' ' + item.name
                if (device.roomId !== '') {
                  const room = this.rooms.find(x => x.id === device.roomId)
                  description = description + (room?.name ? ', ' + room.name : '')
                }
              }
            })
          }
          if (device.referenceId.startsWith('obj')) {
            data.forEach(item => {
              if (device.referenceId === item.id) {
                description = description + '\n' + this.$t('message.createOrder.address') + ' ' + this.convertAddress(item.address)
                description = description + '\n' + this.$t('message.createOrder.location') + ' ' + item.name + ', ' + this.$t('message.onBoarding.buildings.objects.category.' + item.category.toLowerCase()) + (item.characteristics?.floor !== -10000 ? ' - ' + this.formatFloor(item.characteristics?.floor) : '')
                if (device.roomId !== '') {
                  const room = this.rooms.find(x => x.id === device.roomId)
                  description = description + ', ' + room.name
                }
              }
            })
          }
        }
        if (device.type !== '' && device.type !== 'DEVICE_TYPE_UNDEFINED') {
          description = description + '\n' + this.$t('message.createOrder.componentDevice') + ' ' + this.$t('message.onBoarding.devices.typeValues.' + device.type)
        }
        if (device.manufacturer !== '') {
          description = description + '\n' + this.$t('message.createOrder.manufacturer') + ' ' + device.manufacturer
        }
        if (device.brand !== '') {
          description = description + '\n' + this.$t('message.createOrder.brand') + ' ' + device.brand
        }
        if (device.model !== '') {
          description = description + '\n' + this.$t('message.createOrder.model') + ' ' + device.model
        }
        if (device.serialNumber !== '') {
          description = description + '\n' + this.$t('message.createOrder.serialNumber') + ' ' + device.serialNumber
        }
        if (device.orderDescription) {
          description = description + '\n\n' + this.$t('message.createOrder.orderDescription') + ':' + '\n' + device.orderDescription + '\n'
        }
      })
      return description
    },
    getPropertyDescription() {
      let description = ''
      this.properties.forEach(property => {
        if (this.propertyIds.includes(property.id)) {
          description = description + '\n' + this.$t('message.createOrder.property') + ' ' + property.name
          description = description + '\n' + this.$t('message.createOrder.city') + ' ' + property.address.zip + ' ' + property.address.city + '\n'
        }
      })
      return description
    },
    getBuildingDescription() {
      let description = ''
      this.buildings.forEach(building => {
          if (this.buildingIds.includes(building.id)) {
            description = description + '\n' + this.$t('message.createOrder.address') + ' ' + this.convertAddress(building.address) + '\n'
          }
      })
      return description
    },
    getObjectDescription() {
      let description = ''
      this.objects.forEach(object => {
          if (this.objectIds.includes(object.id)) {
            description = description + '\n' + this.$t('message.createOrder.address') + ' ' + this.convertAddress(object.address)
            description = description + '\n' + this.$t('message.createOrder.location') + ' ' + object.name + ', ' + this.$t('message.onBoarding.buildings.objects.category.' + object.category.toLowerCase()) + (object.characteristics?.floor !== -10000 ? ' - ' + this.formatFloor(object.characteristics?.floor) : '') + '\n'
          }
      })
      return description
    },
    getRoomDescription(roomId) {
      let description = ''
      let object = null
      let building = null
      const room = this.rooms.find(x => x.id === roomId)
      if (room.referenceId.startsWith('building')) {
        building = this.buildings.find(x => x.id === room.referenceId) || null
        if (building) {
          description = description + '\n' + this.$t('message.createOrder.address') + ' ' + this.convertAddress(building.address)
          description = description + '\n' + this.$t('message.createOrder.location') + ' ' + building.name + (room?.name ? ', ' + room.name : '')
        }
      } else if (room.referenceId.startsWith('obj')) {
        object = this.objects.find(x => x.id === room.referenceId) || null
        if (object) {
          description = description + '\n' + this.$t('message.createOrder.address') + ' ' + this.convertAddress(object.address)
          description = description + '\n' + this.$t('message.createOrder.location') + ' ' + object.name + ', ' + this.$t('message.onBoarding.buildings.objects.category.' + object.category.toLowerCase()) + (object.characteristics?.floor !== -10000 ? ' - ' + this.formatFloor(object.characteristics?.floor) : '') + (room?.name ? ', ' + room.name : '')
        }
      }
      return { description: description }
    },
    convertAddress(address) {
      if (!address) {
        return '-'
      }

      const addr = (address.careOf ? address.careOf + '\n' : '') +
        (address.street ? ' ' + address.street + (address.streetNumber ? ' ' + address.streetNumber : '') : '') +
        (address.mailbox ? ' ' + address.mailbox + '\n ' : '') +
        (address.zip ? ' ' + address.country + '-' : '') +
        (address.zip ? address.zip + ' ' : '') +
        (address.city ? address.city : '')
      if (addr) {
        return addr
      } else {
        return ''
      }
    },
    submitTickets(draft = false) {
      if (this.listTickets.length > 0) {
        this.confirmCancellationProcess = true
        this.loading = true
        this.disableBtns = true
        const promisesMedia = []
        const promisesNewTickets = []
        let listBlobs = []
        let renderId = []
        if (this.listTickets[0].listAttachement.length > 0) {
          renderId = this.listTickets[0].listAttachement.filter(x => x.blob === null).map(x => x.renderId)
          listBlobs = this.listTickets[0].listAttachement.filter(x => x.blob !== null)
          if (listBlobs.length > 0) {
            listBlobs.forEach(item => {
              const formData = new FormData()
              const fileName = this.editFileName(item.filename)
              formData.append('file', item.blob, fileName)
              formData.append('filename', item.filename)
              formData.append('public', 'true')
              promisesMedia.push(MediaApi.uploadMedia(formData))
            })
          }
          if (renderId.length > 0) {
            const recheckDocs = setInterval(() => {
              ObjectApi.listRenderStatus(renderId)
                .then(response => {
                  renderId = response.status.filter(x => !x.finished && !x.failed).map(x => x.renderId)
                  const stillInProgress = response.status.filter(function (v) {
                    return v.finished === false && v.failed === false
                  })
                  if (stillInProgress.length === 0) {
                    const isFinished = response.status.every(function (v) {
                      return v.finished !== false || v.failed !== false
                    })
                    if (isFinished) {
                      const onlyFinished = response.status.filter(x => x.finished)
                      const onlyFailed = response.status.filter(x => x.failed)
                      if (onlyFailed.length > 0) {
                        Vue.toasted.show(this.$t('message.savingErrors.renderingContractError') + onlyFailed.map(x => x.renderId).toString() + ' ' + onlyFailed.map(x => x.errorMessage).toString(), { type: 'error' })
                      }
                      if (onlyFinished.length > 0) {
                        onlyFinished.forEach((e, index) => {
                          ObjectApi.getRenderFile(e.renderId)
                            .then(element => {
                              // decode base64 string
                              const binary = window.atob(element.contract)
                              const len = binary.length
                              const buffer = new ArrayBuffer(len)
                              const view = new Uint8Array(buffer)
                              for (let i = 0; i < len; i++) {
                                view[i] = binary.charCodeAt(i)
                              }
                              let blob = new Blob([view], { type: 'application/pdf' })

                              const template = this.listTickets[0].listAttachement.find(x => x.renderId === e.renderId)

                              const formData = new FormData()
                              const fileName = this.editFileName(template.filename)
                              formData.append('file', blob, fileName)
                              formData.append('filename', template.filename)
                              formData.append('public', 'true')
                              promisesMedia.push(MediaApi.uploadMedia(formData))
                              if (renderId.length === 0 && index === onlyFinished.length - 1) {
                                clearInterval(recheckDocs)
                                const mediaIds = []
                                Promise.all(promisesMedia)
                                  .then(response => {
                                    for (const media of response) {
                                      mediaIds.push(media.id)
                                    }
                                    this.listTickets.forEach(element => {
                                      if (draft) {
                                        element.status = 'TICKET_STATUS_DRAFT'
                                      }
                                      element.mediaIds = mediaIds.concat(element.mediaIds)
                                      promisesNewTickets.push(TicketApi.newTicket(element))
                                    })
                                    Promise.all(promisesNewTickets).then((response) => {
                                      if (this.source === 'email' && this.emailsData && response.length > 0) {
                                        const refs = []
                                        response.forEach(element => {
                                          if (element.ticket?.id) {
                                            refs.push(element.ticket.id)
                                          }
                                        })
                                        const email = JSON.parse(this.emailsData)
                                        const id = email.id
                                        MessageApi.setReferenceIds([id], refs.concat(email.referenceIds))
                                          .catch(error => {
                                            console.log(error)
                                          })
                                      }
                                      const ids = this.listTickets[0].listAttachement.map(x => x.renderId)
                                      ObjectApi.deleteRenderFiles(ids)
                                        .catch(exeption => {
                                          console.error(exeption)
                                        })
                                      this.redirectOnFinish('ticket', draft)
                                    }).catch(error => {
                                      console.log(error)
                                      this.handleErrorOnFinish(error)
                                    })
                                  })
                              }
                            })
                        })
                      } else if (onlyFailed.length > 0 && onlyFinished.length === 0) {
                        clearInterval(recheckDocs)
                        const mediaIds = []
                        Promise.all(promisesMedia)
                          .then(response => {
                            for (const media of response) {
                              mediaIds.push(media.id)
                            }
                            this.listTickets.forEach(element => {
                              if (draft) {
                                element.status = 'TICKET_STATUS_DRAFT'
                              }
                              element.mediaIds = mediaIds.concat(element.mediaIds)
                              promisesNewTickets.push(TicketApi.newTicket(element))
                            })
                            Promise.all(promisesNewTickets).then((response) => {
                              if (this.source === 'email' && this.emailsData && response.length > 0) {
                                const refs = []
                                response.forEach(element => {
                                  if (element.ticket?.id) {
                                    refs.push(element.ticket.id)
                                  }
                                })
                                const email = JSON.parse(this.emailsData)
                                const id = email.id
                                MessageApi.setReferenceIds([id], refs.concat(email.referenceIds))
                                  .catch(error => {
                                    console.log(error)
                                  })
                              }
                              const ids = this.listTickets[0].listAttachement.map(x => x.renderId)
                              ObjectApi.deleteRenderFiles(ids)
                                .catch(exeption => {
                                  console.error(exeption)
                                })
                              this.redirectOnFinish('ticket', draft)
                            }).catch(error => {
                              console.log(error)
                              this.handleErrorOnFinish(error)
                            })
                          })
                      }
                    }
                  }
                })
            }, 3000)
          } else if (renderId.length === 0 && listBlobs.length > 0) {
            const mediaIds = []
            Promise.all(promisesMedia)
              .then(response => {
                for (const media of response) {
                  mediaIds.push(media.id)
                }
                this.listTickets.forEach(element => {
                  if (draft) {
                    element.status = 'TICKET_STATUS_DRAFT'
                  }
                  element.mediaIds = mediaIds.concat(element.mediaIds)
                  promisesNewTickets.push(TicketApi.newTicket(element))
                })
                Promise.all(promisesNewTickets).then((response) => {
                  if (this.source === 'email' && this.emailsData && response.length > 0) {
                    const refs = []
                    response.forEach(element => {
                      if (element.ticket?.id) {
                        refs.push(element.ticket.id)
                      }
                    })
                    const email = JSON.parse(this.emailsData)
                    const id = email.id
                    MessageApi.setReferenceIds([id], refs.concat(email.referenceIds))
                      .catch(error => {
                        console.log(error)
                      })
                  }
                  const ids = this.listTickets[0].listAttachement.map(x => x.renderId)
                  ObjectApi.deleteRenderFiles(ids)
                    .catch(exeption => {
                      console.error(exeption)
                    })
                  this.redirectOnFinish('ticket', draft)
                }).catch(error => {
                  console.log(error)
                  this.handleErrorOnFinish(error)
                })
              })
          }
        } else {
          const promises = []
          this.listTickets.forEach(element => {
            if (draft) {
              element.status = 'TICKET_STATUS_DRAFT'
            }
            promises.push(TicketApi.newTicket(element))
          })
          Promise.all(promises).then((response) => {
            if (this.source === 'email' && this.emailsData && response.length > 0) {
              const refs = []
              response.forEach(element => {
                if (element.ticket?.id) {
                  refs.push(element.ticket.id)
                }
              })
              const email = JSON.parse(this.emailsData)
              const id = email.id
              MessageApi.setReferenceIds([id], refs.concat(email.referenceIds))
                .then(() => {
                  this.redirectOnFinish('ticket', draft)
                })
            } else {
              this.redirectOnFinish('ticket', draft)
            }
          }).catch(error => {
            console.log(error)
            this.handleErrorOnFinish(error)
          })
        }
      }
    },
    updateAttachAuto(value) {
      this.attachAutomatically = value
    },
    updateStatusContacts(value) {
      this.selectedContactsUpdatesStatus = value
    },
    async loadReferences() {
      this.references = []

      if (this.source === 'device' || (this.source === 'ticket' && this.deviceIds !== '')) {
        const arrayDeviceIds = this.deviceIds.split(',')
        for (const element of this.devices) {
          if (arrayDeviceIds.includes(element.id)) {
            const arrayRefs = []
            if (element.referenceId.startsWith('obj_')) {
              arrayRefs.push(element.referenceId)
              const response = await ObjectApi.getBuildingByObjectId(element.referenceId)
              if (response.building) {
                this.buildings.push(response.building)
                arrayRefs.push(response.building.id)
                const propResponse = await ObjectApi.getPropertiesByBuildingIds([response.building.id])
                if (propResponse.properties.length > 0) {
                  this.properties.push(propResponse.properties[0])
                  arrayRefs.push(propResponse.properties[0].id)
                }
              }
              const obj = {
                id: element.id,
                relatedIds: arrayRefs
              }
              this.references.push(obj)
            } else if (element.referenceId.startsWith('building_')) {
              arrayRefs.push(element.referenceId)
              const response = await ObjectApi.getPropertiesByBuildingIds([element.referenceId])
              if (response.properties.length > 0) {
                this.properties.push(response.properties[0])
                arrayRefs.push(response.properties[0].id)
              }
              const obj = {
                id: element.id,
                relatedIds: arrayRefs
              }
              this.references.push(obj)
            } else if (element.referenceId.startsWith('prop_')) {
              arrayRefs.push(element.referenceId)
              const obj = {
                id: element.id,
                relatedIds: arrayRefs
              }
              this.references.push(obj)
            }
          }
        }
      }

      if (this.source === 'object' || this.source === 'condominium' || (this.source === 'ticket' && this.objectIds !== '')) {
        const arrayObjectIds = this.objectIds.split(',')
        for (const element of this.objects) {
          if (arrayObjectIds.includes(element.id)) {
            const arrayRefs = []
            arrayRefs.push(element.id)
            const response = await ObjectApi.getBuildingByObjectId(element.id)
            if (response.building) {
              this.buildings.push(response.building)
              arrayRefs.push(response.building.id)
              const propResponse = await ObjectApi.getPropertiesByBuildingIds([response.building.id])
              if (propResponse.properties.length > 0) {
                this.properties.push(propResponse.properties[0])
                arrayRefs.push(propResponse.properties[0].id)
              }
            }
            const obj = {
              id: element.id,
              relatedIds: arrayRefs
            }
            this.references.push(obj)
          }
        }
      }

      if (this.source === 'building' || (this.source === 'ticket' && this.buildingIds !== '')) {
        const arrayBuildingIds = this.buildingIds.split(',')
        for (const element of this.buildings) {
          if (arrayBuildingIds.includes(element.id)) {
            const arrayRefs = []
            arrayRefs.push(element.id)
            const response = await ObjectApi.getPropertiesByBuildingIds([element.id])
            if (response.properties.length > 0) {
              this.properties.push(response.properties[0])
              arrayRefs.push(response.properties[0].id)
            }
            const obj = {
              id: element.id,
              relatedIds: arrayRefs
            }
            this.references.push(obj)
          }
        }
      }

      if (this.source === 'property' || (this.source === 'ticket' && this.propertyIds !== '')) {
        const arrayPropertyIds = this.propertyIds.split(',')
        for (const element of this.properties) {
          if (arrayPropertyIds.includes(element.id)) {
            const arrayRefs = []
            arrayRefs.push(element.id)
            const obj = {
              id: element.id,
              relatedIds: arrayRefs
            }
            this.references.push(obj)
          }
        }
      }

      if (this.source === 'tenancy' || this.source === 'condoOwner' || (this.source === 'ticket' && (this.tenancyIds !== '' || this.condoOwnerIds !== ''))) {
        const list = this.source === 'ticket' ? this.tenancies.concat(this.condoOwners) : this.source === 'tenancy' ? this.tenancies : this.condoOwners
        const arrayIds = this.source === 'ticket' ? this.tenancyIds.split(',').concat(this.condoOwnerIds.split(',')) : this.source === 'tenancy' ? this.tenancyIds.split(',') : this.condoOwnerIds.split(',')
        for (const element of list) {
          if (arrayIds.includes(element.id)) {
            const arrayRefs = []
            arrayRefs.push(element.objectId)
            const response = await ObjectApi.getBuildingByObjectId(element.objectId)
            if (response.building) {
              this.buildings.push(response.building)
              arrayRefs.push(response.building.id)
              const propResponse = await ObjectApi.getPropertiesByBuildingIds([response.building.id])
              if (propResponse.properties.length > 0) {
                this.properties.push(propResponse.properties[0])
                arrayRefs.push(propResponse.properties[0].id)
              }
            }
            const obj = {
              id: element.id,
              relatedIds: arrayRefs
            }
            this.references.push(obj)
          }
        }
      }

      if (this.source === 'room' || (this.source === 'ticket' && this.roomId !== '')) {
        const room = this.rooms.find(x => x.id === this.roomId)
        if (room && room.referenceId.startsWith('obj_')) {
          const arrayRefs = []
          arrayRefs.push(room.referenceId)
          const response = await ObjectApi.getBuildingByObjectId(room.referenceId)
          if (response.building) {
            this.buildings.push(response.building)
            arrayRefs.push(response.building.id)
            const propResponse = await ObjectApi.getPropertiesByBuildingIds([response.building.id])
            if (propResponse.properties.length > 0) {
              this.properties.push(propResponse.properties[0])
              arrayRefs.push(propResponse.properties[0].id)
            }
          }
          const obj = {
            id: room.id,
            relatedIds: arrayRefs
          }
          this.references.push(obj)
        }
        if (room && room.referenceId.startsWith('building_')) {
          const arrayRefs = []
          arrayRefs.push(room.referenceId)
          const response = await ObjectApi.getPropertiesByBuildingIds([room.referenceId])
          if (response.properties.length > 0) {
            this.properties.push(response.properties[0])
            arrayRefs.push(response.properties[0].id)
          }
          const obj = {
            id: room.id,
            relatedIds: arrayRefs
          }
          this.references.push(obj)
        }
      }

      if (this.source === 'ticket' && this.contactIds !== '') {
        const arrayContactIds = this.contactIds.split(',')
        for (const element of this.contacts) {
          if (arrayContactIds.includes(element.id)) {
            const obj = {
              id: element.id,
              relatedIds: []
            }
            this.references.push(obj)
          }
        }
      }
      // remove duplicated items
      this.references = this.references.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
      if (this.source === 'device') {
        this.ticketTitle = this.getTicketTitle(this.deviceIds)
      }
      this.finishLoadingReferences = true
    },
    async loadDevices() {
      this.loading = true
      const deviceIds = typeof this.deviceIds === 'string' ? this.deviceIds.split(',') : this.deviceIds
      await ObjectApi.listDevicesByIds(deviceIds)
        .then(async response => {
          this.devices = response.devices
          // Check if we can only load objects and tenants by referenceId of devices
          const objectsIds = this.devices.filter(dev => dev.referenceId.startsWith('obj_')).map(dev => dev.referenceId) || []
          const buildingIds = this.devices.filter(dev => dev.referenceId.startsWith('building_')).map(dev => dev.referenceId) || []
          const propertyIds = this.devices.filter(dev => dev.referenceId.startsWith('prop_')).map(dev => dev.referenceId) || []
          const roomIds = this.devices.filter(dev => dev.roomId).map(dev => dev.roomId) || []
          if (buildingIds.length > 0) {
            // We need to load the building to get the objects and janitors because no device refers to an object
            await this.loadBuildings(buildingIds, objectsIds)
          }
          if (objectsIds.length > 0) {
            await this.loadObjects(objectsIds)
          }
          if (propertyIds) {
            await this.loadProperties(propertyIds)
          }
          if (roomIds) {
            await this.loadRooms(roomIds)
          }
          this.loading = false
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.device'), { type: 'error' })
          this.loading = false
        })
    },
    async loadBuildings(buildingIds, additionalObjectsIds) {
      try {
        const buildings = typeof buildingIds === 'string' ? buildingIds.split(',') : buildingIds
        const response = await ObjectApi.getBuildingsListByIds(buildings)
        const list = this.buildings.concat(response.buildings)
        this.buildings = list.length > 0 ? list.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i) : []
        this.ownersIds = this.removeDuplicatedIds(this.ownersIds.concat(response.buildings.map(x => x.ownerId)))
        const objectIds = response.buildings.flatMap(x => x.objectIds)
        await this.loadObjects(objectIds.concat(additionalObjectsIds))
      } catch (e) {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.building'), { type: 'error' })
      }
    },
    async loadProperties(propertyIds = []) {
      try {
        // const properties = propertyIds.length === 0 ? typeof this.propertyIds === 'string' ? this.propertyIds.split(',') : this.propertyIds : propertyIds
        const properties = typeof propertyIds === 'string' ? propertyIds.split(',') : propertyIds
        const response = await ObjectApi.getPropertyListByIds(properties)
        const list = this.properties.concat(response.properties)
        this.properties = list.length > 0 ? list.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i) : []
        this.ownersIds = this.removeDuplicatedIds(this.ownersIds.concat(response.properties.map(x => x.ownerId)))
        const buildingIds = response.properties.flatMap(x => x.buildingIds)
        await this.loadBuildings(buildingIds, [])
      } catch (e) {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.building'), { type: 'error' })
      }
    },
    loadOwners() {
      const promises = []
      if (this.ownersIds.length > 0) {
        for (const id of this.ownersIds) {
          promises.push(UserApi.getAccount(id))
        }
        Promise.all(promises).then((result) => {
          const names = []
          result.forEach(response => {
            if (response.account?.company) {
              names.push(response.account?.company?.name)
            } else if (response.account?.individual) {
              const name = response.account?.individual?.firstName + ' ' + response.account?.individual?.lastName
              names.push(name)
            }
          })
          this.ownersList = names
        })
      }
    },
    async loadObjects(objectIds) {
      try {
        this.finishLoadingReferences = false
        const response = await ObjectApi.listObjectsByIds(objectIds.filter(function (e) {
          return e
        }))
        // concat objects list and remove duplicated items
        const list = this.objects.concat(response.objects)
        this.objects = list.length > 0 ? list.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i) : []

        this.ownersIds = this.removeDuplicatedIds(this.ownersIds.concat(response.objects.map(x => x.ownerId)))
        const rentalObjectIds = this.objects.filter(x => x.condominiumOwnership === false).map(x => x.id)
        const condominiumIds = this.objects.filter(x => x.condominiumOwnership === true).map(x => x.id)
        if (rentalObjectIds.length > 0) {
          await this.loadTenantContacts(rentalObjectIds)
        } else if (condominiumIds.length > 0) {
          await this.loadCondominiumContacts(condominiumIds)
        }
        // await this.loadReferences()
      } catch (e) {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
      }
    },
    removeDuplicatedIds(items) {
      const uniqueIds = []
      return items.filter(element => {
        const isDuplicate = uniqueIds.filter(y => y === element)
        if (isDuplicate.length === 0) {
          uniqueIds.push(element)
          return true
        }
        return false
      })
    },
    async loadJanitors() {
      try {
        let janitorIds = []
        this.buildings.forEach((building) => {
          janitorIds = janitorIds.concat(building.contacts.filter(x => x.type === 'BUILDING_CONTACT_TYPE_JANITOR')?.map(y => y.id))
        })
        const response = await ContactApi.contactResolve(janitorIds)
        const list = this.janitorContacts.concat(response.persons).concat(response.companies)
        this.janitorContacts = list.length > 0 ? list.filter(x => janitorIds.includes(x.id)) : []
      } catch (e) {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.janitors'), { type: 'error' })
      }
    },
    async loadCraftsmans() {
      try {
        const craftsmanIds = this.buildings.flatMap((building) => {
          return building.contacts.filter(x => x.type === 'BUILDING_CONTACT_TYPE_CRAFTSMAN')?.map(y => y.id)
        })
        const response = await ContactApi.contactResolve(craftsmanIds)
        const list = this.craftsmanContacts.concat(response.persons).concat(response.companies)
        this.craftsmanContacts = list.length > 0 ? list.filter(x => craftsmanIds.includes(x.id)) : []
      } catch (e) {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.janitors'), { type: 'error' })
      }
    },
    async loadServiceProviders() {
      try {
        const serviceProviderIds = this.buildings.flatMap((building) => {
          return building.contacts.filter(x => x.type === 'BUILDING_CONTACT_TYPE_SERVICE_PROVIDER')?.map(y => y.id)
        })
        const response = await ContactApi.contactResolve(serviceProviderIds)
        const list = this.serviceProviderContacts.concat(response.persons).concat(response.companies)
        this.serviceProviderContacts = list.length > 0 ? list.filter(x => serviceProviderIds.includes(x.id)) : []
      } catch (e) {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.janitors'), { type: 'error' })
      }
    },
    async loadDelegates() {
      try {
        const delegateIds = this.buildings.flatMap((building) => {
          return building.contacts.filter(x => x.type === 'BUILDING_CONTACT_TYPE_DELEGATE')?.map(y => y.id)
        })
        const response = await ContactApi.contactResolve(delegateIds)
        const list = this.delegateContacts.concat(response.persons).concat(response.companies)
        this.delegateContacts = list.length > 0 ? list.filter(x => delegateIds.includes(x.id)) : []
      } catch (e) {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.delegates'), { type: 'error' })
      }
    },
    async loadTenantContacts(objectIds) {
      let tenantContactIds = []
      try {
        const tenancyResponse = await ObjectApi.getTenancies(objectIds)
        const today = new Date()
        const list = this.tenancies.concat(tenancyResponse.tenancies.filter(x => !x.vacancy && (!x.endingAt || new Date(this.$options.filters.objectToDateInput(x.startingAt)).getTime() >= today.getTime() || new Date(this.$options.filters.objectToDateInput(x.endingAt)).getTime() >= today.getTime())))
        this.tenancies = [...new Set(list.map(a => a.id))].map(id => {
          return list.find(a => a.id === id)
        })
        tenantContactIds = this.tenancies.map(t => t?.tenant?.contactIds || []).flat()
      } catch (e) {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.tenancy'), { type: 'error' })
      }

      if (tenantContactIds && tenantContactIds.length > 0) {
        try {
          const contactResponse = await ContactApi.contactResolve(tenantContactIds)
          const list = contactResponse.persons.concat(contactResponse.companies)
          this.tenantContacts = list.filter(c => tenantContactIds.includes(c.id))
          // this.combinedContacts = this.combinedContacts.concat(this.tenantContacts)
        } catch (e) {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
        }
      }
    },
    async loadCondominiumContacts(objectIds) {
      let condoOwnerContactIds = []
      try {
        const condominiumResponse = await ObjectApi.getCondominiumOwnership(objectIds)
        const today = new Date()
        const list = this.condoOwners.concat(condominiumResponse.condominiums.filter(x => !x.endingAt || new Date(this.$options.filters.objectToDateInput(x.startingAt)).getTime() >= today.getTime() || new Date(this.$options.filters.objectToDateInput(x.endingAt)).getTime() >= today.getTime()))
        this.condoOwners = [...new Set(list.map(a => a.id))].map(id => {
          return list.find(a => a.id === id)
        })
        condoOwnerContactIds = this.condoOwners.map(t => t?.owner?.contactIds || []).flat()
      } catch (e) {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.tenancy'), { type: 'error' })
      }

      if (condoOwnerContactIds && condoOwnerContactIds.length > 0) {
        try {
          const contactResponse = await ContactApi.contactResolve(condoOwnerContactIds)
          const list = contactResponse.persons.concat(contactResponse.companies)
          this.condoOwnerContacts = list.filter(c => condoOwnerContactIds.includes(c.id))
          // this.combinedContacts = this.combinedContacts.concat(this.condoOwnerContacts)
        } catch (e) {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
        }
      }
    },
    async loadContacts(ids = []) {
      try {
        const contactIds = this.contactIds.split(',').concat(ids)
        const contactResponse = await ContactApi.contactResolve(contactIds)
        this.contacts = contactResponse.persons.concat(contactResponse.companies)
        // this.loadReferences()
      } catch (e) {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.building'), { type: 'error' })
      }
    },
    async loadTenancies(tenancyIds) {
      try {
        const response = await ObjectApi.getTenanciesByIds(tenancyIds)
        this.tenancies = response.tenancies
        const objectIds = response.tenancies.map(x => x.objectId)
        await this.loadObjects(objectIds)
        await this.getDevicesByRefId(objectIds)
      } catch (e) {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.building'), { type: 'error' })
      }
    },
    async loadCondoOwners(condoOwnersIds) {
      try {
        const response = await ObjectApi.getCondominiumByIds(condoOwnersIds)
        this.condoOwners = response.condominiums
        const objectIds = response.condominiums.map(x => x.objectId)
        await this.loadObjects(objectIds)
        await this.getDevicesByRefId(objectIds)
      } catch (e) {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.building'), { type: 'error' })
      }
    },
    async loadRooms(roomIds) {
      try {
        const response = await RoomApi.listById(roomIds)
        this.rooms = this.rooms.concat(response.rooms.length > 0 ? response.rooms : [])
        const room = this.rooms.find(x => x.id === this.roomId)
        if (room) {
          if (room.referenceId.startsWith('obj')) {
            await this.loadObjects([room.referenceId])
            if (this.source === 'room') {
              this.selectedObjects = this.objects.filter(x => x.id === room.referenceId)
            }
          } else if (room.referenceId.startsWith('building')) {
            await this.loadBuildings([room.referenceId])
            if (this.source === 'room') {
              this.selectedBuildings = this.buildings.filter(x => x.id === room.referenceId)
            }
          }
        }
      } catch (e) {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.building'), { type: 'error' })
      }
    },
    getObjectName(objectId) {
      const obj = this.objects.find(e => e.id === objectId)
      return obj ? obj.name : ''
    },
    noteChanged(id, note) {
      this.devicesForApi.find(x => x.id === id).note = note
    },
    itemSelected(ids, contracts) {
      this.contractTemplateSelected = ids
      this.contractTemplate = contracts
    },
    nextStep(props) {
      if (props.activeTabIndex === 0) {
        // if (this.selectedDevices.length < 1) {
        //   Vue.toasted.show(this.$t('message.savingErrors.hasNoDevice'), { type: 'error' })
        // } else {
        if (this.source !== 'email') {
          props.nextTab()
          this.scrollToTop()
        } else {
          if (this.selectedObjects.length < 1) {
            Vue.toasted.show(this.$tc('message.savingErrors.hasNoObject'), { type: 'error' })
          } else {
            props.nextTab()
            this.scrollToTop()
          }
        }
        // }
      } else if ((this.source !== 'email' && props.activeTabIndex === 1) || (this.source === 'email' && props.activeTabIndex === 2)) {
        if (this.recipientContact && !this.recipientContact.email) {
          Vue.toasted.show(this.$t('message.savingErrors.operatorHasNoEmail'), { type: 'error' })
        } else {
          props.nextTab()
          this.scrollToTop()
        }
      } else {
        props.nextTab()
        this.scrollToTop()
      }
    },
    finishProcess() {
      if (this.$refs.orderDataTab) {
        const isInvalid = this.$refs.orderDataTab.isInvalid()
        if (!isInvalid) {
          let contactObject = null
          if (this.onSiteContactType === 'VIEWING_APPOINTMENT_CONTACT_TYPE_FREE_TEXT' && (this.contact.firstName || this.contact.lastName || this.contact.email || this.contact.phone)) {
            contactObject = this.contact
          }
          this.loading = true
          const promises = []
          const array = []
          for (let j = 0; j < this.contractTemplateSelected.length; j++) {
            array.push({
              contract: this.contractTemplateSelected[j]
            })
            promises.push(
              ObjectApi.renderDevice(
                this.contractTemplateSelected[j],
                this.selectedEmployee,
                this.recipientContactId,
                this.devicesForApi,
                this.executeFrom,
                this.executeUntil,
                this.orderDescription,
                this.onSiteContactPersonIds.length > 0 ? this.onSiteContactPersonIds[0] : this.$refs.orderDataTab.selectedUser,
                contactObject,
                this.attachAutomatically
              )
            )
          }
          Promise.all(promises)
            .then(response => {
              const renderId = []
              response.forEach((element, index) => {
                const contract = this.contractTemplate.find(x => x.id === array[index].contract)
                const contractName = contract.title
                let downloadName = contractName + '_' + this.selectedDevices.map(x => this.$t('message.onBoarding.devices.typeValues.' + x.type)).toString().replaceAll(',', '_')
                const item = {
                  renderId: element.renderId + '_' + downloadName + '-/-' + contract.enforcePdf,
                  contract: contract
                }
                renderId.push(item)
              })
              localStorage.setItem('renderId', JSON.stringify(renderId))
              this.redirectOnFinish('createDocs')
            })
            .catch(e => {
              this.handleErrorOnFinish(e)
            })
        }
      }
    },
    handleErrorOnFinish(e) {
      console.log(e)
      this.loading = false
      Vue.toasted.show(this.$t('message.savingErrors.createOrder'), { type: 'error' })
      const lastRoute = JSON.parse(localStorage.getItem('LS_ROUTE_KEY'))
      this.$router.push({
        name: lastRoute.name,
        params: lastRoute.params,
        query: lastRoute.query
      }, () => {
      })
    },
    redirectOnFinish(lastTask) {
      setTimeout(() => {
        const lastRoute = JSON.parse(localStorage.getItem('LS_ROUTE_KEY'))
        if (lastTask === 'ticket') {
          Vue.toasted.show(this.$t('message.successMessages.orderSent'), { type: 'success' })
        } else if (lastTask === 'createDocs') {
          Vue.toasted.show(this.$t('message.successMessages.createOrder'), { type: 'success' })
        }
        this.$router.push({
          name: lastRoute.name,
          params: lastRoute.params,
          query: lastRoute.query
        }, () => {
        })
        this.loading = false
        this.disableBtns = false
      }, 2000)
    },
    backClicked(props) {
      props.prevTab()
      this.scrollToTop()
    },
    handleCheckboxesInsideTable(value) {
      if (this.$refs.deviceTable) {
        const allCheckboxesComponents = this.$refs.deviceTable.$children[1].$children
        this.handleCheckbox(allCheckboxesComponents, value)
      }
    },
    scrollToTop() {
      this.$nextTick(() => {
        const domRect = this.$el.querySelector('#wizard')
        domRect.scrollIntoView(true)
      })
    },
    recipientChanged(recipientId, recipient) {
      this.recipientContactId = recipientId
      this.recipientContact = recipient
    },
    orderDataUpdated(data) {
      Object.assign(this, data)
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.hideSteps.vue-form-wizard) {
  .wizard-header {
    display: none;
  }

  ul.wizard-nav-pills,
  .wizard-progress-with-circle {
    display: none !important;
  }
}

.scrollable-print-message {
  position: fixed;
  height: 100%;
  overflow-y: auto;
}
</style>
