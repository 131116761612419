// @ts-nocheck
export default {
  namespaced: true,
  state: {
    accessToken: null,
    currentUser: null,
    changedModal: false,
    logoUrl: null,
    renderId: null,
    isCompatibleBrowser: null,
    fontSettings: null,
    isTicketingEnabled: null,
    isInvestPlanEnabled: null,
    isBriefButlerEnabled: null,
    isDeepSignEnabled: null,
    isSendMailModalOpen: false
  },
  getters: {
    getAccessToken: (state) => {
      return state.accessToken
    },
    getIsTicketingEnabled: (state) => {
      return state.isTicketingEnabled
    },
    getIsInvestPlanEnabled: (state) => {
      return state.isInvestPlanEnabled
    },
    getIsBriefButlerEnabled: (state) => {
      return state.isBriefButlerEnabled
    },
    getIsDeepSignEnabled: (state) => {
      return state.isDeepSignEnabled
    },
    getIsCompatibleBrowser: (state) => {
      return state.isCompatibleBrowser
    },
    getFontSettings(state) {
      return state.fontSettings
    },
    getRenderId: (state) => {
      return state.renderId
    },
    getCurrentUser: (state) => {
      return state.currentUser
    },
    isAuthenticated: (state) => {
      return state.currentUser != null
    },
    isSendMailModalOpen: (state) => {
      return state.isSendMailModalOpen
    },
    getLang: (state) => {
      return state.currentUser != null ? state.currentUser['https://coozzy.ch/lang'] : 'en'
    },
    getEmail: (state) => {
      return state.currentUser != null ? state.currentUser.email : null
    },
    getUserId: (state) => {
      return state.currentUser != null ? state.currentUser.sub : null
    },
    getAccountId: (state) => {
      return state.currentUser != null ? state.currentUser['https://coozzy.ch/accountId'] : null
    },
    getSubType: (state) => {
      return state.currentUser != null ? state.currentUser['https://coozzy.ch/subType'] : null
    },
    getType: (state) => {
      return state.currentUser != null ? state.currentUser['https://coozzy.ch/type'] : null
    },
    getLogoUrl: (state) => {
      return state.logoUrl
    },
    isPlatformEmployee: (state) => {
      return state.currentUser != null ? state.currentUser['https://coozzy.ch/type'] === 'platform_employee' : false
    },
    isCompanyAccount: (state) => {
      return state.currentUser != null ? state.currentUser['https://coozzy.ch/type'] === 'company' : false
    },
    isCompanyAdmin: (state) => {
      return state.currentUser != null ? state.currentUser['https://coozzy.ch/roles'].includes('company_admin') : false
    },
    isEditor: (state) => {
      return state.currentUser != null ? (state.currentUser['https://coozzy.ch/roles'].includes('editor') || state.currentUser['https://coozzy.ch/roles'].includes('company_admin')) : false
    },
    isJanitor: (state) => {
      return state.currentUser != null ? state.currentUser['https://coozzy.ch/roles'].includes('janitor') || state.currentUser['https://coozzy.ch/roles'].includes('janitor_editor') : false
    },
    isJanitorEditor: (state) => {
      return state.currentUser != null ? state.currentUser['https://coozzy.ch/roles'].includes('janitor_editor') : false
    },
    isTicketReader: (state) => {
      return state.currentUser != null ? state.currentUser['https://coozzy.ch/roles'].includes('ticket_reader') : false
    },
    isTicketCreator: (state) => {
      return state.currentUser != null ? (state.currentUser['https://coozzy.ch/roles'].includes('ticket_creator') || state.currentUser['https://coozzy.ch/roles'].includes('company_admin')) : false
    },
    isTicketEditor: (state) => {
      return state.currentUser != null ? (state.currentUser['https://coozzy.ch/roles'].includes('ticket_editor') || state.currentUser['https://coozzy.ch/roles'].includes('company_admin')) : false
    },
    isTicketDeletor: (state) => {
      return state.currentUser != null ? (state.currentUser['https://coozzy.ch/roles'].includes('ticket_delete') || state.currentUser['https://coozzy.ch/roles'].includes('company_admin')) : false
    },
    userWithoutRole: (state, getters) => {
      return !getters.isCompanyAdmin && !getters.isEditor
    },
    hasDigitalSignatureRole: (state) => {
      return state.currentUser != null ? (state.currentUser['https://coozzy.ch/roles'].includes('digital_signature') || state.currentUser['https://coozzy.ch/roles'].includes('company_admin')) : false
    },
    hasFinanceBaseRole: (state) => {
      return state.currentUser != null ? state.currentUser['https://coozzy.ch/roles'].includes('accounting') : false
    },
    hasAdminBaseRole: (state) => {
      return state.currentUser != null ? state.currentUser['https://coozzy.ch/roles'].includes('management') : false
    },
    hasMarketbaseRole: (state) => {
      return state.currentUser != null ? state.currentUser['https://coozzy.ch/roles'].includes('marketing') : false
    },
    hasAssetBaseRole: (state) => {
      return state.currentUser != null ? state.currentUser['https://coozzy.ch/roles'].includes('asset') : false
    },
    hasManageTemplatesRole: (state) => {
      return state.currentUser != null ? state.currentUser['https://coozzy.ch/roles'].includes('manage_templates') : false
    },
    hasChangedModal: (state) => {
      return state.changedModal
    },
    hasManageMailsRole: (state) => {
      return state.currentUser != null ? state.currentUser['https://coozzy.ch/roles'].includes('manage_mails') : false
    },
    hasPublishUpdateMessageRole: (state) => {
      return state.currentUser != null ? state.currentUser['https://coozzy.ch/roles'].includes('publish_update_message') : false
    },
    hasGenerateDataSetRole: (state) => {
      return state.currentUser != null ? state.currentUser['https://coozzy.ch/roles'].includes('generate_data_sets') : false
    }
  },
  mutations: {
    setSendMailModalOpen: (state, value) => {
      state.isSendMailModalOpen = value
    },
    setAccessToken: (state, value) => {
      state.accessToken = value
    },
    setIsTicketingEnabled: (state, value) => {
      state.isTicketingEnabled = value
    },
    setIsInvestPlanEnabled: (state, value) => {
      state.isInvestPlanEnabled = value
    },
    setIsBriefButlerEnabled: (state, value) => {
      state.isBriefButlerEnabled = value
    },
    setCurrentUser: (state, value) => {
      state.currentUser = value
    },
    setChangedModal: (state, value) => {
      state.changedModal = value
    },
    setLogoUrl: (state, value) => {
      state.logoUrl = value
    },
    setIsCompatibleBrowser: (state, value) => {
      state.isCompatibleBrowser = value
    },
    setFontSettings: (state, value) => {
      state.fontSettings = value
    },
    setRenderId: (state, value) => {
      state.renderId = value
    }
  },
  actions: {
    setSendMailModalOpen: ({ commit }, value) => {
      commit('setSendMailModalOpen', value)
    },
    setAccessToken: ({ commit }, value) => {
      commit('setAccessToken', value)
    },
    setIsTicketingEnabled: ({ commit }, value) => {
      commit('setIsTicketingEnabled', value)
    },
    setIsInvestPlanEnabled: ({ commit }, value) => {
      commit('setIsInvestPlanEnabled', value)
    },
    setIsBriefButlerEnabled: ({ commit }, value) => {
      commit('setIsBriefButlerEnabled', value)
    },
    changeCurrentUser: ({ commit }, value) => {
      commit('setCurrentUser', value)
    },
    logout: ({ commit }) => {
      commit('setAccessToken', null)
      commit('setCurrentUser', null)
    },
    setChangedModal({ commit }, value) {
      commit('setChangedModal', value)
    },
    setLogoUrl({ commit }, value) {
      commit('setLogoUrl', value)
    },
    setIsCompatibleBrowser({ commit }, value) {
      commit('setIsCompatibleBrowser', value)
    },
    setFontSettings({ commit }, value) {
      commit('setFontSettings', value)
    },
    setRenderId({ commit }, value) {
      commit('setRenderId', value)
    }
  }
}
