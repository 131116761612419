<template>
  <section class="w-100">
    <div
      :id="`tenancy-${index}`"
      v-b-toggle="`collapse-vanancy-${index}`"
      class="col pt-3 mb-0">
      <div class="row">
        <div class="col">
          <h5 class="cursor-pointer hover width-only-content">
            <span>
              {{ $t('message.onBoarding.buildings.objects.tenancies.vacancy') }}
            </span>
            <span>
              :
              <template v-if="tenancy.startingAt && tenancy.startingAt.year !== -1 && tenancy.startingAt.year !== '-1'">
                {{ tenancy.startingAt | objectToDate }}
              </template>
              <template v-else>
                n/a
              </template>
              -
              <template v-if="tenancy.endingAt && tenancy.endingAt.year !== -1 && tenancy.endingAt.year !== '-1'">
                {{ tenancy.endingAt | objectToDate }}
              </template>
              <template v-else>
                n/a
              </template>
            </span>
          </h5>
        </div>
        <div
          v-if="!userWithoutRole && $route.path.startsWith('/admin/buildings/') > 0 || $route.path.startsWith('/accounting/buildings/') > 0"
          class="col mr-5">
          <coozzy-dropdown
            size="sm"
            class="w-20 sort float-right"
            :text="$t('message.generic.actions')">
            <coozzy-dropdown-item
              v-if="tenancy.activated && object && ((object.debitUntil && new Date($options.filters.objectToDateInput(object.debitUntil)) < new Date($options.filters.objectToDateInput(tenancy.startingAt))) || !object.debitUntil)"
              :disabled="isBetweenTenancies"
              @click="removeActiveVacancy();$event.stopPropagation()">
              {{ $t('message.onBoarding.buildings.objects.tenancies.deleteVacancy') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="!tenancy.activated && object && ((object.debitUntil && new Date($options.filters.objectToDateInput(object.debitUntil)) < new Date($options.filters.objectToDateInput(tenancy.startingAt))) || !object.debitUntil)"
              :disabled="isBetweenTenancies"
              @click="removeClicked();$event.stopPropagation()">
              {{ $t('message.onBoarding.buildings.objects.tenancies.deleteVacancy') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="!tenancy.activated"
              @click="activateVacancyClicked();$event.stopPropagation()">
              {{ $t('message.onBoarding.buildings.objects.tenancies.activateVacancyConfirmationTitle') }}
            </coozzy-dropdown-item>
            <add-document-modal
              :suffix="'tenancies-doc' + index"
              :owner-id="tenancy.ownerId"
              @document-created="documentCreatedAction" />
            <coozzy-dropdown-item
              @click="addDocumentClicked('tenancies-doc' + index);$event.stopPropagation()">
              {{ $t('message.manageDocuments.addDocument') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              @click="$bvModal.show(`create-contract-confirmationModal-${index}`);$event.stopPropagation()">
              {{ $t('message.addTenancy.createDocument') }}
            </coozzy-dropdown-item>
          </coozzy-dropdown>
        </div>
      </div>
    </div>
    <b-collapse
      :id="`collapse-vanancy-${index}`"
      ref="collapse"
      :visible="expandByDefault || openedCollapses.includes(`collapse-vanancy-${index}`)"
      class="col">
      <div class="row">
        <div class="col">
          <h6
            v-b-toggle="`collapse-tenancy-${index}-tenant`"
            class="font-weight-bold mt-3"
            :class="[readOnly ? 'none': 'cursor-pointer', 'width-only-content hover']">
            {{ $t('message.onBoarding.buildings.objects.tenancies.vacancy') }}
          </h6>
        </div>
      </div>
      <b-collapse
        :id="`collapse-tenancy-${index}-tenant`"
        :visible="expandByDefault">
        <div
          :ref="`objvacancyrow-${tenancy.id}`"
          class="row objvacancyrow py-01rem">
          <div class="col-sm-12 col-md-3">
            <label>
              {{ $t('message.onBoarding.buildings.objects.tenancies.vacancyReason.title') }} {{ (readOnly ? '' : '*') }}
            </label>
            <coozzy-form-select
              v-if="!readOnly"
              id="vacancyReason"
              v-model="vacancyReason"
              :state="$v.vacancyReason.$dirty && $v.vacancyReason.$error ? false : null"
              :disabled="disabledFields">
              <option value="">
                -
              </option>
              <option value="VACANCY_REASON_NO_NEXT_TENANT">
                {{ $t('message.onBoarding.buildings.objects.tenancies.vacancyReason.VACANCY_REASON_NO_NEXT_TENANT') }}
              </option>
              <option value="VACANCY_REASON_INITIAL_LETTING">
                {{ $t('message.onBoarding.buildings.objects.tenancies.vacancyReason.VACANCY_REASON_INITIAL_LETTING') }}
              </option>
              <option value="VACANCY_REASON_RENOVATION">
                {{ $t('message.onBoarding.buildings.objects.tenancies.vacancyReason.VACANCY_REASON_RENOVATION') }}
              </option>
              <option value="VACANCY_REASON_TEMPORARY_TENANCY">
                {{ $t('message.onBoarding.buildings.objects.tenancies.vacancyReason.VACANCY_REASON_TEMPORARY_TENANCY') }}
              </option>
              <option value="VACANCY_REASON_SALE">
                {{ $t('message.onBoarding.buildings.objects.tenancies.vacancyReason.VACANCY_REASON_SALE') }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="show-data">
              <span v-if="vacancyReason === 'VACANCY_REASON_NO_NEXT_TENANT'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.vacancyReason.VACANCY_REASON_NO_NEXT_TENANT') }}
              </span>
              <span v-else-if="vacancyReason === 'VACANCY_REASON_INITIAL_LETTING'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.vacancyReason.VACANCY_REASON_INITIAL_LETTING') }}
              </span>
              <span v-else-if="vacancyReason === 'VACANCY_REASON_RENOVATION'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.vacancyReason.VACANCY_REASON_RENOVATION') }}
              </span>
              <span v-else-if="vacancyReason === 'VACANCY_REASON_TEMPORARY_TENANCY'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.vacancyReason.VACANCY_REASON_TEMPORARY_TENANCY') }}
              </span>
              <span v-else-if="vacancyReason === 'VACANCY_REASON_SALE'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.vacancyReason.VACANCY_REASON_SALE') }}
              </span>
              <span v-else> - </span>
            </span>
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="rentType">
              {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.title') }} {{ (readOnly ? '' : '*') }}
            </label>
            <coozzy-form-select
              v-if="!readOnly"
              id="rentType"
              v-model="rentType"
              :state="$v.rentType.$dirty && $v.rentType.$error ? false : null"
              :disabled="disabledFields">
              <option value="">
                -
              </option>
              <option value="RENT_TYPE_VMWG">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_VMWG') }}
              </option>
              <option value="RENT_TYPE_INDEX">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_INDEX') }}
              </option>
              <option value="RENT_TYPE_TURNOVER">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_TURNOVER') }}
              </option>
              <option value="RENT_TYPE_NOT_VMWG">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_NOT_VMWG') }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="show-data">
              <span v-if="rentType === 'RENT_TYPE_VMWG'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_VMWG') }}
              </span>
              <span v-else-if="rentType === 'RENT_TYPE_CONDOMINIUM_OWNERSHIP'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_CONDOMINIUM_OWNERSHIP') }}
              </span>
              <span v-else-if="rentType === 'RENT_TYPE_INDEX'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_INDEX') }}
              </span>
              <span v-else-if="rentType === 'RENT_TYPE_GRADUATED'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_GRADUATED') }}
              </span>
              <span v-else-if="rentType === 'RENT_TYPE_TURNOVER'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_TURNOVER') }}
              </span>
              <span v-else-if="rentType === 'RENT_TYPE_SUBSIDIZES'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_SUBSIDIZES') }}
              </span>
              <span v-else-if="rentType === 'RENT_TYPE_NOT_VMWG'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_NOT_VMWG') }}
              </span>
              <span v-else> - </span>
            </span>
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="typeOfUse">
              {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.title') }} {{ (readOnly ? '' : '*') }}
            </label>
            <coozzy-form-select
              v-if="!readOnly"
              id="typeOfUse"
              v-model="typeOfUse"
              :state="$v.typeOfUse.$dirty && $v.typeOfUse.$error ? false : null"
              :disabled="disabledFields">
              <option value="">
                -
              </option>
              <option
                v-for="(item, i) in typeOfUseData"
                :key="i"
                :value="item">
                {{ $t(`message.onBoarding.buildings.objects.tenancies.typeOfUse.${item}`) }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="show-data">
              <span v-if="typeOfUse === 'TYPE_OF_USE_VACATION'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_VACATION') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_COMMERCIAL'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_COMMERCIAL') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_PARKING'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_PARKING') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_PARKING_EXTERNAL'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_PARKING_EXTERNAL') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_AGRICULTURAL'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_AGRICULTURAL') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_CONSTRUCTION'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_CONSTRUCTION') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_GASTRONOMY'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_GASTRONOMY') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_INDUSTRIAL'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_INDUSTRIAL') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_INVESTMENT'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_INVESTMENT') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_STORAGE'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_STORAGE') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_RESIDENTIAL'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_RESIDENTIAL') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_SECONDARY_ROOM'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_SECONDARY_ROOM') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_OFFICE'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_OFFICE') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_STORE'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_STORE') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_ADVERTISING_AREA'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_ADVERTISING_AREA') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_RADIO_ANTENNA'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_RADIO_ANTENNA') }}
              </span>
              <span v-else> - </span>
            </span>
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="dates.startingAt"
              :is-read-only="readOnly"
              :filter="'formatDate'"
              type="date"
              :state="$v.startingAt.$dirty && $v.startingAt.$error ? false : null"
              :name="$t('message.addVacancy.startVacancyAt') + (readOnly ? '' : '*')"
              :disabled="disabledFields"
              @blur="dateToObject()" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="dates.endingAt"
              type="date"
              :state="(dates.endingAt && $v.endingAt.$dirty && $v.endingAt.$error) ? false : null"
              :is-read-only="readOnly"
              :filter="'formatDate'"
              :name="$t('message.addVacancy.endingVacancyAt')"
              :disabled="disabledFields"
              @blur="dateToObject()" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="contractNumber"
              :disabled="true"
              :is-read-only="readOnly"
              :name="$t('message.onBoarding.buildings.objects.tenancies.contractNumber')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="additionalCostsBillingType">
              {{ $t('message.onBoarding.buildings.objects.tenancies.additionalCostsBillingTypes.title') + (readOnly ? '' : '*') }}
            </label>
            <coozzy-form-select
              v-if="!readOnly"
              id="additionalCostsBillingType"
              v-model="additionalCostsBillingType"
              :disabled="disabledFields">
              <option value="UNDEFINED_ADDITIONAL_COSTS_BILLING_TYPE">
                {{ $t('message.onBoarding.buildings.objects.tenancies.additionalCostsBillingTypes.options.UNDEFINED_ADDITIONAL_COSTS_BILLING_TYPE') }}
              </option>
              <option value="ADVANCE_PAYMENT_ADDITIONAL_COSTS_BILLING_TYPE">
                {{ $t('message.onBoarding.buildings.objects.tenancies.additionalCostsBillingTypes.options.ADVANCE_PAYMENT_ADDITIONAL_COSTS_BILLING_TYPE') }}
              </option>
              <option value="FLATRATE_ADDITIONAL_COSTS_BILLING_TYPE">
                {{ $t('message.onBoarding.buildings.objects.tenancies.additionalCostsBillingTypes.options.FLATRATE_ADDITIONAL_COSTS_BILLING_TYPE') }}
              </option>
            </coozzy-form-select>
            <span v-else>
              {{ $t(`message.onBoarding.buildings.objects.tenancies.additionalCostsBillingTypes.options.${additionalCostsBillingType}`) }}
            </span>
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="tenancyStatus">
              {{ $t('message.addVacancy.vacancyStatus') + (readOnly ? '' : '*') }}
            </label>
            <coozzy-form-select
              v-if="!readOnly"
              id="tenancyStatus"
              v-model="tenancyStatus"
              :state="$v.tenancyStatus.$dirty && $v.tenancyStatus.$error ? false : null"
              :disabled="disabledFields || isInprogress">
              <option
                v-if="!hideInprogress"
                :value="null">
                -
              </option>
              <option
                v-if="!hideInprogress"
                value="inProgress">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tenancyStatus.inProgress') }}
              </option>
              <option value="active">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tenancyStatus.active') }}
              </option>
              <option
                v-if="endingDatebeforeNow"
                value="activeClosed">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tenancyStatus.activeClosed') }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="show-data">
              <span v-if="tenancyStatus === null">
                -
              </span>
              <span v-else-if="tenancyStatus === 'inProgress'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tenancyStatus.inProgress') }}
              </span>
              <span v-else-if="tenancyStatus === 'active'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tenancyStatus.active') }}
              </span>
              <span v-else-if="tenancyStatus === 'activeClosed'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tenancyStatus.activeClosed') }}
              </span>
              <span v-else> - </span>
            </span>
          </div>
          <div
            v-if="(property && property.opted === true) || (['TYPE_OF_USE_PARKING_EXTERNAL', 'TYPE_OF_USE_SECONDARY_ROOM', 'TYPE_OF_USE_STORAGE', 'TYPE_OF_USE_ADVERTISING_AREA', 'TYPE_OF_USE_RADIO_ANTENNA'].includes(typeOfUse))"
            class="col-sm-12 col-md-3">
            <label for="tax">
              {{ $t('message.onBoarding.buildings.objects.tenancies.tax.title') }}
            </label>
            <coozzy-form-select
              v-if="!readOnly"
              id="tax"
              v-model="taxable"
              :disabled="(object.debitUntil && new Date($options.filters.objectToDateInput(object.debitUntil)) > new Date($options.filters.objectToDateInput(tenancy.startingAt)))">
              <option
                :value="null">
                -
              </option>
              <option
                :disabled="(property && property.opted === false)"
                :value="false">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tax.opted') }}
              </option>
              <option
                v-if="(property && property.opted === false) && (['TYPE_OF_USE_PARKING_EXTERNAL', 'TYPE_OF_USE_SECONDARY_ROOM', 'TYPE_OF_USE_STORAGE', 'TYPE_OF_USE_ADVERTISING_AREA', 'TYPE_OF_USE_RADIO_ANTENNA'].includes(typeOfUse))"
                :value="true">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tax.taxable') }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="d-block">
              <span v-if="taxable === null">
                -
              </span>
              <span v-else-if="taxable === false">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tax.opted') }}
              </span>
              <span v-else-if="taxable === true">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tax.taxable') }}
              </span>
              <span v-else> - </span>
            </span>
          </div>
          <div :class="readOnly ? 'col-sm-12 col-md-3' : 'col'">
            <coozzy-form-textarea
              v-model="note"
              :is-read-only="readOnly"
              :initial="note">
              {{ $t('message.onBoarding.buildings.objects.tenancies.note') }}
            </coozzy-form-textarea>
          </div>
        </div>
      </b-collapse>
      <div class="row">
        <div class="col">
          <hr class="mb-0">
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h6
            v-b-toggle="`collapse-tenancy-${index}-currentcost`"
            class="cursor-pointer hover font-weight-bold mt-3 width-only-content">
            {{ $t('message.onBoarding.buildings.objects.pricing.rents') }}
          </h6>
        </div>
      </div>
      <b-collapse
        :id="`collapse-tenancy-${index}-currentcost`"
        :visible="!readOnly || expandAllChild || openedCollapses.includes(`collapse-tenancy-${index}-currentcost`)">
        <template v-for="(adjustment, adjIndex) in adjustments">
          <tenancy-adjustment
            ref="tenancy-adjustment"
            :key="adjustment.internalId"
            :index="adjIndex"
            :adjustment="adjustment"
            :tenancy="tenancy"
            :read-only="readOnly"
            :tenancy-mode="mode"
            :opted="opted || taxable"
            :object="object"
            :active-and-editable="activeAndEditable"
            :is-most-recent="adjustments[0] === adjustment"
            :until-date="convertTimestampToISODate(adjustments[adjIndex - 1] ? getPreviousDayOfTimestamp(adjustments[adjIndex - 1].byDate) : -1)"
            @delete-adjustment="deleteAdjustmentClicked"
            @change-adjustment="changeAdjustment" />
        </template>
        <div class="col-12 px-0 mt-2">
          <hr
            v-if="!readOnly"
            class="mt-0">
        </div>
        <div class="col-2 my-2 pr-2 pl-0 ml-0">
          <coozzy-button
            v-if="!readOnly"
            class="w-100"
            design="prop-green"
            @click="addAdjustmentClicked">
            {{ $t('message.onBoarding.buildings.objects.tenancies.addNewRent') }}
          </coozzy-button>
        </div>
      </b-collapse>
      <div
        v-if="rentType === 'RENT_TYPE_INDEX' || rentType === 'RENT_TYPE_TURNOVER'"
        class="row">
        <div class="col-12">
          <hr class="mt-0">
        </div>
        <div class="col">
          <h6
            v-b-toggle="`collapse-tenancy-${index}-index`"
            class="cursor-pointer hover font-weight-bold mt-3 width-only-content">
            {{ $t('message.onBoarding.buildings.objects.tenancies.indexData.title') }}
          </h6>
        </div>
      </div>
      <b-collapse
        v-if="rentType === 'RENT_TYPE_INDEX' || rentType === 'RENT_TYPE_TURNOVER'"
        :id="`collapse-tenancy-${index}-index`"
        :visible="expandAllChild || openedCollapses.includes(`collapse-tenancy-${index}-index`)">
        <div class="row">
          <div class="col">
            <h6 class="font-weight-bold mt-3">
              {{ $t('message.onBoarding.buildings.objects.tenancies.indexData.general') }}
            </h6>
          </div>
        </div>
        <div class="row details-row-bg-1 py-01rem">
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="dates.firstRentAdjustmentPossibleAt"
              :is-read-only="readOnly"
              :filter="'formatDate'"
              type="date"
              :name="$t('message.onBoarding.buildings.objects.tenancies.indexData.firstRentAdjustmentPossibleOn')"
              @blur="dateToObject()" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="rentAdjustmentByMonths"
              type="number"
              min="0"
              :is-read-only="readOnly"
              :is-decimal="true"
              :name="$t('message.onBoarding.buildings.objects.tenancies.indexData.rentAdjustmentByMonth')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="rentAdjustmentByPoints"
              type="number"
              min="0"
              :is-read-only="readOnly"
              :is-decimal="true"
              :name="$t('message.onBoarding.buildings.objects.tenancies.indexData.rentAdjustmentByPoints')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="contractPercentImputedAtIndex"
              type="number"
              min="0"
              :is-read-only="readOnly"
              :is-decimal="true"
              :name="$t('message.onBoarding.buildings.objects.tenancies.indexData.contractImputedAtIndex')" />
          </div>
        </div>
        <div class="row details-row-bg-2 py-01rem">
          <div class="col-sm-12 col-md-3">
            <label for="referenceMonthForIndex">
              {{ $t('message.onBoarding.buildings.objects.tenancies.indexData.referenceMonthForIndex') }}
            </label>
            <coozzy-form-select
              v-if="!readOnly"
              id="referenceMonthForIndex"
              v-model="referenceMonthForIndex">
              <option value="-1">
                -
              </option>
              <option
                v-for="m in valueMonth"
                :key="m"
                :value="m">
                {{ m }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="d-block">
              {{ referenceMonthForIndex }}
            </span>
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="maximumRate"
              type="number"
              min="0"
              :is-read-only="readOnly"
              :is-decimal="true"
              :name="$t('message.onBoarding.buildings.objects.tenancies.indexData.maximumRate')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <coozzy-form-input-numeric
                  v-model="rentAdjustmentNotificationValue"
                  type="number"
                  min="0"
                  :is-read-only="readOnly"
                  :is-integer="true"
                  :name="$t('message.onBoarding.buildings.objects.tenancies.indexData.rentAdjustmentNotification.title')" />
              </div>
              <div class="col-sm-12 col-md-6 pl-0 mt-4">
                <coozzy-form-select
                  v-if="!readOnly"
                  id="rentAdjustmentNotificationUnit"
                  v-model="rentAdjustmentNotificationUnit">
                  <option value="RENT_ADJUSTMENT_NOTIFICATION_DAYS">
                    {{ $t('message.onBoarding.buildings.objects.tenancies.indexData.rentAdjustmentNotification.RENT_ADJUSTMENT_NOTIFICATION_DAYS') }}
                  </option>
                  <option value="RENT_ADJUSTMENT_NOTIFICATION_MONTHS">
                    {{ $t('message.onBoarding.buildings.objects.tenancies.indexData.rentAdjustmentNotification.RENT_ADJUSTMENT_NOTIFICATION_MONTHS') }}
                  </option>
                </coozzy-form-select>
                <span
                  v-else
                  class="d-block">
                  {{ $t('message.onBoarding.buildings.objects.tenancies.indexData.rentAdjustmentNotification.' + rentAdjustmentNotificationUnit) }}
                </span>
              </div>
            </div>
          </div>
          <div
            v-if="readOnly"
            class="col-sm-12 col-md-3">
            <label for="rentAdjustmentMonths">
              {{ $t('message.onBoarding.buildings.objects.tenancies.indexData.rentAdjustmentMonths') }}
            </label>
            <p
              v-if="getRentAdjustmentMonths.length > 0"
              class="mb-0 multi-select-value">
              {{ getRentAdjustmentMonths }}
            </p>
            <p
              v-else
              class="mb-0 multi-select-value">
              -
            </p>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <coozzy-multiselect
              id="rentAdjustmentMonths"
              v-model="rentAdjustmentMonths"
              :options="translatedMonths"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :preselect-first="false"
              :taggable="false"
              label="label"
              track-by="label">
              {{ $t('message.onBoarding.buildings.objects.tenancies.indexData.rentAdjustmentMonths') }}
            </coozzy-multiselect>
          </div>
        </div>
        <div class="row details-row-bg-1 py-01rem">
          <div
            v-if="!readOnly"
            class="col-sm-6 col-md-3 col-6 mt-4">
            <coozzy-form-checkbox
              v-model="rentAdjustmentAdditionalBenefit"
              :initial="rentAdjustmentAdditionalBenefit"
              name="rentAdjustmentAdditionalBenefit">
              {{ $t('message.onBoarding.buildings.objects.tenancies.indexData.rentAdjustmentAdditionalBenefit') }}
            </coozzy-form-checkbox>
          </div>
          <div
            v-else
            class="col-sm-6 col-md-3 col-6">
            <label for="rentAdjustmentAdditionalBenefit">{{ $t('message.onBoarding.buildings.objects.tenancies.indexData.rentAdjustmentAdditionalBenefit')
            }}</label>
            <span
              class="show-data">{{ rentAdjustmentAdditionalBenefit === null || rentAdjustmentAdditionalBenefit === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
          </div>
          <div
            v-if="!readOnly"
            class="col-sm-6 col-md-3 col-6 mt-4">
            <coozzy-form-checkbox
              v-model="belowInitialRentAllowed"
              :initial="belowInitialRentAllowed"
              name="belowInitialRentAllowed">
              {{ $t('message.onBoarding.buildings.objects.tenancies.indexData.belowInitialRentAllowed') }}
            </coozzy-form-checkbox>
          </div>
          <div
            v-else
            class="col-sm-6 col-md-3 col-6">
            <label for="belowInitialRentAllowed">{{ $t('message.onBoarding.buildings.objects.tenancies.indexData.belowInitialRentAllowed')
            }}</label>
            <span
              class="show-data">{{ belowInitialRentAllowed === null || belowInitialRentAllowed === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <h6 class="font-weight-bold mt-4">
              {{ $t('message.onBoarding.buildings.objects.tenancies.indexData.additionalCosts') }}
            </h6>
          </div>
        </div>
        <div class="row details-row-bg-1 py-01rem">
          <div
            v-if="!readOnly"
            class="col-sm-6 col-md-3 col-6">
            <coozzy-form-checkbox
              v-model="additionalCostsAdjustmentAllowed"
              :initial="additionalCostsAdjustmentAllowed"
              name="additionalCostsAdjustmentAllowed">
              {{ $t('message.onBoarding.buildings.objects.tenancies.indexData.additionalCostsAdjustmentAllowed') }}
            </coozzy-form-checkbox>
          </div>
          <div
            v-else
            class="col-sm-6 col-md-3 col-6">
            <label for="additionalCostsAdjustmentAllowed">{{ $t('message.onBoarding.buildings.objects.tenancies.indexData.additionalCostsAdjustmentAllowed')
            }}</label>
            <span
              class="show-data">{{ additionalCostsAdjustmentAllowed === null || additionalCostsAdjustmentAllowed === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
          </div>
        </div>
      </b-collapse>
      <div class="row">
        <div class="col-12">
          <hr class="mt-0">
        </div>
        <div
          v-if="documents.length > 0 || !readOnly"
          class="col-12">
          <h6
            v-b-toggle="`collapse-tenancy-${index}-document`"
            class="cursor-pointer hover font-weight-bold mt-3 width-only-content">
            {{ $t('message.generic.documents') }}
          </h6>
        </div>
        <b-collapse
          :id="`collapse-tenancy-${index}-document`"
          class="w-100"
          visible>
          <div
            v-if="!readOnly"
            class="col-2 mt-1">
            <add-document-modal
              :suffix="'tenancies' + index"
              :owner-id="tenancy.ownerId"
              @document-created="documentCreated" />
            <coozzy-button
              class="w-100"
              design="prop-green"
              :disabled="disabledFields"
              @click="addDocumentClicked('tenancies' + index)">
              {{ $t('message.manageDocuments.addDocument') }}
            </coozzy-button>
          </div>
          <div
            v-if="documents.length > 0"
            class="col-12 mt-2">
            <document
              v-for="doc in documents"
              :key="doc.id"
              :document="doc"
              :is-read-only="readOnly"
              @version-added="versionAdded"
              @delete-document="deleteDocument" />
          </div>
        </b-collapse>
      </div>
      <div
        v-if="!tenancy.activated && !readOnly && mode !== 'adding' && mode !== 'activate'"
        class="row">
        <div class="col-12 mt-3">
          <coozzy-button
            v-if="!readOnly && mode !== 'adding' && mode !== 'activate'"
            size="small"
            design="red"
            class="float-right"
            @click="removeClicked">
            {{ $t('message.onBoarding.buildings.objects.tenancies.deleteVacancy') }}
          </coozzy-button>
        </div>
      </div>
      <div
        v-if="tenancy.activated && object && ((object.debitUntil && new Date($options.filters.objectToDateInput(object.debitUntil)) < new Date($options.filters.objectToDateInput(tenancy.startingAt))) || !object.debitUntil) && !readOnly && mode !== 'adding' && mode !== 'activate'"
        class="row">
        <div class="col-12 mt-3">
          <coozzy-button
            v-if="!readOnly && mode !== 'adding' && mode !== 'activate'"
            size="small"
            design="red"
            class="float-right"
            @click="removeActiveVacancy">
            {{ $t('message.onBoarding.buildings.objects.tenancies.deleteVacancy') }}
          </coozzy-button>
        </div>
      </div>
    </b-collapse>
    <b-modal
      :id="`deleteVacancyModal-${index}`"
      :ref="`deleteVacancyModal-${index}`"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.deleteModals.confirmation')">
      <div class="col">
        <p>{{ $t('message.onBoarding.deleteModals.bodyVacancy') }}</p>
      </div>
      <div class="col">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide(`deleteVacancyModal-${index}`)">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="removeConfirmed">
          {{ $t('message.generic.delete') }}
        </coozzy-button>
      </div>
    </b-modal>
    <b-modal
      :id="`create-contract-confirmationModal-${index}`"
      :ref="`create-contract-confirmationModal-${index}`"
      :size="'xl'"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.addTenancy.createDocument')">
      <template>
        <div class="col pl-0 mb-1">
          <label>{{ $t('message.settings.template.textTemplate') }}</label>
          <coozzy-form-select
            v-model="selectedTemplate"
            name="numberEntries">
            <option
              value="">
              -
            </option>
            <option
              v-for="template in sortedTemplate"
              :key="template.id"
              :value="template.id">
              {{ template.name }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="col pl-0 mb-1">
          <label>{{ $t('message.serialLetterProcess.subject') }}</label>
          <coozzy-form-textarea
            id="title"
            v-model="title"
            :initial="title"
            :value-text-area="title"
            :text="title"
            :show-label="false"
            :rows="3" />
        </div>
        <div class="col pl-0 mb-2">
          <label>{{ $t('message.serialLetterProcess.letterText') }}</label>
          <coozzy-form-textarea
            id="description"
            v-model="description"
            :initial="description"
            :value-text-area="description"
            :text="description"
            :show-label="false"
            :rows="3" />
        </div>
        <div class="col pl-0">
          <template-table
            :source="'objectTenancy'"
            :by-owners-ids="templateOwnersIds"
            @contracts-loaded="contractsLoaded"
            @selected-contract="itemSelected" />
        </div>
        <div class="col pl-0">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide(`create-contract-confirmationModal-${index}`)">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="renderContract">
            {{ $t('message.addTenancy.createDocument') }}
          </coozzy-button>
          <coozzy-form-checkbox
            v-model="addDocumentToContact"
            class="float-right pr-3 mt-1 mr-0 display-none-xs font-weight-lighter">
            {{ $t('message.generic.addDocumentToVacancy') }}
          </coozzy-form-checkbox>
        </div>
      </template>
    </b-modal>
    <b-modal
      :id="`after-delete-tenancy-modal-${index}`"
      :ref="`after-delete-tenancy-modal-${index}`"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.generic.attention')">
      <div class="col">
        <p>{{ $t('message.onBoarding.buildings.objects.tenancies.afterDeleteTenancyVacancy1') }}</p>
        <p>{{ $t('message.onBoarding.buildings.objects.tenancies.afterDeleteTenancyVacancy2') }}</p>
      </div>
      <div class="col">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide(`after-delete-tenancy-modal-${index}`)">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="removeConfirmed">
          {{ $t('message.generic.delete') }}
        </coozzy-button>
      </div>
    </b-modal>
    <activate-vacancy-modal
      :ref="`activate-vacancy-modal-${tenancy.id}`"
      :owner-id="tenancy.ownerId"
      :tenancy-id="tenancy.id"
      @tenancy-activated="tenancyActivated" />
  </section>
</template>

<script>

import CoozzyFormInput from '../../../framework/components/form/input/CoozzyFormInput'
import CoozzyFormInputNumeric from '../../../framework/components/form/input/CoozzyFormInputNumeric'
import CoozzyFormSelect from '../../../framework/components/form/select/CoozzyFormSelect'
import CoozzyButton from '../../../framework/components/button/CoozzyButton'
import Document from '@/properties/components/Document'
import ObjectApi from '@/misc/apis/ObjectApi'
import MediaApi from '@/misc/apis/MediaApi'
import AddDocumentModal from '@/properties/components/AddDocumentModal'
import { onboarding } from '@/mixins/onboarding'
import { validation } from '@/mixins/validation'
import { required } from 'vuelidate/lib/validators'
import Vue from 'vue'
import TenancyAdjustment from './TenancyAdjustment.vue'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { routeChecks } from '@/mixins/routeChecks'
import { tables } from '@/mixins/tables'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem'
import ActivateVacancyModal from '@/addVacancy/ActivateVacancyModal'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import { countryIndex } from '@/mixins/countryIndex'
import { referenceInterestRate } from '@/mixins/referenceInterestRate'
import { dateUtils } from '@/mixins/dateUtils'
import TemplateTable from '@/settings/components/TemplateTable'
import { saveAs } from 'file-saver'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea.vue'
import { user } from '@/mixins/user'

function lessThanEnding(value, vm) {
  if (!value) {
    return false
  }
  if ((!vm.endingAt || new Date(this.$options.filters.objectToDateInput(vm.endingAt)).getTime() / 1000 <= 0)) {
    return true
  }

  return new Date(this.$options.filters.objectToDateInput(value)).getTime() < new Date(this.$options.filters.objectToDateInput(vm.endingAt)).getTime()
}
function startingMoreThanDebitUntil(value, vm) {
  if (!value) {
    return true
  }
  if (!this.object.debitUntil) {
    return true
  }
  if (!this.startingAtChanged && !this.tenancy.internalId) {
    return true
  }
  if (this.$options.filters.objectToDateInput(this.initStartingAt) === this.$options.filters.objectToDateInput(this.tenancy.startingAt) && !this.tenancy.internalId) {
    return true
  } else {
    return new Date(this.$options.filters.objectToDateInput(value)) >= new Date(this.$options.filters.objectToDateInput(this.object.debitUntil))
  }
}
function moreThanDebitUntil(value, vm) {
  if (!value) {
    return true
  }
  if (!this.object.debitUntil) {
    return true
  }
  if (!this.endingAtChanged) {
    return true
  }
  return new Date(this.$options.filters.objectToDateInput(value)) >= new Date(this.$options.filters.objectToDateInput(this.object.debitUntil))
}
function checkChanging(value, vm) {
  if (!value || !this.property.transferredToAccounting) {
    return true
  }
  return new Date(this.$options.filters.objectToDateInput(this.endingAtInit)).getTime() === new Date(this.$options.filters.objectToDateInput(value)).getTime()
}
function moreThanStarting(value, vm) {
  if (!value) {
    return true
  }
  if (!vm.startingAt) {
    return true
  }
  return new Date(this.$options.filters.objectToDateInput(value)).getTime() > new Date(this.$options.filters.objectToDateInput(vm.startingAt)).getTime()
}

export default {
  name: 'ObjectVacancy',
  components: {
    CoozzyFormTextarea,
    CoozzyMultiselect,
    ActivateVacancyModal,
    CoozzyDropdownItem,
    CoozzyDropdown,
    CoozzyFormCheckbox,
    CoozzyButton,
    CoozzyFormSelect,
    CoozzyFormInput,
    CoozzyFormInputNumeric,
    TenancyAdjustment,
    Document,
    AddDocumentModal,
    TemplateTable
  },
  mixins: [routeChecks, onboarding, validation, tables, countryIndex, referenceInterestRate, dateUtils, user],
  props: {
    isFirst: {
      type: Boolean,
      default: true
    },
    expandByDefault: {
      type: Boolean,
      default: true
    },
    expandAllChild: {
      type: Boolean,
      default: false
    },
    index: {
      type: [String, Number],
      default: ''
    },
    initEnding: {
      type: Object,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    enableTenantSelection: {
      type: Boolean,
      default: true
    },
    objectCategory: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'full',
      validator: function (value) {
        return ['full', 'adding', 'activate'].indexOf(value) !== -1
      }
    },
    tenancy: {
      type: Object,
      default: null
    },
    target: {
      type: String,
      default: 'tenants'
    },
    disabledFields: {
      type: Boolean,
      default: false
    },
    objectDebitUntil: {
      type: Boolean,
      default: false
    },
    isInprogress: {
      type: Boolean,
      default: false
    },
    initObject: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      uniqueId: null,
      startingAtChanged: false,
      activeAndEditable: false,
      localEnding: false,
      tenancyStatus: null,
      tenancyChanged: false,
      endingAtChanged: false,
      hideInprogress: false,
      dates: {
        startingAt: null,
        endingAt: null,
        firstRentAdjustmentPossibleAt: null
      },
      selectedTenant: [],
      availableTerminationMonths: ['all', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      availableIndustryCodes: [1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 35, 36, 37, 38, 39, 41, 42, 43, 45, 46, 47, 49, 50, 51, 52, 53, 55, 56, 58, 59, 60, 61, 62, 63, 64, 65, 66, 68, 69, 70, 71, 72, 73, 74, 75, 77, 78, 79, 80, 81, 82, 84, 85, 86, 87, 88, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99],
      isBusy: false,
      contractTemplate: [],
      contractTemplateSelected: [],
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'title',
          label: 'Title',
          thClass: 'align-middle d-sm-table-cell',
          tdClass: 'align-middle d-sm-table-cell'
        }
      ],
      title: '',
      description: '',
      initStartingAt: null,
      addDocumentToContact: false,
      selectedTemplate: ''
    }
  },
  computed: {
    ...mapGetters('message', ['getSentMails']),
    ...mapState('message', ['templates']),
    sortedTemplate() {
      return this.templates.filter(x => x.type === 'TEMPLATE_TYPE_LETTER').slice().sort((a, b) => {
        if (a.name > b.name) {
          return 1
        }
        if (b.name > a.name) {
          return -1
        }
        return 0
      })
    },
    templateOwnersIds() {
      const ownersIds = [].concat(parseInt(this.tenancy.ownerId))
      return ownersIds
    },
    object() {
      return this.objects && this.objects.find(x => x.id === this.tenancy.objectId) ? this.objects.find(x => x.id === this.tenancy.objectId) : this.initObject
    },
    vacancyCanBeEdited() {
      return new Date(this.$options.filters.objectToDateInput(this.tenancy.startingAt)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(this.objectDebitUntil)).getTime() / 1000
    },
    // adjustment() {
    //   return this.tenancy.rent.adjustments[0]
    // },
    adjustments() {
      return this.tenancy.rent.adjustments.slice().sort((a, b) => {
        const aDate = new Date(this.$options.filters.objectToDateInput(a.byDate)).getTime()
        const bDate = new Date(this.$options.filters.objectToDateInput(b.byDate)).getTime()
        if (bDate === 0 && b.newItem) {
          return 1
        }
        if (bDate === 0) {
          return 1
        }
        if (aDate === bDate) {
          return 0
        }
        if (aDate > bDate) {
          return -1
        }
        if (aDate < bDate) {
          return 1
        }
        return 0
      })
    },
    typeOfUseData() {
      if (!this.object || this.object.category === 'UNDEFINED_CATEGORY') {
        return ['TYPE_OF_USE_VACATION', 'TYPE_OF_USE_COMMERCIAL', 'TYPE_OF_USE_PARKING', 'TYPE_OF_USE_PARKING_EXTERNAL', 'TYPE_OF_USE_AGRICULTURAL', 'TYPE_OF_USE_CONSTRUCTION', 'TYPE_OF_USE_GASTRONOMY', 'TYPE_OF_USE_INDUSTRIAL', 'TYPE_OF_USE_INVESTMENT', 'TYPE_OF_USE_STORAGE', 'TYPE_OF_USE_RESIDENTIAL', 'TYPE_OF_USE_OFFICE', 'TYPE_OF_USE_STORE', 'TYPE_OF_USE_ADVERTISING_AREA']
      } else if (this.object.category === 'APARTMENT' || this.object.category === 'HOUSE') {
        return ['TYPE_OF_USE_STORAGE', 'TYPE_OF_USE_VACATION', 'TYPE_OF_USE_GASTRONOMY', 'TYPE_OF_USE_INDUSTRIAL', 'TYPE_OF_USE_INVESTMENT', 'TYPE_OF_USE_CONSTRUCTION', 'TYPE_OF_USE_RESIDENTIAL', 'TYPE_OF_USE_COMMERCIAL']
      } else if (this.object.category === 'COMMERCIAL' || this.object.category === 'GASTRONOMY') {
        return ['TYPE_OF_USE_CONSTRUCTION', 'TYPE_OF_USE_GASTRONOMY', 'TYPE_OF_USE_INDUSTRIAL', 'TYPE_OF_USE_INVESTMENT', 'TYPE_OF_USE_STORAGE', 'TYPE_OF_USE_RESIDENTIAL', 'TYPE_OF_USE_COMMERCIAL', 'TYPE_OF_USE_OFFICE', 'TYPE_OF_USE_STORE', 'TYPE_OF_USE_ADVERTISING_AREA', 'TYPE_OF_USE_RADIO_ANTENNA']
      } else if (this.object.category === 'GARDEN' || this.object.category === 'PLOT' || this.object.category === 'AGRICULTURE') {
        return ['TYPE_OF_USE_COMMERCIAL', 'TYPE_OF_USE_GASTRONOMY', 'TYPE_OF_USE_INDUSTRIAL', 'TYPE_OF_USE_INVESTMENT', 'TYPE_OF_USE_CONSTRUCTION']
      } else if (this.object.category === 'SECONDARY_ROOM') {
        return ['TYPE_OF_USE_STORAGE', 'TYPE_OF_USE_RESIDENTIAL', 'TYPE_OF_USE_SECONDARY_ROOM']
      } else if (this.object.category === 'PARKING_SPACE') {
        return ['TYPE_OF_USE_PARKING', 'TYPE_OF_USE_PARKING_EXTERNAL']
      } else {
        return []
      }
    },
    endingDatebeforeNow() {
      return this.endingAt && this.endingAt.year !== -1 && new Date(this.$options.filters.objectToDateInput(this.endingAt)) < new Date()
    },
    contractNumber() {
      return this.tenancy.numericId ? this.tenancy.numericId : this.$t('message.generic.generatedAfterSave')
    },
    vacancyReason: {
      get() {
        return this.tenancy.vacancyReason
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.vacancyReason = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    rentType: {
      get() {
        return this.tenancy.contract.rentType
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.contract.rentType = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    typeOfUse: {
      get() {
        return this.tenancy.contract.typeOfUse
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.contract.typeOfUse = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    startingAt: {
      get() {
        return this.tenancy.startingAt || null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.startingAt = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    endingAt: {
      get() {
        return this.tenancy.endingAt || null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.endingAt = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    activated: {
      get() {
        return this.tenancy.activated
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.activated = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    opted: {
      get() {
        return this.tenancy.opted
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.opted = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    note: {
      get() {
        return this.tenancy.note
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.note = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    activatedOnLoad: {
      get() {
        if (this.tenancy.activatedOnLoad !== true && this.tenancy.activatedOnLoad !== false) {
          return null
        } else {
          return this.tenancy.activatedOnLoad
        }
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.activatedOnLoad = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    additionalCostsBillingType: {
      get() {
        return this.tenancy.rent.additionalCostsBillingType
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.rent.additionalCostsBillingType = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    getRentAdjustmentMonths() {
      const array = []
      if (this.tenancy.indexData) {
        for (const month of this.tenancy.indexData.rentAdjustmentMonths) {
          array.push(this.$t('message.generic.months.' + month))
        }
        return array.join(', ')
      }
      return ''
    },
    additionalCostsAdjustmentAllowed: {
      get() {
        return this.tenancy.indexData ? this.tenancy.indexData.additionalCostsAdjustmentAllowed : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.indexData.additionalCostsAdjustmentAllowed = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    belowInitialRentAllowed: {
      get() {
        return this.tenancy.indexData ? this.tenancy.indexData.belowInitialRentAllowed : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.indexData.belowInitialRentAllowed = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    rentAdjustmentAdditionalBenefit: {
      get() {
        return this.tenancy.indexData ? this.tenancy.indexData.rentAdjustmentAdditionalBenefit : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.indexData.rentAdjustmentAdditionalBenefit = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    maximumRate: {
      get() {
        return this.tenancy.indexData && this.tenancy.indexData.maximumRate !== -1 ? this.tenancy.indexData.maximumRate : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.indexData.maximumRate = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    referenceMonthForIndex: {
      get() {
        return this.tenancy.indexData && this.tenancy.indexData.referenceMonthForIndex !== -1 ? this.tenancy.indexData.referenceMonthForIndex : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.indexData.referenceMonthForIndex = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    contractPercentImputedAtIndex: {
      get() {
        return this.tenancy.indexData && this.tenancy.indexData.contractPercentImputedAtIndex !== -1 ? this.tenancy.indexData.contractPercentImputedAtIndex : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.indexData.contractPercentImputedAtIndex = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    firstRentAdjustmentPossibleAt: {
      get() {
        return this.tenancy.rent.firstRentAdjustmentPossibleAt
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.rent.firstRentAdjustmentPossibleAt = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    rentAdjustmentByMonths: {
      get() {
        return this.tenancy.indexData && this.tenancy.indexData.rentAdjustmentByMonths !== -1 ? this.tenancy.indexData.rentAdjustmentByMonths : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.indexData.rentAdjustmentByMonths = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    rentAdjustmentByPoints: {
      get() {
        return this.tenancy.indexData && this.tenancy.indexData.rentAdjustmentByPoints !== -1 ? this.tenancy.indexData.rentAdjustmentByPoints : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.indexData.rentAdjustmentByPoints = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    rentAdjustmentMonths: {
      get() {
        if (this.tenancy.indexData && this.tenancy.indexData.rentAdjustmentMonths) {
          const tempMonths = this.tenancy.indexData.rentAdjustmentMonths
          const avoidInfiniteLoop = tempMonths
          avoidInfiniteLoop.sort(function (a, b) {
            return a - b
          })
          return avoidInfiniteLoop.map(month => {
            return {
              label: this.$t('message.generic.months.' + month),
              value: month
            }
          })
        }
        return []
      },
      set(value) {
        const newTenancy = this.tenancy
        for (const month of value) {
          if (month.value === 'all') {
            newTenancy.indexData.rentAdjustmentMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            this.dispatchTenancyUpdate(newTenancy)
            this.$emit('tenancy-updated', newTenancy)
            return
          }
        }

        newTenancy.indexData.rentAdjustmentMonths = value.map(v => v.value)
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    rentAdjustmentNotificationUnit: {
      get() {
        return this.tenancy.indexData && this.tenancy.indexData.rentAdjustmentNotification && this.tenancy.indexData.rentAdjustmentNotification.unit ? this.tenancy.indexData.rentAdjustmentNotification.unit : null
      },
      set(value) {
        const newTenancy = this.tenancy
        if (!newTenancy.indexData.rentAdjustmentNotification) {
          newTenancy.indexData.rentAdjustmentNotification = {
            unit: 'RENT_ADJUSTMENT_NOTIFICATION_DAYS',
            value: null
          }
        }
        newTenancy.indexData.rentAdjustmentNotification.unit = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    rentAdjustmentNotificationValue: {
      get() {
        return this.tenancy.indexData && this.tenancy.indexData.rentAdjustmentNotification && this.tenancy.indexData.rentAdjustmentNotification.value ? this.tenancy.indexData.rentAdjustmentNotification.value : null
      },
      set(value) {
        const newTenancy = this.tenancy
        if (!newTenancy.indexData.rentAdjustmentNotification) {
          newTenancy.indexData.rentAdjustmentNotification = {
            unit: 'RENT_ADJUSTMENT_NOTIFICATION_DAYS',
            value: null
          }
        }
        newTenancy.indexData.rentAdjustmentNotification.value = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    valueMonth() {
      const array = []
      for (let index = 1; index < 13; index++) {
        array.push(index)
      }
      return array
    },
    translatedMonths() {
      const array = []
      for (const month of this.availableTerminationMonths) {
        array.push({
          label: this.$t('message.generic.months.' + month),
          value: month
        })
      }
      return array
    },
    finalInvoiceCreated: {
      get() {
        return this.tenancy.finalInvoiceCreated
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.finalInvoiceCreated = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    closed: {
      get() {
        return this.tenancy.closed
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.closed = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    isBetweenTenancies() {
      const listTenancies = this.getFilteredTenancies(this.tenancy.objectId)
      const i = listTenancies.findIndex(x => x.id === this.tenancy.id)
      if (i > 0 && i < (listTenancies.length - 1)) {
        if (listTenancies[i - 1].vacancy === false && listTenancies[i + 1].vacancy === false) {
          return true
        }
      }
      return false
    },
    documents() {
      const adjustmentsDocuments = this.adjustments ? this.tenancyDocuments.filter(d => this.adjustments.map(x => x.documentIds).flat().includes(d.id)) : []
      return this.getFilteredTenancyDocuments(this.tenancy.documentIds).concat(adjustmentsDocuments).sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    getLang() {
      return this.$store.getters['internationalization/getCurrentLanguage']
    },
    property() {
      return this.properties[0] ? this.properties[0] : null
    },
    taxable: {
      get() {
        return this.tenancy.taxable
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.taxable = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    }
  },
  watch: {
    selectedTemplate: function (newVal) {
      if (newVal !== '') {
        const template = this.sortedTemplate.find(x => x.id === newVal)
        this.title = template.subject
        this.description = template.message
      } else {
        this.title = ''
        this.description = ''
      }
    },
    taxable: function (newVal, oldVal) {
      if (newVal === null || newVal) {
        this.opted = false
      } else if (!newVal && this.property?.opted) {
        this.opted = true
      }
    },
    initEnding: function (newVal, oldVal) {
      if (JSON.stringify(oldVal) !== JSON.stringify(newVal) && newVal && this.localEnding === true) {
        const isValide = new Date(this.$options.filters.objectToDateInput(newVal)).getTime() > new Date(this.$options.filters.objectToDateInput(this.startingAt)).getTime()
        if (isValide) {
          this.$nextTick(function () {
            this.dates.endingAt = newVal ? this.$options.filters.objectToDateInput(newVal) : null
            this.endingAt = newVal
            const newTenancy = this.tenancy
            this.dispatchTenancyUpdate(newTenancy)
            this.$emit('tenancy-updated', newTenancy)
          })
        }
      }
    },
    'tenancy.startingAt': function (newVal, oldVal) {
      this.startingAtChanged = true
      if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        if (this.dates.referenceInterestRateDate) {
          const referenceInterestRateDateArrayD = this.dates.referenceInterestRateDate.split('/')
          const referenceInterestRateDateFormated = new Date(referenceInterestRateDateArrayD[0] + '/01/' + referenceInterestRateDateArrayD[1])
          if (this.isValidDate(referenceInterestRateDateFormated) === true) {
            this.dates.referenceInterestRateDate = this.startingAt ? ('0' + this.startingAt.month).slice(-2) + '/' + this.startingAt.year : null
            this.referenceInterestRateDate = this.toObject(referenceInterestRateDateArrayD[1] + '-' + referenceInterestRateDateArrayD[0] + '-01')
          }
        }
      }
    },
    'tenancy.endingAt': function (newVal, oldVal) {
      if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        this.endingAtChanged = true
      }
    },
    'tenancy.contract.rentType': function (newVal) {
      if ((newVal === 'RENT_TYPE_INDEX' || newVal === 'RENT_TYPE_TURNOVER') && !this.tenancy.indexData) {
        const newTenancy = this.tenancy
        newTenancy.indexData = {
          additionalCostsAdjustmentAllowed: false,
          belowInitialRentAllowed: false,
          maximumRate: null,
          referenceMonthForIndex: null,
          rentAdjustmentAdditionalBenefit: false,
          rentAdjustmentByMonths: null,
          rentAdjustmentByPoints: null,
          rentAdjustmentMonths: [],
          contractPercentImputedAtIndex: 100,
          rentAdjustmentNotification: {
            unit: 'RENT_ADJUSTMENT_NOTIFICATION_MONTHS',
            value: null
          }
        }

        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    referenceInterestRateDate: function (newVal, oldVal) {
      if (JSON.stringify(oldVal) !== JSON.stringify(newVal) && new Date(this.$options.filters.objectToDateInput(newVal)).getTime() / 1000 > 0) {
        this.referenceInterestRate = this.getReferenceInterestRateByTimestamp(new Date(this.$options.filters.objectToDateInput(newVal)).getTime() / 1000)
        this.referenceInterestRatePossible = this.getReferenceInterestRateByTimestamp(new Date(this.$options.filters.objectToDateInput(newVal)).getTime() / 1000)
      }
    },
    tenancyStatus: function (newVal) {
      if (newVal === 'activeClosed') {
        this.activated = true
        this.closed = true
      } else if (newVal === 'active') {
        this.activated = true
        this.closed = false
      } else if (newVal === 'inProgress') {
        this.activated = false
        this.closed = false
      }
    },
    dueDates: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.calculationBasis === 'TENANCY_CALCULATION_BASIS_M2_YEAR') {
          this.changeColdRentM2PerYear()
        } else {
          this.changeColdRent()
        }
      }
    }
    // properties: function (newVal) {
    //   if (newVal && newVal.length > 0) {
    //     this.loadVacancyRentComponents(newVal)
    //   }
    // }
  },
  mounted() {
    this.getDatesFromObjects()
    if (this.tenancy) {
      if (this.activatedOnLoad === null) {
        this.activatedOnLoad = this.activated
      }
      if (this.tenancy.opted === false && this.tenancy.taxable === false) {
        this.taxable = null
      }
    }
    for (let index = 0; index < this.tenancy.rent.adjustments.length; index++) {
      this.tenancy.rent.adjustments[index].grossRent = null
    }
    this.contractTemplate = this.tenancyContracts.filter(x => x.ownerId === this.tenancy.ownerId)
    if (this.tenancy.activated === true && this.tenancy.closed === true) {
      this.tenancyStatus = 'activeClosed'
    } else if (this.tenancy.activated === true && this.tenancy.closed === false) {
      this.tenancyStatus = 'active'
      this.hideInprogress = true
    } else if (this.tenancy.activated === false && this.tenancy.closed === false) {
      this.tenancyStatus = 'inProgress'
    }
    if (this.endingAt === null) {
      this.localEnding = true
    }
    if (this.isInprogress) {
      this.tenancy.activated = false
      this.tenancy.closed = false
      this.tenancyStatus = 'inProgress'
    }
    let i = 0
    this.tenancy.rent.adjustments.forEach(x => {
      x.internalId = Date.now() + i
      i = i + 1
    })
    // this.loadVacancyRentComponents(this.properties)
    this.$nextTick(function () {
      this.initStartingAt = this.tenancy.startingAt
      this.activeAndEditable = (!this.object?.debitUntil || new Date(this.$options.filters.objectToDateInput(this.object?.debitUntil)) < new Date(this.$options.filters.objectToDateInput(this.tenancy.startingAt)))
      this.tenancy.modified = false
      if (!this.hasChangedObject && !this.hasChangedTenant) {
        this.resetChanged()
      }
      this.tenancyChanged = true
    })
    if (this.readOnly) {
      this.fillWholesInGreyLines()
    }
  },
  beforeDestroy() {
    this.$root.$off('bv::collapse::state')
  },
  methods: {
    ...mapMutations('message', [
      'setSendMailContacts',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply',
      'setSubject',
      'setAdditionalText',
      'setUseBcc',
      'setBccReferenceIds'
    ]),
    itemSelected(ids, contracts) {
      this.contractTemplateSelected = ids
      this.contractTemplate = contracts
    },
    contractsLoaded(contracts) {
      this.contractTemplate = contracts
    },
    fillWholesInGreyLines() {
      if (this.$refs['objvacancyrow-' + this.tenancy.id]?.children?.length % 4 !== 0) {
        const div = document.createElement('div')
        div.classList.add('col-sm-12', 'col-md-3', 'grey-col', 'd-sm-none', 'd-md-block')
        div.style.backgroundColor = 'rgba(0, 68, 95, 0.03)'
        this.$refs['objvacancyrow-' + this.tenancy.id].appendChild(div)
        this.fillWholesInGreyLines()
      } else {
        this.paintWhiteAndGreyLines()
      }
    },
    paintWhiteAndGreyLines() {
      if (this.$refs['objvacancyrow-' + this.tenancy.id]) {
        const arrayOfChildren = this.$refs['objvacancyrow-' + this.tenancy.id].children
        for (let index = 0; index < arrayOfChildren.length; index++) {
          if ((index === 0) || (parseInt((index / 4)) % 2) === 0) {
            arrayOfChildren[index].classList.add('grey-col')
          } else {
            arrayOfChildren[index].classList.add('white-col')
          }
        }
      }
    },
    renderContract() {
      const promises = []
      const listTemplatePdf = []
      for (let index = 0; index < this.contractTemplateSelected.length; index++) {
        const signatories = []
        const contract = this.contractTemplate.find(x => x.id === this.contractTemplateSelected[index])
        if (contract && !contract.mediaId.includes('.pdf')) {
          if (contract && contract.firstSignature !== '') {
            signatories.push(contract.firstSignature)
          }
          if (contract && contract.secondSignature) {
            if (contract && contract.firstSignature === '') {
              signatories.push('')
            }
            signatories.push(contract.secondSignature)
          }
          promises.push(ObjectApi.RenderTenancyTemplate(this.contractTemplateSelected[index], this.tenancy.id, this.addDocumentToContact, this.title, this.description, null, signatories))
        } else if (contract) {
          listTemplatePdf.push(contract.mediaId)
        }
      }
      if (listTemplatePdf.length > 0) {
        MediaApi.getListMedia(listTemplatePdf).then(response => {
          for (const media of response.media) {
            saveAs(media.url, media.filename)
          }
        })
      }
      Promise.all(promises)
        .then((response) => {
          this.$bvModal.hide(`create-contract-confirmationModal-${this.index}`)
          const renderId = []
          response.forEach((element, index) => {
            let downloadName = ''
            const contract = this.contractTemplate.find(x => x.id === this.contractTemplateSelected[index])
            if (this.tenancy.vacancy === true) {
              downloadName = contract.title + '_' + this.object.numericId
            } else {
              downloadName = contract.title
            }
            const item = { renderId: element.renderId + '_' + downloadName + '-/-' + contract.enforcePdf, contract: contract }
            renderId.push(item)
          })
          localStorage.setItem('renderId', JSON.stringify(renderId))
        })
        .catch(e => {
          this.loading = false
          Vue.toasted.show(this.$t('message.applicationForm.creditTrust.certificateOrderError'), { type: 'error' })
        })
    },
    handleErrorOnFinish(e) {
      console.log(e)
      this.loading = false
      Vue.toasted.show(this.$t('message.loadingErrors.tenancy'), { type: 'error' })
    },
    getPreviousDayISODate(date) {
      if (date && date.byDate.year !== -1) {
        let timestamp = new Date(this.$options.filters.objectToDate(date.byDate)).getTime() / 1000
        timestamp -= 86400000
        const d = new Date(timestamp)
        return d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2)
      } else {
        return ''
      }
    },
    activateVacancyClicked() {
      this.$bvModal.show(`activate-vacancy-modal-${this.tenancy.id}`)
    },
    tenancyActivated() {
      this.tenancy.activated = true
    },
    addDocumentClicked(suffix = '') {
      this.$bvModal.show('add-document-modal' + suffix)
    },
    getModulo(array) {
      let foo = []
      for (let i = 0; i < array.length / 4; i++) {
        foo.push(i)
      }
      return foo
    },
    changeColdRentM2PerYear() {
      const duedate = this.dueDates.length > 0 ? this.dueDates.length : 1
      if (this.object && (this.object.category === 'HOUSE' || this.object.category === 'APARTMENT')) {
        const la = this.object.characteristics &&
        this.object.characteristics.areas &&
        this.object.characteristics.areas.livingArea !== -1
          ? this.object.characteristics.areas.livingArea
          : 1
        this.coldRent = la * this.coldRentM2PerYear / duedate
      } else {
        const fa = this.object && this.object.characteristics &&
        this.object.characteristics.areas &&
        this.object.characteristics.areas.effectiveArea !== -1
          ? this.object.characteristics.areas.effectiveArea
          : 1
        this.coldRent = fa * this.coldRentM2PerYear / duedate
      }
    },
    changeColdRent() {
      if (this.object) {
        const duedate = this.dueDates.length > 0 ? this.dueDates.length : 1
        if (this.object.category === 'HOUSE' || this.object.category === 'APARTMENT') {
          const la = this.object.characteristics &&
          this.object.characteristics.areas &&
          this.object.characteristics.areas.livingArea !== -1
            ? this.object.characteristics.areas.livingArea
            : 1
          this.coldRentM2PerYear = duedate * this.coldRent / la
        } else {
          const fa = this.object.characteristics &&
          this.object.characteristics.areas &&
          this.object.characteristics.areas.effectiveArea !== -1
            ? this.object.characteristics.areas.effectiveArea
            : 1
          this.coldRentM2PerYear = duedate * this.coldRent / fa
        }
      }
    },
    getDueDateMonths() {
      const array = []
      if (this.tenancy.rent.dueDates && this.tenancy.rent.dueDates.length > 0) {
        for (const month of this.tenancy.rent.dueDates) {
          array.push(this.$t('message.generic.months.' + month))
        }
        return array.join(', ')
      } else {
        return '-'
      }
    },
    advancePaymentValueChanged(item, val) {
      const newAdjustment = this.adjustment
      newAdjustment.advancePaymentValues.find(x => x.type === item.type).value = val
      this.$emit('change-adjustment', newAdjustment)
      this.dispatchTenancyUpdate(this.tenancy)
    },
    flatRateValueChanged(item, val) {
      const newAdjustment = this.adjustment
      newAdjustment.flatRateValues.find(x => x.type === item.type).value = val
      this.$emit('change-adjustment', newAdjustment)
      this.dispatchTenancyUpdate(this.tenancy)
    },
    additionalValuesChanged(item, val) {
      const newAdjustment = this.adjustment
      newAdjustment.additionalValues.find(x => x.type === item.type).value = val
      this.$emit('change-adjustment', newAdjustment)
      this.dispatchTenancyUpdate(this.tenancy)
    },
    dateToObject() {
      this.startingAt = this.toObject(this.dates.startingAt)
      this.endingAt = this.toObject(this.dates.endingAt)
      this.firstRentAdjustmentPossibleAt = this.toObject(this.dates.firstRentAdjustmentPossibleAt)
    },
    deleteDocument(document) {
      this.tenancy.documentIds.forEach((doc, index) => {
        if (doc === document.id) {
          this.tenancy.documentIds.splice(index, 1)
        }
      }, this)
      this.dispatchTenancyUpdate(this.tenancy)
      this.$emit('tenancy-updated', this.tenancy)
      this.activateChanged()
    },
    dispatchTenancyUpdate(newTenancy) {
      if (this.tenancyChanged) {
        this.setChangedTenant(true)
        this.$store.dispatch('onboarding/updateTenancy', newTenancy)
      }
    },
    documentCreated(document) {
      // Update tenancy in vuex store with new documentId
      const newTenancy = this.tenancy
      newTenancy.documentIds.push(document.id)
      this.dispatchTenancyUpdate(newTenancy)
      this.$emit('tenancy-updated', newTenancy)

      // Add whole new document to vuex store to display it
      this.tenancyDocuments.push(document)
    },
    versionAdded(updatedDocument) {
      this.tenancyDocuments.forEach((doc, index) => {
        if (doc.id === updatedDocument.id) {
          this.tenancyDocuments.splice(index, 1, updatedDocument)
        }
      }, this)
      this.dispatchTenancyUpdate(this.tenancy)
      this.$emit('tenancy-updated', this.tenancy)
    },
    async documentCreatedAction(document) {
      // Update tenancy in vuex store with new documentId
      const newTenancy = this.tenancy
      newTenancy.documentIds.push(document.id)
      this.dispatchTenancyUpdate(newTenancy)
      this.$emit('tenancy-updated', newTenancy)

      // Add whole new document to vuex store to display it
      this.tenancyDocuments.push(document)
      await this.saveOnboarding()
    },
    getDatesFromObjects: function () {
      this.dates.startingAt = this.$options.filters.objectToDateInput(this.startingAt)
      this.dates.endingAt = this.$options.filters.objectToDateInput(this.endingAt)
      this.dates.firstRentAdjustmentPossibleAt = this.$options.filters.objectToDateInput(this.firstRentAdjustmentPossibleAt)
    },
    getFormatPriceOfReduction(value, decimal = false) {
      value = Number(value).toFixed(decimal === true ? 2 : ((value % 1) === 0 ? 0 : 2)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1\'')
      return `CHF -${value}`
    },
    isInvalid() {
      let valid = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.scrollToError()
        valid = false
        Vue.toasted.show(this.$t('message.savingErrors.validationError3'), { type: 'error' })
      }
      if (this.$v.startingAt.$dirty && this.$v.startingAt.$error) {
        Vue.toasted.show(this.$t('message.savingErrors.tenancyStartingAtAfterEndingAt'), { type: 'error' })
      }
      if (this.$v.endingAt.$dirty && this.$v.endingAt.$error && !this.isFirst && this.tenancy.id && this.endingAtChanged) {
        Vue.toasted.show(this.$t('message.savingErrors.endDateCantchangeAfterwards'), { type: 'error' })
      }
      if (this.$v.endingAt.$dirty && this.$v.endingAt.$error && !this.$v.endingAt.moreThanDebitUntil) {
        Vue.toasted.show(this.$t('message.savingErrors.endDateBeforeDebit', { debitUntil: this.$options.filters.objectToDate(this.object.debitUntil) }), { type: 'error' })
      }
      if (this.$v.startingAt.$dirty && this.$v.startingAt.$error && !this.$v.startingAt.startingMoreThanDebitUntil) {
        Vue.toasted.show(this.$t('message.savingErrors.startDateBeforeDebit', { debitUntil: this.$options.filters.objectToDate(this.object.debitUntil) }), { type: 'error' })
      }
      if (this.$refs['tenancy-adjustment']) {
        this.$refs['tenancy-adjustment'].forEach(element => {
          if (element.isInvalid().$invalid) {
            const v = element.isInvalid()
            // if (v.adjustment.byDate.$dirty && v.adjustment.byDate.$error && !v.adjustment.byDate.moreThanStarting) {
            //   Vue.toasted.show(this.$t('message.savingErrors.costAdjustmentAfterContractStart'), { type: 'error' })
            // } else if (v.adjustment.byDate.$dirty && v.adjustment.byDate.$error && !v.adjustment.byDate.moreThanDebitUntil) {
            //   Vue.toasted.show(this.$t('message.savingErrors.debitDateAndAdjustment', { debitUntil: this.$options.filters.objectToDate(this.object.debitUntil) }), { type: 'error' })
            // }
            valid = false
          }
        })
      }
      return !valid
    },
    isCollapseOpen() {
      return this.$refs.collapse.show
    },
    dateRangeOverlaps(aStart, aEnd, bStart, bEnd) {
      if (aStart <= bStart && bStart <= aEnd) return true // b starts in a
      if (aStart <= bEnd && bEnd <= aEnd) return true // b ends in a
      if (bStart < aStart && aEnd < bEnd) return true // a in b
      return false
    },
    removeClicked() {
      this.$bvModal.show('deleteVacancyModal-' + this.index)
    },
    async removeConfirmed() {
      await this.removeTenancy(this.tenancy)
      await this.saveOnboarding()
      this.$bvModal.hide('deleteVacancyModal-' + this.index)
    },
    removeActiveVacancy() {
      this.$bvModal.show(`after-delete-tenancy-modal-${this.index}`)
    },
    addAdjustmentClicked() {
      this.tenancy.rent.adjustments.forEach(adj => {
        if (adj.visibleCollaps) {
          adj.visibleCollaps = false
        }
      })
      const adjustmentAdvancePayment = []
      const adjustmentFlatRate = []
      const adjustmentAdditionalRent = []
      if (this.properties && this.properties.length > 0) {
        this.properties.forEach(property => {
          // Get the adjustment to copy the values from
          const baseAdjustment = (this.adjustments || []).reduce((p, c) => new Date(this.$options.filters.objectToDateInput(p.byDate)) > new Date(this.$options.filters.objectToDateInput(c.byDate)) ? p : c)
          property.advancePaymentRentComponents.forEach(element => {
            const item = baseAdjustment.advancePaymentValues.find(e => e.type === element.rentComponent)
            adjustmentAdvancePayment.push({
              type: element.rentComponent,
              value: item ? item.value : null
            })
          })
          property.flatRateRentComponents.forEach(element => {
            const item = baseAdjustment.flatRateValues.find(e => e.type === element.rentComponent)
            adjustmentFlatRate.push({
              type: element.rentComponent,
              value: item ? item.value : null
            })
          })
          property.additionalRentComponents.forEach(element => {
            const item = baseAdjustment.additionalValues.find(e => e.type === element.rentComponent)
            adjustmentAdditionalRent.push({
              type: element.rentComponent,
              value: item ? item.value : null
            })
          })
        })
      }

      const previousRentAdjustment = this.adjustments[0]
      const internalId = Date.now()
      this.tenancy.rent.adjustments.unshift({
        internalId: internalId,
        calculationBasis: previousRentAdjustment ? previousRentAdjustment.calculationBasis : '',
        byDate: null,
        newItem: true,
        dueDates: previousRentAdjustment ? previousRentAdjustment.dueDates : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        coldRent: previousRentAdjustment ? previousRentAdjustment.coldRent : -1,
        coldRentM2PerYear: previousRentAdjustment ? previousRentAdjustment.coldRentM2PerYear : -1,
        coldRentPerMonth: previousRentAdjustment ? previousRentAdjustment.coldRentPerMonth : -1,
        additionalCosts: -1,
        grossRent: -1,
        heatingCosts: -1,
        costIncreaseType: previousRentAdjustment ? previousRentAdjustment.costIncreaseType : 'COST_INCREASE_TYPE_FLAT_RATE',
        costIncreaseDate: previousRentAdjustment ? previousRentAdjustment.costIncreaseDate : null,
        costIncreaseValue: previousRentAdjustment ? previousRentAdjustment.costIncreaseValue : null,
        countryIndex: {
          baseDate: previousRentAdjustment && previousRentAdjustment.countryIndex ? previousRentAdjustment.countryIndex.baseDate : null,
          date: previousRentAdjustment && previousRentAdjustment.countryIndex ? previousRentAdjustment.countryIndex.date : null,
          inflation: previousRentAdjustment && previousRentAdjustment.countryIndex ? previousRentAdjustment.countryIndex.inflation : null
        },
        referenceInterestRate: {
          date: previousRentAdjustment && previousRentAdjustment.referenceInterestRate ? previousRentAdjustment.referenceInterestRate.date : null,
          rate: previousRentAdjustment && previousRentAdjustment.referenceInterestRate ? previousRentAdjustment.referenceInterestRate.rate : -10000,
          ratePossible: previousRentAdjustment && previousRentAdjustment.referenceInterestRate ? previousRentAdjustment.referenceInterestRate.ratePossible : -10000
        },
        documentIds: [],
        monthlyRentDueBy: previousRentAdjustment ? previousRentAdjustment.monthlyRentDueBy : 1,
        inArrears: previousRentAdjustment ? previousRentAdjustment.inArrears : false,
        advancePaymentValues: adjustmentAdvancePayment,
        flatRateValues: adjustmentFlatRate,
        additionalValues: adjustmentAdditionalRent,
        finalized: false,
        visibleCollaps: true
      })
      this.dispatchTenancyUpdate(this.tenancy)
      this.$emit('tenancy-updated', this.tenancy)
      this.$nextTick(() => {
        if (this.$el) {
          const domRect = this.$el.querySelector('#collapse-tenancy-adjustedcosts-index-' + internalId)
          if (domRect) {
            domRect.scrollIntoView(true)
            this.$el.querySelector('#collapse-tenancy-adjustedcosts-index-' + internalId + ' input[type="date"]')?.focus()
          }
        }
      })
    },
    convertTimestampToISODate(timestamp) {
      if (timestamp && timestamp >= 0) {
        const date = new Date(timestamp * 1000)
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
      } else {
        return ''
      }
    },
    getPreviousDayOfTimestamp(timestamp) {
      return timestamp - 86400
    },
    // loadVacancyRentComponents(properties) {
    //   if (properties && properties.length > 0) {
    //     const adjustmentAdvancePayment = []
    //     const adjustmentFlatRate = []
    //     const adjustmentAdditionalRent = []
    //     this.tenancy.rent.adjustments.forEach(adj => {
    //     properties.forEach(property => {
    //       property.advancePaymentRentComponents.forEach(element => {
    //           if (element.inactive === false) {
    //             const item = adj.advancePaymentValues.find(e => e.type === element.rentComponent)
    //             adjustmentAdvancePayment.push({
    //               type: element.rentComponent,
    //               value: item ? item.value : null
    //             })
    //           }
    //         })
    //       property.flatRateRentComponents.forEach(element => {
    //         if (element.inactive === false) {
    //           const item = adj.flatRateValues.find(e => e.type === element.rentComponent)
    //           adjustmentFlatRate.push({
    //             type: element.rentComponent,
    //             value: item ? item.value : null
    //           })
    //         }
    //       })
    //       property.additionalRentComponents.forEach(element => {
    //         if (element.inactive === false) {
    //           const item = adj.additionalValues.find(e => e.type === element.rentComponent)
    //           adjustmentAdditionalRent.push({
    //             type: element.rentComponent,
    //             value: item ? item.value : null
    //           })
    //         }
    //       })
    //     })
    //     adj.flatRateValues = adjustmentFlatRate
    //     adj.advancePaymentValues = adjustmentAdvancePayment
    //     adj.additionalValues = adjustmentAdditionalRent
    //     })
    //   }
    // },
    changeAdjustment(newAdjustment) {
      const index = this.tenancy.rent.adjustments.findIndex(adj => adj.internalId === newAdjustment.internalId)
      const newTenancy = this.tenancy
      newTenancy.rent.adjustments[index] = newAdjustment
      this.dispatchTenancyUpdate(newTenancy)
      this.$emit('tenancy-updated', newTenancy)
    },
    deleteAdjustmentClicked(index) {
      this.$delete(this.tenancy.rent.adjustments, this.tenancy.rent.adjustments.findIndex(adj => adj.internalId === index))
      this.dispatchTenancyUpdate(this.tenancy)
    },
    toTimestamp(strDate) {
      return Date.parse(strDate) / 1000
    },
    getCustomeDocumentName(doc) {
      const name = doc.name.split('.').shift()
      if (this.tenancy.vacancy === true) {
        return name + '_' + this.tenancy.objectId
      } else {
        return null
      }
    },
    handleCheckboxesInsideTable(value) {
      if (this.$refs['table-contract']) {
        const allCheckboxesComponents = this.$refs['table-contract'].$children[1].$children
        this.costumeHandleCheckbox(allCheckboxesComponents, value)
      }
    },
    clearCostIncreaseDate() {
      this.costIncreaseDate = null
      this.dates.costIncreaseDate = null
    },
    handleCostIncreaseDateChange() {
      if (this.dates.costIncreaseDate) {
        const costIncreaseDateArrayD = this.dates.costIncreaseDate.split('/')
        const costIncreaseDateFormated = new Date(costIncreaseDateArrayD[0] + '/01/' + costIncreaseDateArrayD[1])
        if (this.isValidDate(costIncreaseDateFormated) === true) {
          this.costIncreaseDate = this.toObject(costIncreaseDateArrayD[1] + '-' + costIncreaseDateArrayD[0] + '-01')
        }
      }
    }
  },
  validations() {
    const validationVacancy = {
      vacancyReason: {
        required
      },
      rentType: {
        isRequired: (value) => !['RENT_TYPE_UNDEFINED', '', undefined, null].includes(value)
      },
      typeOfUse: {
        isRequired: (value) => !['TYPE_OF_USE_UNDEFINED', '', undefined, null].includes(value)
      },
      startingAt: {
        required,
        lessThanEnding,
        startingMoreThanDebitUntil
      },
      endingAt: {
        moreThanStarting,
        moreThanDebitUntil
      },
      tenancyStatus: {
        required
      },
      dates: {}
    }
    if (!this.isFirst && this.tenancy.id && this.endingAtChanged) {
      validationVacancy.endingAt = {
        moreThanStarting,
        checkChanging
      }
    }
    return validationVacancy
  }
}
</script>

<style lang="scss" scoped>
h5 > span {
  font-size: 18px !important;
}
:deep(.modal-xl) {
    max-width: 1140px !important;
}
:deep(.mx-datepicker) {
  width: 100%;
}

:deep(.mx-icon-calendar) {
  display: none;
}
.grey-col{
  background:  $color-table-row-2;
}
.white-col{
 background:  $color-table-row-1;
}
.cursor-none {
  text-decoration: none;
  cursor: auto;
}
</style>
